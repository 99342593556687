import React from 'react';
import classNames from 'classnames';
import {
  PHOTO_REQUIRED,
  ANSWER_TYPE,
  QUESTION_TYPE_LABEL,
  ANSWER_TYPE_LABEL,
} from 'config/questionOptions';
import { DEFAULT_LANGUAGE } from 'config/languages';
import { getTranslation } from 'lib/dataTransform';

import CollapsibleRadio from 'lib/components/collapsible-radio/CollapsibleRadio';
import DatePicker from 'lib/components/date-picker/DatePicker';
import DeleteButton from 'lib/components/delete-button/DeleteButton';
import ErrorBag from 'lib/components/error-bag/ErrorBag';
import File from 'lib/components/file/File';
import Input from 'lib/components/input/Input';
import Select from 'lib/components/select/Select';
import Textarea from 'lib/components/textarea/Textarea';
import UploadButton from 'lib/components/upload-button/UploadButton';

import { MAX_PHOTO_NUMBER } from 'modules/inspections/performInspectionReducer';

import { ReactComponent as CloseCircleSolid } from 'assets/images/remove.svg';

import './AnswerForm.scss';

function AnswerForm({
  data = {},
  isHidden,
  onInputChange,
  onPhotoUpload,
  onPhotoRemove,
  onDocumentUpload,
  onDocumentInputChange,
  onDocumentRemove,
}) {
  let answerInput = null;
  let photoRequired = data.photoRequired;

  switch (data.answerType) {
    case ANSWER_TYPE.MULTIPLE_CHOICE:
      answerInput = (
        <Select
          label='Answer'
          isClearable
          data-answertype={data.answerType}
          data-testid='AnswerForm.answerInput'
          onChange={(val) => onInputChange('actualAnswer', val)}
          value={data.actualAnswer?.value}
          options={data.answerOptions}
        />
      );
      break;
    case ANSWER_TYPE.DATE_SELECTION:
      answerInput = (
        <DatePicker
          label='Answer'
          data-answertype={data.answerType}
          data-testid='AnswerForm.answerInput'
          value={data.actualAnswer?.value}
          onChange={(date) => onInputChange('actualAnswer', date)}
        />
      );
      break;
    case ANSWER_TYPE.TEXT_AREA_INPUT:
      answerInput = (
        <Textarea
          label='Answer'
          data-answertype={data.answerType}
          data-testid='AnswerForm.answerInput'
          value={data.actualAnswer?.value}
          onChange={(ev) => onInputChange('actualAnswer', ev.target.value)}
        />
      );
      break;
    case ANSWER_TYPE.QUANTITATIVE_INPUT:
      answerInput = (
        <Input
          label='Answer'
          data-answertype={data.answerType}
          data-testid='AnswerForm.answerInput'
          value={data.actualAnswer?.value}
          onChange={(ev) => onInputChange('actualAnswer', ev.target.value)}
        />
      );
      break;
    case ANSWER_TYPE.DEVICE_IMAGE_CAPTURE:
      // For device image capture we don't have a text answer but we
      // make photo mandatory
      answerInput = null;
      photoRequired = PHOTO_REQUIRED.REQUIRED;
      break;
    case ANSWER_TYPE.BARCODE_INPUT:
    case ANSWER_TYPE.COLOR_INPUT:
    default:
      answerInput = false;
  }
  const answerTypeLabel = ANSWER_TYPE_LABEL[data.answerType] || data.answerType;
  const unsupportedMessage =
    answerInput === false
      ? `Questions of type "${answerTypeLabel}" are not supported in the web app.`
      : null;

  const isPhotoNotAllowed = photoRequired === PHOTO_REQUIRED.NOT_ALLOWED;
  const isPhotoLimitReached = data.photos.value.length >= MAX_PHOTO_NUMBER;
  const isUploadPhotoDisabled = isPhotoNotAllowed || isPhotoLimitReached;

  const { display: displayName } = getTranslation(data.name, DEFAULT_LANGUAGE);

  return (
    <div
      className={`qm-question-detail ${
        isHidden || unsupportedMessage ? 'hidden' : ''
      } qm-answer-form`}
      data-testid='AnswerForm.container'
    >
      <div
        data-testid='AnswerForm.summary'
        className='table-row question-summary'
      >
        <div
          data-testid='AnswerForm.questionOrder'
          className='table-cell id-cell'
        >
          #{data.order + 1}
        </div>
        <div
          data-testid='AnswerForm.questionName'
          className='table-cell question-cell'
        >
          {displayName}
        </div>
        <div
          data-testid='AnswerForm.questionType'
          className='table-cell question-type-cell'
        >
          {QUESTION_TYPE_LABEL[data.type]}
        </div>
        <div
          data-testid='AnswerForm.questionSampleSize'
          className='table-cell sample-size-cell align-right'
        >
          {data.sampleSize}
        </div>
        <div className='table-cell toggle-cell'></div>
      </div>
      <div className='table-row question-body'>
        {unsupportedMessage && (
          <div className='table-cell body-cell'>
            <p className='unsupported-description'>{unsupportedMessage}</p>
          </div>
        )}
        {!isHidden && !unsupportedMessage && (
          <div data-testid='AnswerForm.body' className='table-cell body-cell'>
            {!!(data.documents || []).length && (
              <div className='reference-documents-container'>
                <div className='form-label'>Reference documents</div>
                <div className='document-list'>
                  {(data.documents || []).map((doc) => (
                    <div
                      className='document'
                      key={doc.id}
                      data-testid='AnswerForm.referenceDocument'
                    >
                      <div className='form-group'>
                        <a rel='noreferrer' href={doc.url} target='_blank'>
                          <File data={doc} />
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {!!data.assetReferenceDocs?.length && (
              <div className='asset-reference-documents-container'>
                <div className='form-label'>Asset Reference Documents</div>
                <div className='document-list'>
                  {data.assetReferenceDocs?.map((doc) => (
                    <div
                      className='document'
                      key={doc.id}
                      data-testid='AnswerForm.assetReferenceDocument'
                    >
                      <div className='form-group'>
                        <a rel='noreferrer' href={doc.url} target='_blank'>
                          <File data={doc} />
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {(data.tools || []).length > 0 && (
              <div className='attribute tools-needed-container'>
                <div className='label'>Tools needed</div>
                <div className='value'>
                  {data.tools.map((tool, idx) => (
                    <div key={idx}>{tool.name}</div>
                  ))}
                </div>
              </div>
            )}
            {answerInput}
            <div className='form-row'>
              <div className='form-label'>
                Photos
                {data.photoRequired === PHOTO_REQUIRED.REQUIRED &&
                  ' (Required)'}
              </div>
              <div
                className={classNames('photo-list-container', {
                  'is-invalid': data.photos?.errors.length,
                })}
              >
                {!!data.photos.value.length && (
                  <div className='photo-list'>
                    {data.photos.value.map((photo, idx) => (
                      <div className='answer-photo' key={photo.name}>
                        <img
                          alt=''
                          className='answer-photo'
                          src={URL.createObjectURL(photo)}
                        />
                        <button
                          type='button'
                          onClick={() => onPhotoRemove(idx)}
                          className='remove-button'
                        >
                          <CloseCircleSolid />
                        </button>
                      </div>
                    ))}
                  </div>
                )}
                <UploadButton
                  options={{ multiple: true }}
                  data-testid='AnswerForm.uploadPhoto'
                  disabled={isUploadPhotoDisabled}
                  onUpload={onPhotoUpload}
                >
                  Upload
                </UploadButton>
                <ErrorBag errors={data.photos?.errors} />
              </div>
            </div>
            <div className='documents-container'>
              <div className='form-label'>Documents</div>
              <div className='document-list'>
                {data.actualDocuments.value.map((doc, idx) => (
                  <div className='document' key={doc.file.name}>
                    <div className='form-group'>
                      <File iconOnly data={doc.file} />
                      <Input
                        value={doc.value}
                        className={!!doc.errors.length && 'is-invalid'}
                        onChange={(ev) =>
                          onDocumentInputChange(idx, ev.target.value)
                        }
                      />
                      <DeleteButton onClick={() => onDocumentRemove(idx)} />
                    </div>
                    <ErrorBag errors={doc.errors} />
                  </div>
                ))}
              </div>
              <UploadButton
                options={{ multiple: true }}
                data-testid='AnswerForm.uploadDocument'
                onUpload={onDocumentUpload}
              >
                Upload
              </UploadButton>
              <ErrorBag errors={data.actualDocuments?.errors} />
            </div>
            <Textarea
              value={data.questionNotes.value}
              onChange={(ev) => onInputChange('questionNotes', ev.target.value)}
              label='Question notes'
            />
            {!!data.defectOptions.length && (
              <div className='form-row'>
                <div className='form-label'>Defect</div>
                <CollapsibleRadio
                  threshold={7}
                  options={data.defectOptions}
                  onChange={(val) => onInputChange('actualDefect', val)}
                  value={data.actualDefect.value}
                />
                {data.defectOptions.length > 2 && (
                  <p className='field-description'>
                    You can only select up to one defect on the web app. To
                    select more please use the iPad app
                  </p>
                )}
              </div>
            )}
            {!!data.actualDefect.value && (
              <>
                <div className='form-row'>
                  <div className='flex defective-units-container'>
                    <Input
                      data-testid='AnswerForm.defectiveUnits'
                      label='Defective units'
                      value={data.defectiveUnits.value}
                      className={classNames({
                        'is-invalid': data.defectiveUnits.errors.length,
                      })}
                      onChange={(ev) =>
                        onInputChange('defectiveUnits', ev.target.value)
                      }
                    />
                    <Input
                      data-testid='AnswerForm.defectCount'
                      label='Defect count'
                      value={data.defectCount.value}
                      className={classNames({
                        'is-invalid': data.defectCount.errors.length,
                      })}
                      onChange={(ev) =>
                        onInputChange('defectCount', ev.target.value)
                      }
                    />
                  </div>
                  <ErrorBag
                    errors={[
                      ...data.defectiveUnits.errors,
                      ...data.defectCount.errors,
                    ]}
                  />
                </div>
                <div className='form-row'>
                  <Textarea
                    label='Defect notes'
                    data-testid='AnswerForm.defectNotes'
                    onChange={(ev) =>
                      onInputChange('defectNotes', ev.target.value)
                    }
                    value={data.defectNotes.value}
                  />
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default AnswerForm;
