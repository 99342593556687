import { useEffect, useReducer, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import debounce from 'lodash.debounce';

import SOURCE_STATUS from 'config/sourceStatus';
import COUNTRIES from 'config/countries.json';
import useQueryParams from 'lib/useQueryParams';
import tagsService from 'lib/tagsService';
import { entityToSelectOption } from 'lib/dataTransform';
import useNavigationPrompt from 'lib/useNavigationPrompt';
import useRoles from 'lib/useRoles';

import { sourceToFormState } from './dataTransform';
import {
  reducer,
  initialState,
  SOURCES_FORM_ACTIONS,
} from './sourcesFormReducer';
import sourcesService from './sourcesService';
import sourcesFormValidator from './sourcesFormValidator';
import { SOURCES_FORM_INPUT_RESTRICTIONS } from './sourcePermissions';

const getOwners = debounce(
  (params, dispatch) => {
    return sourcesService
      .getOwners(params)
      .then((response) => {
        dispatch({
          type: SOURCES_FORM_ACTIONS.APP_LOADS_OWNERS,
          payload: {
            data: response.data,
            count: response.count,
          },
        });
      })
      .catch(() => {
        dispatch({
          type: SOURCES_FORM_ACTIONS.RESET_STATE,
          payload: {
            errors: ['An error occured while fetching source owners'],
          },
        });
      });
  },
  400,
  { leading: true },
);

const useSourcesForm = (props) => {
  const [typeOptions, setTypeOptions] = useState([]);
  const { getParams } = useQueryParams(props.location);
  const [tagsOptions, setTagsOptions] = useState([]);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { isAllowed } = useRoles();

  useNavigationPrompt(state.isDirty);

  const { sortBy, sortOrder, search } = state.availableOwners;
  const ownersParams = {
    order: { [sortBy]: sortOrder },
    filters: { status: 'active' },
    search,
    page: state.availableOwners.page,
    pageSize: state.availableOwners.pageSize,
  };

  const isOwnerListChanged = () => {
    const originalList = state.initialOwners.map((o) => o.id).sort();
    const newList = state.owners.map((o) => o.value.id).sort();
    return JSON.stringify(originalList) !== JSON.stringify(newList);
  };

  const isImageListChanged = () => {
    const originalList = JSON.stringify(state.initialImages);
    const newList = JSON.stringify(state.images.values);
    return originalList !== newList;
  };

  const displayImageUploadError = () => {
    const { imageUploadFailed } = getParams();
    if (imageUploadFailed) {
      dispatch({
        type: SOURCES_FORM_ACTIONS.APP_FAILS_IMAGE_UPLOAD,
      });
    }
  };

  const getTypes = () => {
    return sourcesService
      .getTypes()
      .then((response) => {
        setTypeOptions(response.data.map(entityToSelectOption('name', 'id')));
      })
      .catch(() => {
        dispatch({
          type: SOURCES_FORM_ACTIONS.RESET_STATE,
          payload: {
            errors: ['An error occured while fetching source types'],
          },
        });
      });
  };

  const getSource = () => {
    const id = props.match?.params?.id;
    return (
      id &&
      sourcesService
        .getSource(id)
        .then((source) => {
          dispatch({
            type: SOURCES_FORM_ACTIONS.RESET_STATE,
            payload: sourceToFormState(source),
          });
        })
        .catch((e) => {
          if (e.response?.status === 403) {
            props.history.push(`/sources`);
          } else {
            dispatch({
              type: SOURCES_FORM_ACTIONS.RESET_STATE,
              payload: e.payload,
            });
          }
        })
    );
  };

  const getTags = () => {
    return tagsService.getTags().then((tags) => {
      setTagsOptions(tags.data.map(({ id, label }) => ({ value: id, label })));
    });
  };

  const userSetsTags = (tags) => {
    return dispatch({
      type: SOURCES_FORM_ACTIONS.USER_SETS_TAGS,
      payload: tags,
    });
  };

  useEffect(() => {
    const promises = [getTypes(), getSource()];
    if (getInputPermission('tags')) {
      promises.push(getTags());
    }
    Promise.all(promises).then(displayImageUploadError);
  }, []);

  useEffect(() => {
    if (state.availableOwners.isModalOpen) {
      void getOwners(ownersParams, dispatch);
    }
  }, [
    state.availableOwners.sortBy,
    state.availableOwners.sortOrder,
    state.availableOwners.search,
    state.availableOwners.page,
  ]);

  const resolvedInputPermissions = Object.keys(
    SOURCES_FORM_INPUT_RESTRICTIONS,
  ).reduce(
    (acc, key) => ({
      ...acc,
      [key]:
        !SOURCES_FORM_INPUT_RESTRICTIONS[key].length ||
        isAllowed(SOURCES_FORM_INPUT_RESTRICTIONS[key]),
    }),
    {},
  );

  const getInputPermission = (key) => resolvedInputPermissions[key];

  useEffect(() => {
    if (state.loading) {
      sourcesFormValidator(state, {
        inputPermissions: resolvedInputPermissions,
      })
        .then((data) =>
          sourcesService
            .saveSource(data)
            .then((response) => {
              const newImages = state.images.values.filter((i) => !i.id);
              if (newImages.length) {
                return sourcesService
                  .saveSourceImages(response.id, state.images.values)
                  .then(() => response)
                  .catch(() =>
                    Promise.reject({
                      type: 'IMAGE_UPLOAD_ERROR',
                      source: response,
                    }),
                  );
              }
              return response;
            })
            .then((response) => {
              return props.history.push(`/sources/${response.id}`);
            }),
        )
        .catch((e) => {
          if (e.type === 'IMAGE_UPLOAD_ERROR') {
            props.history.push(
              `/sources/${e.source.id}/edit?imageUploadFailed=true`,
            );
          } else {
            dispatch({
              type: SOURCES_FORM_ACTIONS.RESET_STATE,
              payload: e.payload,
            });
          }
        })
        .finally(() => {
          dispatch({ type: SOURCES_FORM_ACTIONS.APP_FINISHES_SUBMISSION });
          document.getElementsByClassName('is-invalid')[0]?.scrollIntoView();
        });
    }
  }, [state.loading]);

  useEffect(displayImageUploadError, [state.loading]);

  const userTypesName = (ev) => {
    return dispatch({
      type: SOURCES_FORM_ACTIONS.USER_TYPES_NAME,
      payload: ev.target.value || '',
    });
  };

  const userTypesExternalId = (ev) => {
    return dispatch({
      type: SOURCES_FORM_ACTIONS.USER_TYPES_EXTERNAL_ID,
      payload: ev.target.value || '',
    });
  };

  const userTypesAddress = (ev) => {
    return dispatch({
      type: SOURCES_FORM_ACTIONS.USER_TYPES_ADDRESS,
      payload: ev.target.value || '',
    });
  };

  const userTypesZipCode = (ev) => {
    return dispatch({
      type: SOURCES_FORM_ACTIONS.USER_TYPES_ZIPCODE,
      payload: ev.target.value || '',
    });
  };

  const userTypesDistrict = (ev) => {
    return dispatch({
      type: SOURCES_FORM_ACTIONS.USER_TYPES_DISTRICT,
      payload: ev.target.value || '',
    });
  };

  const userTypesCity = (ev) => {
    return dispatch({
      type: SOURCES_FORM_ACTIONS.USER_TYPES_CITY,
      payload: ev.target.value || '',
    });
  };

  const updateGeocode = () => {
    const {
      address,
      city,
      country,
      zipCode,
      district,
      state: addrState,
    } = state;

    if (address.value && city.value && country.value) {
      sourcesService
        .getGeolocation({
          address: address.value,
          city: city.value,
          zipCode: zipCode.value,
          district: district.value,
          state: addrState.value,
          country: country.value.value,
        })
        .then((results) => {
          if (results.length) {
            dispatch({
              type: SOURCES_FORM_ACTIONS.APP_LOADS_GEOCODE,
              payload: results[0].geometry.location,
            });
          }
        })
        .catch((e) => {
          dispatch({
            type: SOURCES_FORM_ACTIONS.APP_FAILS_GEOCODE,
            payload: e.payload.errors,
          });
        });
    }
  };
  const userBlursAddress = () => {
    updateGeocode();
  };
  useEffect(() => {
    updateGeocode();
  }, [state.country.value]);

  const userTypesState = (ev) => {
    return dispatch({
      type: SOURCES_FORM_ACTIONS.USER_TYPES_STATE,
      payload: ev.target.value || '',
    });
  };

  const getCountryOptions = () => {
    return COUNTRIES.map((country) => ({
      label: country,
      value: country,
    }));
  };

  const userSelectsCountry = (value) => {
    return dispatch({
      type: SOURCES_FORM_ACTIONS.USER_SELECTS_COUNTRY,
      payload: value || '',
    });
  };

  /* IMAGES */
  const onImageDrop = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length) {
      return dispatch({
        type: SOURCES_FORM_ACTIONS.USER_ADDS_IMAGE,
        payload: {
          values: state.images.values,
          errors: [
            'Maximum allowed size for images is 5 MB and the accepted MIME types are image/jpeg, image/png.',
            'A maximum of 5 images is permitted.',
          ],
        },
      });
    }

    dispatch({
      type: SOURCES_FORM_ACTIONS.USER_ADDS_IMAGE,
      payload: {
        values: [...state.images.values, ...acceptedFiles],
        errors: [],
      },
    });
  };

  const dropzoneConfig = {
    multiple: true,
    accept: 'image/jpeg, image/png, image/jpg',
    maxSize: 5 * 1024 * 1024,
    maxFiles: 5 - state.images.values.length,
    onDrop: onImageDrop,
  };
  const {
    open: openImageDropzone,
    getInputProps: getImageInputProps,
  } = useDropzone(dropzoneConfig);
  const userClicksUploadImage = openImageDropzone;

  const userRemovesImage = (index) => {
    const values = [...state.images.values];
    values.splice(index, 1);
    dispatch({
      type: SOURCES_FORM_ACTIONS.USER_REMOVES_IMAGE,
      payload: {
        values,
        errors: [],
      },
    });
  };

  const userCancelsImageChanges = () => {
    dispatch({
      type: SOURCES_FORM_ACTIONS.USER_CANCELS_IMAGE_CHANGES,
    });
  };

  /* SOURCE OWNERS */
  const userOpensOwnersModal = (ev) => {
    ev.preventDefault();
    return getOwners(ownersParams, dispatch).then(() => {
      return dispatch({
        type: SOURCES_FORM_ACTIONS.USER_OPENS_OWNERS_MODAL,
      });
    });
  };

  const userCancelsOwnerChanges = () => {
    dispatch({
      type: SOURCES_FORM_ACTIONS.USER_CANCELS_OWNER_CHANGES,
    });
  };

  const userTogglesNewOwner = (owner) => {
    return dispatch({
      type: SOURCES_FORM_ACTIONS.USER_TOGGLES_NEW_OWNER,
      payload: owner,
    });
  };

  const userCancelsOwnersModal = () => {
    return dispatch({
      type: SOURCES_FORM_ACTIONS.USER_CANCELS_OWNERS_MODAL,
    });
  };

  const userSearchesOwners = (ev) => {
    return dispatch({
      type: SOURCES_FORM_ACTIONS.USER_SEARCHES_OWNERS,
      payload: ev.target.value,
    });
  };

  const userSortsOwners = (col, order) => {
    return dispatch({
      type: SOURCES_FORM_ACTIONS.USER_SORTS_OWNERS,
      payload: {
        sortBy: col,
        sortOrder: order,
      },
    });
  };

  const userRemovesOwner = (owner) => {
    return dispatch({
      type: SOURCES_FORM_ACTIONS.USER_REMOVES_OWNER,
      payload: owner,
    });
  };

  const userSetsOwnersPage = (page) => {
    return dispatch({
      type: SOURCES_FORM_ACTIONS.USER_SETS_OWNERS_PAGE,
      payload: page,
    });
  };

  const userAssignsOwners = () => {
    return dispatch({
      type: SOURCES_FORM_ACTIONS.USER_ASSIGNS_NEW_OWNERS,
    });
  };

  const userSubmitsForm = (ev) => {
    ev.preventDefault();
    return dispatch({ type: SOURCES_FORM_ACTIONS.USER_SUBMITS_FORM });
  };

  /**
   * @deprecated
   */
  const getTypeValue = () => {
    // TODO: Remove after CollapsibleRadio has a stable signature
    return typeOptions?.length > 7 ? state.type.value : state.type.value?.value;
  };

  const userSelectsType = (typeOrId) => {
    // TODO: Remove after CollapsibleRadio has a stable signature
    const type = typeOrId.value
      ? typeOrId
      : typeOptions.find((t) => t.value === typeOrId);
    return dispatch({
      type: SOURCES_FORM_ACTIONS.USER_SELECTS_TYPE,
      payload: type,
    });
  };

  const getStatusOptions = () => {
    return Object.values(SOURCE_STATUS).map((status) => ({
      value: status,
      label: status,
    }));
  };

  const userSelectsStatus = (status) => {
    return dispatch({
      type: SOURCES_FORM_ACTIONS.USER_SELECTS_STATUS,
      payload: status,
    });
  };

  const userClicksMap = (ev) => {
    return dispatch({
      type: SOURCES_FORM_ACTIONS.USER_SETS_MARKER,
      payload: ev.latLng.toJSON(),
    });
  };

  const handleMapIdle = (map) => {
    const center = map.getCenter().toJSON();
    const zoom = map.getZoom();
    return dispatch({
      type: SOURCES_FORM_ACTIONS.USER_MOVES_MAP,
      payload: {
        center,
        zoom,
      },
    });
  };

  const getOwnersForDisplay = () =>
    state.availableOwners.list.map((owner) => ({
      ...owner,
      selected: !!state.availableOwners.selected.find((s) => s.id === owner.id),
    }));

  return {
    state,
    typeOptions,
    getTypeValue,
    tagsOptions,
    getStatusOptions,
    getCountryOptions,
    getImageInputProps,
    getInputPermission,

    userTypesName,
    userTypesExternalId,
    userSelectsType,
    userSelectsStatus,
    userTypesAddress,
    userTypesZipCode,
    userTypesDistrict,
    userTypesCity,
    userTypesState,
    userBlursAddress,
    userSelectsCountry,
    userSetsTags,
    userClicksMap,
    handleMapIdle,

    isImageListChanged,
    userCancelsImageChanges,
    userRemovesImage,
    userClicksUploadImage,

    isOwnerListChanged,
    getOwnersForDisplay,
    userCancelsOwnerChanges,
    userRemovesOwner,
    userOpensOwnersModal,
    userCancelsOwnersModal,
    userTogglesNewOwner,
    userAssignsOwners,
    userSearchesOwners,
    userSortsOwners,
    userSetsOwnersPage,
    userSubmitsForm,
  };
};

export default useSourcesForm;
