import * as yup from 'yup';
import { isInteger } from 'lodash';

import { API_ERROR_CODES } from 'config/errorCodes';
import { makeAPIBodyErrorProcessor } from 'lib/errorHelpers';
import validator, { inputShape, yupNumber } from 'lib/validator';

const ERRORS = {
  photoRequired: 'At least one photo is required',
  defectCountRequired: 'Defect count is a required field',
  defectiveUnitsRequired: 'Defective units is a required field',
  maxSampleSize: 'Defective units cannot be more than sample size',
  minDefective: 'Defect count cannot be smaller than defective units',
};

const performInspectionQuestionSchema = yup.object().shape({
  photos: yup.mixed().when(['isAnswered', 'isPhotoRequired', 'isHidden'], {
    is: (isAnswered, isPhotoRequired, isHidden) =>
      isAnswered && isPhotoRequired && !isHidden,
    then: inputShape(yup.array().min(1, ERRORS.photoRequired)),
  }),
  sampleSize: yup.number(),
  defectiveUnits: yup.object().when(['actualDefect', 'isHidden'], {
    is: (actualDefect, isHidden) => {
      return !!actualDefect.value && !isHidden;
    },
    then: inputShape(
      yupNumber
        .label('Defective units')
        .min(1)
        .integer()
        .required(ERRORS.defectiveUnitsRequired),
    ).test('maxSampleSize', ERRORS.maxSampleSize, function (input, ref) {
      if (
        (!input.errors?.length || input.errors?.[0] === ERRORS.maxSampleSize) &&
        isInteger(input.value) &&
        input.value > ref.parent.sampleSize
      ) {
        return this.createError({
          path: `${this.path}.value`,
          params: {
            originalValue: input.value,
          },
        });
      }
      return true;
    }),
  }),
  defectCount: yup.object().when(['actualDefect', 'isHidden'], {
    is: (actualDefect, isHidden) => !!actualDefect.value && !isHidden,
    then: inputShape(
      yupNumber
        .label('Defect count')
        .integer()
        .max(2147483647)
        .required(ERRORS.defectCountRequired),
    ).test('minDefective', ERRORS.minDefective, function (input, ref) {
      if (
        (!input.errors?.length || input.errors?.[0] === ERRORS.minDefective) &&
        isInteger(input.value) &&
        input.value < ref.parent.defectiveUnits.value
      ) {
        return this.createError({
          path: `${this.path}.value`,
          params: {
            originalValue: input.value,
          },
        });
      }
      return true;
    }),
  }),
});

const performInspectionValidationSchema = yup.object().shape({
  inspection: yup.object().shape({
    questionGroups: yup.array().of(
      yup.object().shape({
        questions: yup.array().of(performInspectionQuestionSchema),
      }),
    ),
  }),
});

const performInspectionValidator = (state) =>
  validator(state, performInspectionValidationSchema);

const performInspectionApiErrors = {
  ...API_ERROR_CODES,
};
export const processPerformInspectionAPIError = makeAPIBodyErrorProcessor({
  errorCodes: performInspectionApiErrors,
});

export default performInspectionValidator;
