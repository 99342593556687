import React from 'react';

import { DEFAULT_LANGUAGE } from 'config/languages';

import Button from 'lib/components/button/Button';
import Pagination from 'lib/components/pagination/Pagination';
import SearchInput from 'lib/components/search-input/SearchInput';
import QuestionDetail from 'lib/components/question-detail/QuestionDetail';
import { formatQuestionId } from 'lib/components/question-detail/dataTransform';
import Select from 'lib/components/select/Select';
import SettingsQuestionsList from '../settings-questions-list/SettingsQuestionsList';
import ErrorBag from 'lib/components/error-bag/ErrorBag';

import useSettingsQuestionsList from 'modules/settings/useSettingsQuestionsList';

import './SettingsQuestionsListPage.scss';
import DropdownMenu, {
  MenuItem,
} from 'lib/components/dropdown-menu/DropdownMenu';

const paginationOptions = [
  { value: 10, label: '10 per page' },
  { value: 50, label: '50 per page' },
  { value: 100, label: '100 per page' },
];

export default function SettingsQuestionsListPage(params) {
  const list = useSettingsQuestionsList(params);
  const getPageSize = () =>
    paginationOptions.find(
      (option) => option.value === Number(list.state.pageSize),
    );

  return (
    <div className='settings-questions-list-page'>
      <div className='settings-header'>
        <h4>Questions</h4>
        <DropdownMenu
          data-testid='SettingsQuestionsListPage.importDropdown'
          items={[
            <MenuItem
              label='Questions'
              onClick={() => params.history.push('/settings/questions/import')}
            />,
            <MenuItem
              label='Question defects'
              onClick={() =>
                params.history.push('/settings/questions/import-defects')
              }
            />,
            <MenuItem
              label='Question documents'
              onClick={() =>
                params.history.push('/settings/questions/import-documents')
              }
            />,
          ]}
        >
          <Button className='medium-button'>Import</Button>
        </DropdownMenu>
        <DropdownMenu
          data-testid='SettingsQuestionsListPage.importDropdown'
          disabled={list.state.isExporting}
          items={[
            <MenuItem
              label='Questions'
              onClick={list.userClicksExportQuestions}
            />,
            <MenuItem
              label='Question defects'
              onClick={list.userClicksExportQuestionDefects}
            />,
          ]}
        >
          <Button className='medium-button' disabled={list.state.isExporting}>
            Export
          </Button>
        </DropdownMenu>
      </div>
      <p className='form-info'>
        Changing a question here will affect all the inspection plans (which
        haven't been started) with that question inside.
      </p>
      <div className='actions-container'>
        <SearchInput
          data-testid='SettingsQuestionsListPage.search'
          value={list.state.search}
          onChange={list.setSearch}
        />
        <Button
          data-testid='SettingsQuestionsListPage.addQuestion'
          className='medium-button'
          onClick={list.userClicksAddQuestion}
        >
          Add new question
        </Button>
      </div>
      <div className='questions-container'>
        <ErrorBag errors={list.state.errors} />
        <SettingsQuestionsList
          sortBy={list.state.sortBy}
          sortOrder={list.state.sortOrder}
          setSortBy={list.sortBy}
        >
          {list.state.data.map((question) => (
            <QuestionDetail
              key={`${question.id}-detail`}
              data={question}
              editVariant={list.state.form ? 'none' : 'icon'}
              id={formatQuestionId(question)}
              language={DEFAULT_LANGUAGE}
              onEditChange={() => list.userClicksEditQuestion(question.id)}
              questionOptions={list.state.questionOptions}
            />
          ))}
        </SettingsQuestionsList>
      </div>
      <div className='table-controls'>
        <div className='page-size'>
          <label>Show:</label>
          <Select
            className='page-size-select'
            options={paginationOptions}
            value={getPageSize()}
            onChange={(option) => list.setPageSize(option.value)}
            isSearchable={false}
            menuPlacement='auto'
          />
        </div>
        <div className='pagination'>
          <Pagination
            pageSize={list.state.pageSize}
            page={list.state.page}
            count={list.state.count}
            threshold={7}
            setPage={list.setPage}
          />
        </div>
      </div>
    </div>
  );
}
