import { useEffect, useReducer, useCallback } from 'react';
import { cloneDeep } from 'lodash';
import WORK_OBJECT_STATUS from 'config/workObjectStatus';
import PARTIAL_WORK_OBJECT_STATUS from 'config/partialWorkObjectStatus';
import ROLES from 'config/roles';
import useRoles from 'lib/useRoles';
import useQueryParams from 'lib/useQueryParams';
import {
  reducer,
  initialState,
  WORK_OBJECTS_DETAIL_ACTIONS,
} from './workObjectsDetailReducer';
import workObjectsService from './workObjectsService';
import workObjectsDetailValidator, {
  workObjectReInspectValidator,
} from './workObjectsDetailValidator';
import { workObjectsToDetailState } from './dataTransform';
import INSPECTION_STATUS from 'config/inspectionStatus';
import workObjectService from './workObjectsService';

const useWorkObjectsDetail = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { currentUserRoles } = useRoles();
  const { setParams, getParams } = useQueryParams(
    props.location,
    props.history,
  );
  const { currentTab } = getParams();

  const getWorkObject = () => {
    const id = props.match?.params?.id;
    if (id) {
      workObjectsService
        .getWorkObject({ id })
        .then((workObject) => {
          dispatch({
            type: WORK_OBJECTS_DETAIL_ACTIONS.APP_LOADS_DATA,
            payload: workObjectsToDetailState(workObject),
          });
        })
        .catch((e) => {
          dispatch({
            type: WORK_OBJECTS_DETAIL_ACTIONS.RESET_STATE,
            payload: e.payload,
          });
        });
    }
  };

  const userOpensInspectionModal = () => {
    return dispatch({
      type: WORK_OBJECTS_DETAIL_ACTIONS.USER_OPENS_INSPECTION_MODAL,
      payload: { isEditing: false },
    });
  };
  const userOpensEditPartialModal = () => {
    return dispatch({
      type: WORK_OBJECTS_DETAIL_ACTIONS.USER_OPENS_INSPECTION_MODAL,
      payload: { isEditing: true },
    });
  };
  const userOpensReInspectModal = (inspectionId) => {
    return dispatch({
      type: WORK_OBJECTS_DETAIL_ACTIONS.USER_OPENS_RE_INSPECT_MODAL,
      payload: inspectionId,
    });
  };

  const userCancelsInspectionModal = () => {
    return dispatch({
      type: WORK_OBJECTS_DETAIL_ACTIONS.USER_CANCELS_INSPECTION_MODAL,
    });
  };

  const userCancelsReInspectModal = () => {
    return dispatch({
      type: WORK_OBJECTS_DETAIL_ACTIONS.USER_CANCELS_RE_INSPECT_MODAL,
    });
  };

  const userSubmitsPartial = (e) => {
    e.preventDefault();
    return dispatch({
      type: WORK_OBJECTS_DETAIL_ACTIONS.USER_SUBMITS_PARTIAL,
    });
  };

  const userSubmitsReInspect = (e) => {
    e.preventDefault();
    return dispatch({
      type: WORK_OBJECTS_DETAIL_ACTIONS.USER_SUBMITS_RE_INSPECT,
    });
  };

  const userClicksOnTab = (index) => {
    setParams({ currentTab: index });
  };

  useEffect(() => {
    if (state.isPartialLoading) {
      const method = state.isEditingPartial ? 'updatePartial' : 'createPartial';
      const stateClone = cloneDeep(state);
      stateClone.deliveryDate.value = stateClone.deliveryDate?.value
        ? stateClone.deliveryDate.value
        : state.isEditingPartial
        ? state.workObject.partialWorkObjects[state.currentTab]?.deadline
        : state.workObject?.deadline;

      stateClone.partialQuantity.value = stateClone.partialQuantity?.value
        ? stateClone.partialQuantity.value
        : state.isEditingPartial
        ? state.workObject.partialWorkObjects[state.currentTab]?.quantity
        : stateClone.partialQuantity.value;

      workObjectsDetailValidator(stateClone)
        .then((data) =>
          workObjectsService[method]({
            id: state.isEditingPartial
              ? state.workObject.partialWorkObjects[state.currentTab]?.id
              : data.workObject?.id,
            quantity: data.partialQuantity?.value,
            deadline: data.deliveryDate?.value,
          }),
        )
        .then(() =>
          dispatch({
            type: WORK_OBJECTS_DETAIL_ACTIONS.APP_SUBMITS_PARTIAL_SUCCESSFULLY,
          }),
        )
        .catch((e) => {
          dispatch({
            type: WORK_OBJECTS_DETAIL_ACTIONS.RESET_STATE,
            payload: e.payload,
          });
        })
        .finally(() =>
          dispatch({
            type: WORK_OBJECTS_DETAIL_ACTIONS.APP_FINISHES_SUBMISSION,
          }),
        );
    } else {
      document.getElementsByClassName('is-invalid')[0]?.scrollIntoView();
    }
  }, [state.isPartialLoading]);

  useEffect(() => {
    if (state.isReInspectLoading) {
      const stateClone = cloneDeep(state);
      workObjectReInspectValidator(stateClone)
        .then((data) =>
          workObjectsService.performInspectionAction({
            inspectionId: data.currentReInspect,
            id: data.reInspectAql.value?.value,
          }),
        )
        .then(() =>
          dispatch({
            type:
              WORK_OBJECTS_DETAIL_ACTIONS.APP_SUBMITS_RE_INSPECT_SUCCESSFULLY,
          }),
        )
        .catch((e) => {
          dispatch({
            type: WORK_OBJECTS_DETAIL_ACTIONS.RESET_STATE,
            payload: e.payload,
          });
        })
        .finally(() =>
          dispatch({
            type:
              WORK_OBJECTS_DETAIL_ACTIONS.APP_FINISHES_RE_INSPECT_SUBMISSION,
          }),
        );
    } else {
      document.getElementsByClassName('is-invalid')[0]?.scrollIntoView();
    }
  }, [state.isReInspectLoading]);

  useEffect(() => {
    if (
      state.isPartialLoading ||
      props.match?.params?.id ||
      state.isAcceptPartialsLoading ||
      state.isRejectPartialsLoading ||
      state.isSkipInspectionLoading ||
      state.isPublishInspectionLoading ||
      state.isReInspectLoading ||
      state.isInspectionActionLoading ||
      state.isUpdatingPartialLoading
    ) {
      getWorkObject();
    }
  }, [
    state.isPartialLoading,
    props.match?.params?.id,
    state.isAcceptPartialsLoading,
    state.isRejectPartialsLoading,
    state.isSkipInspectionLoading,
    state.isPublishInspectionLoading,
    state.isReInspectLoading,
    state.isInspectionActionLoading,
    state.isUpdatingPartialLoading,
  ]);

  // whatch for url param and set correct tab
  useEffect(() => {
    const isValidTab =
      Number(currentTab) < state.workObject?.partialWorkObjects?.length;
    const validTab = isValidTab ? Number(currentTab) : 0;

    if (state.workObject && !isValidTab) {
      setParams({ currentTab: validTab });
    }
    dispatch({
      type: WORK_OBJECTS_DETAIL_ACTIONS.USER_CLICKS_ON_TAB,
      payload: validTab,
    });
  }, [currentTab, state.loading]);

  const userTypesPartialQuantity = (e, partialData) =>
    dispatch({
      type: WORK_OBJECTS_DETAIL_ACTIONS.USER_TYPES_PARTIAL_QUANTITY,
      payload: { value: e.target.value, partialData },
    });

  const userSetsPartialDeliveryDate = (date) =>
    dispatch({
      type: WORK_OBJECTS_DETAIL_ACTIONS.USER_SETS_PARTIAL_DELIVERY_DATE,
      payload: date,
    });

  const userSetsReInspectAql = (aql) =>
    dispatch({
      type: WORK_OBJECTS_DETAIL_ACTIONS.USER_SETS_RE_INSPECT_AQL,
      payload: aql,
    });

  const userAcceptsPartial = (index) => {
    if (
      state.workObject?.partialWorkObjects?.length &&
      index < state.workObject.partialWorkObjects.length
    ) {
      workObjectsService
        .acceptPartial({
          id: state.workObject.partialWorkObjects[index]?.id,
        })
        .then(() =>
          dispatch({
            type: WORK_OBJECTS_DETAIL_ACTIONS.USER_ACCEPTS_PARTIAL,
          }),
        )
        .finally(() =>
          dispatch({
            type: WORK_OBJECTS_DETAIL_ACTIONS.APP_FINISHES_ACCEPTING_PARTIAL,
          }),
        );
    }
  };
  const userRejectsPartial = (index) => {
    if (
      state.workObject?.partialWorkObjects?.length &&
      index < state.workObject.partialWorkObjects.length
    ) {
      workObjectsService
        .rejectPartial({
          id: state.workObject.partialWorkObjects[index]?.id,
        })
        .then(() => {
          setParams({ currentTab: 0 });
          return dispatch({
            type: WORK_OBJECTS_DETAIL_ACTIONS.USER_REJECTS_PARTIAL,
          });
        })
        .finally(() =>
          dispatch({
            type: WORK_OBJECTS_DETAIL_ACTIONS.APP_FINISHES_REJECTING_PARTIAL,
          }),
        );
    }
  };

  const makePartialReadyForInspection = (index, ready = true) => {
    if (
      state.workObject?.partialWorkObjects?.length &&
      index < state.workObject.partialWorkObjects.length
    ) {
      workObjectsService
        .updatePartial({
          id: state.workObject.partialWorkObjects[index]?.id,
          isReadyForInspection: ready,
        })
        .then(() =>
          dispatch({
            type: WORK_OBJECTS_DETAIL_ACTIONS.USER_UPDATES_PARTIAL,
          }),
        )
        .finally(() =>
          dispatch({
            type: WORK_OBJECTS_DETAIL_ACTIONS.APP_FINISHES_UPDATING_PARTIAL,
          }),
        );
    }
  };

  const userTakesInspectionAction = (action) => {
    dispatch({
      type: WORK_OBJECTS_DETAIL_ACTIONS.USER_TAKES_INSPECTION_ACTION,
      payload: action,
    });
  };

  const userCancelsInspectionAction = (action) => {
    dispatch({
      type: WORK_OBJECTS_DETAIL_ACTIONS.USER_CANCELS_INSPECTION_ACTION,
      payload: action,
    });
  };

  const userTypesActionNote = (ev) => {
    dispatch({
      type: WORK_OBJECTS_DETAIL_ACTIONS.USER_TYPES_INSPECTION_ACTION_NOTE,
      payload: ev.target.value,
    });
  };

  const userConfirmsInspectionAction = () => {
    const { notes, action } = state.takeDecisionModal;
    workObjectsService
      .performInspectionAction(action, notes.value)
      .then(() =>
        dispatch({
          type: WORK_OBJECTS_DETAIL_ACTIONS.USER_CONFIRMS_INSPECTION_ACTION,
        }),
      )
      .finally(() =>
        dispatch({
          type:
            WORK_OBJECTS_DETAIL_ACTIONS.APP_FINISHES_TAKING_INSPECTION_ACTION,
        }),
      );
  };

  const userSkipsInspection = (id) => {
    workObjectsService
      .skipInspection(id)
      .then(() =>
        dispatch({
          type: WORK_OBJECTS_DETAIL_ACTIONS.USER_SKIPS_INSPECTION,
        }),
      )
      .finally(() =>
        dispatch({
          type: WORK_OBJECTS_DETAIL_ACTIONS.APP_FINISHES_SKIPPING_INSPECTION,
        }),
      );
  };

  const userPublishesInspection = (partialId, pairId) => {
    workObjectsService
      .publishInspection(partialId, pairId)
      .then(() =>
        dispatch({
          type: WORK_OBJECTS_DETAIL_ACTIONS.USER_PUBLISHES_INSPECTION,
        }),
      )
      .finally(() =>
        dispatch({
          type: WORK_OBJECTS_DETAIL_ACTIONS.APP_FINISHES_PUBLISHING_INSPECTION,
        }),
      );
  };

  const isPartialSubmitted = (index) => {
    if (
      state.workObject?.partialWorkObjects?.length &&
      index < state.workObject.partialWorkObjects.length
    ) {
      return (
        state.workObject.partialWorkObjects[index]?.status ===
        PARTIAL_WORK_OBJECT_STATUS.SUBMITTED
      );
    }
    return false;
  };

  const isFullQuantity = (index) => {
    if (
      state.workObject?.partialWorkObjects?.length &&
      index < state.workObject.partialWorkObjects.length
    ) {
      return (
        state.workObject?.quantity ===
        state.workObject.partialWorkObjects[index]?.quantity
      );
    }
    return false;
  };

  const showCreatePartialButton = () => {
    if (currentUserRoles?.includes(ROLES.COORDINATOR)) {
      return (
        state.workObject?.userCanEdit && !!state.workObject?.remainingQuantity
      );
    }
    return !!state.workObject?.remainingQuantity;
  };

  const showPartialReadyForInspectionButton = (index) => {
    const isAccepted =
      state.workObject?.partialWorkObjects[index]?.status ===
      PARTIAL_WORK_OBJECT_STATUS.ACCEPTED;
    const isReadyForInspection =
      state.workObject?.partialWorkObjects[index]?.isReadyForInspection;
    const hasPartials = !!state.workObject?.partialWorkObjects?.length;

    if (currentUserRoles?.includes(ROLES.COORDINATOR)) {
      return (
        state.workObject?.userCanEdit &&
        !!state.workObject?.partialWorkObjects?.length &&
        state.workObject?.partialWorkObjects[index]?.status ===
          PARTIAL_WORK_OBJECT_STATUS.ACCEPTED &&
        !state.workObject?.partialWorkObjects[index]?.isReadyForInspection
      );
    }

    return hasPartials && isAccepted && !isReadyForInspection;
  };

  const showPartialUnreadyForInspectionButton = (index) => {
    const isAccepted =
      state.workObject?.partialWorkObjects?.[index]?.status ===
      PARTIAL_WORK_OBJECT_STATUS.ACCEPTED;

    const isReadyForInspection =
      state.workObject?.partialWorkObjects?.[index]?.isReadyForInspection;

    const hasPartials = !!state.workObject?.partialWorkObjects?.length;
    const canEdit = currentUserRoles?.includes(ROLES.COORDINATOR)
      ? state.workObject?.userCanEdit
      : true;

    const hasStartedInspections = state.workObject.partialWorkObjects?.[
      index
    ]?.partialWorkObjectSteps
      .map((s) => s.inspectionPairs)
      .flat(Infinity)
      .map((p) => p.inspection)
      .filter(
        (i) =>
          i?.status &&
          ![INSPECTION_STATUS.DRAFT, INSPECTION_STATUS.PUBLISHED].includes(
            i.status,
          ),
      ).length;

    return (
      canEdit &&
      hasPartials &&
      isAccepted &&
      isReadyForInspection &&
      !hasStartedInspections
    );
  };

  const showPartialButton = (index) => {
    if (
      currentUserRoles?.includes(
        ROLES.COORDINATOR && !state.workObject?.userCanEdit,
      )
    ) {
      return false;
    }
    return (
      !!state.workObject?.partialWorkObjects?.length &&
      !state.workObject?.partialWorkObjects[index]?.isReadyForInspection
    );
  };

  const isWorkObjectDone =
    state.workObject?.status === WORK_OBJECT_STATUS.canceled.toLowerCase() ||
    state.workObject?.status === WORK_OBJECT_STATUS.finished.toLowerCase();

  const isMissingAssignedLocations = state.workObject?.workObjectSteps.some(
    (step) => step.source === null,
  );

  const isWorkObjectActionsDisabled =
    isWorkObjectDone ||
    isMissingAssignedLocations ||
    state.workObject?.status === WORK_OBJECT_STATUS.deactivated.toLowerCase() ||
    state.workObject?.status === WORK_OBJECT_STATUS.draft.toLowerCase();

  const getAddInspectionOrder = useCallback(
    (inspections) => {
      const inProgressIdx = inspections?.findIndex(
        (item) => item?.inspection?.status === INSPECTION_STATUS.IN_PROGRESS,
      );

      if (inProgressIdx >= 0) {
        return inspections[inProgressIdx].order;
      }

      const finishedInspections = inspections?.filter(
        (item) =>
          item?.inspection?.status === INSPECTION_STATUS.FINISHED ||
          item?.inspection?.status === INSPECTION_STATUS.SKIPPED,
      );

      if (finishedInspections?.length) {
        return Math.max(...finishedInspections.map((i) => i.order));
      }

      return 0;
    },
    [state?.workObject?.partialWorkObjects[state.currentTab]?.inspectionPairs],
  );

  const userOpensPairWindowModal = (pairId) => {
    dispatch({
      type: WORK_OBJECTS_DETAIL_ACTIONS.USER_OPENS_PAIR_WINDOW_MODAL,
      payload: pairId,
    });
  };
  const userCancelsPairWindowModal = () => {
    dispatch({
      type: WORK_OBJECTS_DETAIL_ACTIONS.USER_CLOSES_PAIR_WINDOW_MODAL,
    });
  };
  const userChangesPairWindowRange = (range) => {
    dispatch({
      type: WORK_OBJECTS_DETAIL_ACTIONS.USER_CHANGES_PAIR_WINDOW_RANGE,
      payload: range,
    });
  };

  const userConfirmsPairWindowModal = async () => {
    try {
      const {
        pairId,
        value: [startDate, endDate],
      } = state.pairWindowModal;

      await workObjectService.saveWorkObjectInspectionPair(
        state.workObject?.partialWorkObjects[state.currentTab]?.id,
        {
          id: pairId,
          inspectionWindowStartDate: startDate || null,
          inspectionWindowEndDate: endDate || null,
        },
      );

      getWorkObject();

      dispatch({
        type: WORK_OBJECTS_DETAIL_ACTIONS.USER_CLOSES_PAIR_WINDOW_MODAL,
      });
    } catch (err) {
      if (err.payload?.errors) {
        dispatch({
          type: WORK_OBJECTS_DETAIL_ACTIONS.SET_PAIR_WINDOW_MODAL_ERRORS,
          payload: err.payload.errors,
        });
      } else {
        dispatch({
          type: WORK_OBJECTS_DETAIL_ACTIONS.SET_PAIR_WINDOW_MODAL_ERRORS,
          payload: ['An unknown error occurred. Please try again later.'],
        });
      }
    }
  };

  const isPairWindowModalSubmitDisabled =
    state.pairWindowModal.value.filter((d) => !d).length === 1;

  return {
    state,
    userOpensPairWindowModal,
    userCancelsPairWindowModal,
    userConfirmsPairWindowModal,
    userChangesPairWindowRange,
    isPairWindowModalSubmitDisabled,

    userOpensInspectionModal,
    userOpensEditPartialModal,
    userOpensReInspectModal,
    userTypesPartialQuantity,
    userSetsPartialDeliveryDate,
    userSetsReInspectAql,
    userCancelsInspectionModal,
    userCancelsReInspectModal,
    userSubmitsPartial,
    userSubmitsReInspect,
    userAcceptsPartial,
    userRejectsPartial,
    makePartialReadyForInspection,
    userTakesInspectionAction,
    userConfirmsInspectionAction,
    userCancelsInspectionAction,
    userTypesActionNote,
    userSkipsInspection,
    userPublishesInspection,
    userClicksOnTab,
    isPartialSubmitted,
    isFullQuantity,
    showPartialReadyForInspectionButton,
    showPartialUnreadyForInspectionButton,
    showCreatePartialButton,
    showPartialButton,
    isWorkObjectDone,
    isWorkObjectActionsDisabled,
    getAddInspectionOrder,
  };
};

export default useWorkObjectsDetail;
