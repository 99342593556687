/* eslint-disable no-unused-vars */
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';

import { noop } from 'lib/funcHelpers';
import ErrorBag from 'lib/components/error-bag/ErrorBag';

import { ReactComponent as ClearCircle } from 'assets/images/remove.svg';

import './DateRangePicker.scss';

/**
 * Input for a range of dates
 * @param {{
 *   value?: [string,string],
 *   onChange?: (dates: [startDate: string, endDate: string]) => any,
 *   disabled?: boolean,
 *   label?: string,
 *   errors?: string[],
 *   className?: string,
 *   min?: string,
 *   max?: string,
 *   name?: string,
 *   isClearable?: boolean,
 * }} props
 */
const DateRangePicker = ({
  value = ['', ''],
  onChange = noop,
  disabled,
  label,
  errors = [],
  className,
  inline = false,
  min,
  max,
  name,
  isClearable = false,
}) => {
  const [startDate, endDate] = value;
  const hasPartialValue = startDate || endDate;

  const startRef = useRef();
  const endRef = useRef();

  const onStartDateChanged = (ev) => {
    if (endDate && new Date(ev.target.value) > new Date(endDate)) {
      onChange([ev.target.value, '']);
    } else {
      onChange([ev.target.value, endDate]);
    }
  };

  const onEndDateChanged = (ev) => {
    onChange([startDate, ev.target.value]);
  };

  const onClear = () => {
    onChange(['', '']);
  };

  const containerClasses = classNames(
    'qm-date-range-picker-container',
    className,
    {
      'is-invalid': errors.length,
      disabled,
      inline: inline,
    },
  );

  return (
    <div className={containerClasses}>
      {label && (
        <div className='input-info'>
          <label className='form-label' htmlFor={name}>
            {label}
          </label>
        </div>
      )}
      <div className='input-row'>
        <input
          type='date'
          name={name}
          min={min}
          max={max}
          className='qm-date-range-start-input'
          value={startDate}
          onChange={onStartDateChanged}
          ref={startRef}
          disabled={disabled}
          /** We mark the HTML date input as `required` so we can
           * control its clearing via React and to hide the
           * two (x)'s the browser would otherwise add.
           */
          required
        />
        <span className='separator'>→</span>
        <input
          type='date'
          name={name}
          min={startDate || min}
          max={max}
          className='qm-date-range-end-input'
          value={endDate}
          onChange={onEndDateChanged}
          ref={endRef}
          disabled={disabled}
          /** We mark the HTML date input as `required` so we can
           * control its clearing via React and to hide the
           * two (x)'s the browser would otherwise add.
           */
          required
        />
        {isClearable && hasPartialValue && (
          <ClearCircle className='qm-date-range-clear' onClick={onClear} />
        )}
      </div>
      <ErrorBag errors={errors} />
    </div>
  );
};

export default DateRangePicker;
