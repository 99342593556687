import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import request from 'lib/request';
import { ReactComponent as LoaderIcon } from 'assets/images/loader.svg';
import axios from 'axios';
import './CsvImport.scss';

function StateAnalyzing({ type, onFinished }) {
  const [isAnalyzing, setIsAnalyzing] = useState(false);

  useEffect(() => {
    let ticker;
    let errorsCount = 0;
    // true if component is unmounted and ignore request responses
    let unmounted = false;
    const cancelRequest = axios.CancelToken.source();

    function checkUploadStatus() {
      request
        .get(`/imports/${type}/status`, {
          cancelToken: cancelRequest.token,
        })
        .then((data) => {
          if (unmounted) {
            return;
          }
          if (data?.status === 'finished') {
            onFinished(data);
          } else if (data?.status === 'active') {
            setIsAnalyzing(true);
            // check for status changes in 2 seconds
            ticker = setTimeout(checkUploadStatus, 2000);
          } else {
            onFinished();
          }
        })
        .catch(() => {
          if (unmounted) {
            return;
          }
          ++errorsCount;
          // try 2 times to get data
          if (errorsCount < 2) {
            ticker = setTimeout(checkUploadStatus, 3000);
          } else {
            onFinished();
          }
        });
    }

    checkUploadStatus();
    return () => {
      unmounted = true;
      cancelRequest.cancel();
      if (ticker) {
        clearTimeout(ticker);
      }
    };
  }, []);

  return (
    <div className='stateAnalyzing'>
      {isAnalyzing && (
        <>
          <div className='loader'>
            <LoaderIcon />
          </div>
          <div>Analyzing file...</div>
        </>
      )}
    </div>
  );
}

StateAnalyzing.propTypes = {
  type: PropTypes.string.isRequired,
  onFinished: PropTypes.func.isRequired,
};

export default StateAnalyzing;
