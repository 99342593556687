import { cloneDeep } from 'lodash';
import makeReducer from 'lib/makeReducer';

export const MAX_FIELD_CHARS = {
  name: 50,
  data: 5000,
  externalId: 50,
};

const INITIAL_NEW_CUSTOM_FIELD = () => ({
  name: {
    value: '',
    errors: [],
    charsLeft: MAX_FIELD_CHARS.name,
  },
  data: {
    value: '',
    errors: [],
    charsLeft: MAX_FIELD_CHARS.data,
  },
});

const INITIAL_MODAL_FILTERING = {
  sortBy: 'id',
  sortOrder: 'ASC',
  search: '',
  page: 1,
  pageSize: 10,
};

const INITIAL_FORM_STATE = () => ({
  resource: {
    // Value structure { value: '', label: '', image: '', location: '' },
    value: null,
    type: '',
    errors: [],
  },
  externalId: {
    value: '',
    errors: [],
    charsLeft: MAX_FIELD_CHARS.externalId,
  },
  originalQuantity: 0,
  quantity: {
    value: 0,
    errors: [],
  },
  status: {
    value: { value: 'active', label: 'Active' },
    errors: [],
  },
  deadline: {
    value: '',
    errors: [],
  },
  customFields: [],
  workflow: {
    value: null,
    errors: [],
  },
  source: {
    // Value structure: {value: '',label: '',location: ''},
    value: null,
    errors: [],
    order: 0,
  },
  originalSource: {
    value: null,
    errors: [],
    order: 0,
  },
  destination: {
    // Value structure: {value: '',label: '',location: ''},
    value: null,
    errors: [],
    order: 0,
  },
  originalDestination: {
    value: null,
    errors: [],
    order: 0,
  },
  vendor: {
    value: null,
    errors: [],
    order: 0,
  },
  errors: [],
  loading: false,
  id: null,
  availableSources: { ...INITIAL_MODAL_FILTERING },
  availableResources: { ...INITIAL_MODAL_FILTERING, tab: 'assets' },
  availableDestinations: { ...INITIAL_MODAL_FILTERING },
  availableVendors: { ...INITIAL_MODAL_FILTERING },
  availableWorkflows: { ...INITIAL_MODAL_FILTERING },
  currentResource: {
    value: '',
    externalId: '',
    label: '',
    location: '',
    image: '',
    type: '',
  },
  currentSource: {
    value: '',
    externalId: '',
    label: '',
    location: '',
  },
  currentDestination: {
    value: '',
    externalId: '',
    label: '',
    location: '',
  },
  currentVendor: {
    value: '',
    externalId: '',
    label: '',
    location: '',
  },
  currentWorkflow: {
    value: '',
    label: '',
    stepCount: 0,
  },
  isDestinationsModalOpen: false,
  isSourcesModalOpen: false,
  isVendorsModalOpen: false,
  isResourcesModalOpen: false,
  isWorkflowsModalOpen: false,
  isLoadingWorkflowData: false,
  hasPartials: false,
  hasAcceptedPartials: false,
  isDirty: false,
});

export const WORK_OBJECTS_FORM_ACTIONS = {
  RESET_STATE: 'RESET_STATE',
  USER_CHANGES_INPUT: 'USER_CHANGES_INPUT',
  USER_ADDS_CUSTOM_FIELD: 'USER_ADDS_CUSTOM_FIELD',
  USER_REMOVES_CUSTOM_FIELD: 'USER_REMOVES_CUSTOM_FIELD',
  USER_TYPES_CUSTOM_FIELD_NAME: 'USER_TYPES_CUSTOM_FIELD_NAME',
  USER_TYPES_CUSTOM_FIELD_DATA: 'USER_TYPES_CUSTOM_FIELD_DATA',
  USER_SETS_SOURCE: 'USER_SETS_SOURCE',
  USER_SWITCHES_SOURCE_TO_DEFAULT: 'USER_SWITCHES_SOURCE_TO_DEFAULT',
  USER_SWITCHES_DESTINATION_TO_DEFAULT: 'USER_SWITCHES_DESTINATION_TO_DEFAULT',
  USER_SETS_RESOURCE: 'USER_SETS_RESOURCE',
  USER_SETS_RESOURCE_PAGE: 'USER_SETS_RESOURCE_PAGE',
  USER_SETS_WORKFLOW: 'USER_SETS_WORKFLOW',
  USER_SETS_DESTINATION: 'USER_SETS_DESTINATION',
  USER_SETS_VENDOR: 'USER_SETS_VENDOR',
  USER_OPENS_RESOURCES_MODAL: 'USER_OPENS_RESOURCES_MODAL',
  USER_OPENS_SOURCES_MODAL: 'USER_OPENS_SOURCES_MODAL',
  USER_OPENS_WORKFLOWS_MODAL: 'USER_OPENS_WORKFLOWS_MODAL',
  USER_OPENS_DESTINATIONS_MODAL: 'USER_OPENS_DESTINATIONS_MODAL',
  USER_OPENS_VENDOR_MODAL: 'USER_OPENS_VENDOR_MODAL',
  USER_TOGGLES_RESOURCE: 'USER_TOGGLES_RESOURCE',
  USER_TOGGLES_WORKFLOW: 'USER_TOGGLES_WORKFLOW',
  USER_TOGGLES_SOURCE: 'USER_TOGGLES_SOURCE',
  USER_TOGGLES_DESTINATION: 'USER_TOGGLES_DESTINATION',
  USER_TOGGLES_VENDOR: 'USER_TOGGLES_VENDOR', // TODO: Check what this deos, do we need it?
  USER_CHANGES_RESOURCES_TAB: 'USER_CHANGES_RESOURCES_TAB',
  USER_SEARCHES_SOURCES: 'USER_SEARCHES_SOURCES',
  USER_SEARCHES_RESOURCES: 'USER_SEARCHES_RESOURCES',
  USER_SEARCHES_WORKFLOWS: 'USER_SEARCHES_WORKFLOWS',
  USER_SEARCHES_DESTINATIONS: 'USER_SEARCHES_DESTINATIONS',
  USER_SEARCHES_VENDORS: 'USER_SEARCHES_VENDORS',
  USER_CANCELS_SOURCES_MODAL: 'USER_CANCELS_SOURCES_MODAL',
  USER_CANCELS_RESOURCES_MODAL: 'USER_CANCELS_RESOURCES_MODAL',
  USER_CANCELS_WORKFLOWS_MODAL: 'USER_CANCELS_WORKFLOWS_MODAL',
  USER_CANCELS_DESTINATIONS_MODAL: 'USER_CANCELS_DESTINATIONS_MODAL',
  USER_CANCELS_VENDORS_MODAL: 'USER_CANCELS_VENDORS_MODAL',
  USER_SORTS_SOURCES: 'USER_SORTS_SOURCES',
  USER_SETS_SOURCES_PAGE: 'USER_SETS_SOURCES_PAGE',
  USER_SORTS_RESOURCES: 'USER_SORTS_RESOURCES',
  USER_SORTS_WORKFLOWS: 'USER_SORTS_WORKFLOWS',
  USER_SETS_WORKFLOW_PAGE: 'USER_SETS_WORKFLOW_PAGE',
  USER_SORTS_DESTINATIONS: 'USER_SORTS_DESTINATIONS',
  USER_SORTS_VENDORS: 'USER_SORTS_VENDORS',
  USER_SETS_DESTINATIONS_PAGE: 'USER_SETS_DESTINATIONS_PAGE',
  USER_SETS_VENDORS_PAGE: 'USER_SETS_VENDORS_PAGE',
  APP_IS_LOADING_WORKFLOW_DATA: 'APP_IS_LOADING_WORKFLOW_DATA',
  APP_LOADS_WORKFLOW_DATA: 'APP_LOADS_WORKFLOW_DATA',
  INITIAL_STATE: 'INITIAL_STATE',
  USER_SUBMITS_FORM: 'USER_SUBMITS_FORM',
  APP_FINISHES_SUBMISSION: 'APP_FINISHES_SUBMISSION',
};

const setValue = (key, state, action) => {
  const max = MAX_FIELD_CHARS[key];
  if (max && action.payload.length > max) {
    return state;
  }

  return {
    ...state,
    [key]: {
      value: action.payload,
      errors: [],
      charsLeft: max && max - action.payload.length,
    },
    isDirty: true,
  };
};
const removeArrayValue = (key, state, action) => {
  const newValues = cloneDeep(state[key]);
  newValues.splice(action.payload, 1);

  return {
    ...state,
    [key]: newValues,
    isDirty: true,
  };
};

const addCustomField = (state) => {
  const newValues = cloneDeep(state.customFields);
  newValues.push(INITIAL_NEW_CUSTOM_FIELD());

  return {
    ...state,
    customFields: newValues,
    isDirty: true,
  };
};

const userSetsResource = (state) => {
  return {
    ...state,
    resource: {
      value: {
        value: state.currentResource.value,
        externalId: state.currentResource.externalId,
        label: state.currentResource.label,
        location: state.currentResource.location,
        image: state.currentResource.image,
      },
      type: state.currentResource.type,
      errors: [],
    },
    workflow: {
      value: null,
      errors: [],
    },
    isResourcesModalOpen: false,
    availableResources: INITIAL_FORM_STATE().availableResources,
    isDirty: true,
  };
};

const setWorkflow = (state) => {
  return {
    ...state,
    workflow: {
      value: {
        value: state.currentWorkflow.value,
        label: state.currentWorkflow.label,
        stepCount: state.currentWorkflow.stepCount,
      },
      errors: [],
    },
    isWorkflowsModalOpen: false,
    availableWorkflows: INITIAL_FORM_STATE().availableWorkflows,
    isDirty: true,
  };
};

const setSource = (state) => {
  const originalSource = cloneDeep(state.originalSource);
  const isWorkflowSingleStep = state.workflow?.value.stepCount === 1;
  return {
    ...state,
    originalSource,
    source: {
      value: {
        value: state.currentSource.value,
        externalId: state.currentSource.externalId,
        label: state.currentSource.label,
        location: state.currentSource.location,
      },
      errors: [],
      order: originalSource.order,
    },
    destination: isWorkflowSingleStep
      ? {
          value: {
            value: state.currentSource.value,
            externalId: state.currentSource.externalId,
            label: state.currentSource.label,
            location: state.currentSource.location,
          },
          errors: [],
          order: originalSource.order,
        }
      : state.destination,
    isSourcesModalOpen: false,
    availableSources: INITIAL_FORM_STATE().availableSources,
    isDirty: true,
  };
};
const setDestination = (state) => {
  const originalDestination = cloneDeep(state.originalDestination);
  return {
    ...state,
    originalDestination,
    destination: {
      value: {
        value: state.currentDestination.value,
        externalId: state.currentDestination.externalId,
        label: state.currentDestination.label,
        location: state.currentDestination.location,
      },
      errors: [],
      order: originalDestination.order,
    },
    isDestinationsModalOpen: false,
    availableDestinations: INITIAL_FORM_STATE().availableDestinations,
    isDirty: true,
  };
};
const setVendor = (state) => {
  return {
    ...state,
    vendor: {
      value: {
        value: state.currentVendor.value,
        externalId: state.currentVendor.externalId,
        label: state.currentVendor.label,
        location: state.currentVendor.location,
      },
      errors: [],
    },
    isVendorsModalOpen: false,
    availableVendors: INITIAL_FORM_STATE().availableVendors,
    isDirty: true,
  };
};

const userTypesInArrayObject = (key, state, action) => {
  const max = MAX_FIELD_CHARS[key];
  if (max && action.payload.value.length > max) {
    return state;
  }

  return {
    ...state,
    isDirty: true,
    customFields: [
      ...state.customFields.map((element, index) => {
        if (index === action.payload.index) {
          return {
            ...element,
            [key]: {
              value: action.payload.value,
              errors: [],
              charsLeft: max && max - action.payload.value.length,
            },
          };
        }

        return {
          ...element,
          [key]: {
            value: element[key].value,
            errors: element[key].errors,
            charsLeft: element[key].charsLeft,
          },
        };
      }),
    ],
  };
};

const renderLocation = (location) =>
  [location?.city || '', location?.country || ''].join(', ');

const appLoadsWorkflowData = (state, action) => {
  // need to pick first and last source but workflowSteps are not ordered by step order
  const numberSteps = action.payload.workflowSteps?.length || 0;
  const sourceStep = action.payload.workflowSteps?.find(
    (item) => item.order === 1,
  );
  const destinationStep = action.payload.workflowSteps?.find(
    (item) => item.order === numberSteps,
  );

  return {
    ...state,
    isLoadingWorkflowData: false,
    source: {
      value: sourceStep.source
        ? {
            value: sourceStep.source.id,
            externalId: sourceStep.source.externalId,
            label: sourceStep.source.name,
            location: renderLocation(sourceStep.source.location),
          }
        : null,
      order: sourceStep.order,
      errors: [],
    },
    originalSource: {
      value: sourceStep.source
        ? {
            value: sourceStep.source.id,
            externalId: sourceStep.source.externalId,
            label: sourceStep.source.name,
            location: renderLocation(sourceStep.source.location),
          }
        : null,
      order: sourceStep.order,
      errors: [],
    },
    destination: {
      value: destinationStep.source
        ? {
            value: destinationStep.source.id,
            externalId: destinationStep.source.externalId,
            label: destinationStep.source.name,
            location: renderLocation(destinationStep.source.location),
          }
        : null,
      order: destinationStep.order,
      errors: [],
    },
    originalDestination: {
      value: destinationStep.source
        ? {
            value: destinationStep.source.id,
            externalId: destinationStep.source.externalId,
            label: destinationStep.source.name,
            location: renderLocation(destinationStep.source.location),
          }
        : null,
      order: destinationStep.order,
      errors: [],
    },
  };
};

const WORK_OBJECTS_FORM_REDUCER_CONFIG = {
  [WORK_OBJECTS_FORM_ACTIONS.USER_CHANGES_INPUT]: (state, action) => {
    return setValue(action.key, state, action);
  },
  [WORK_OBJECTS_FORM_ACTIONS.USER_TYPES_DESCRIPTION]: (state, action) => {
    return setValue('description', state, action);
  },
  [WORK_OBJECTS_FORM_ACTIONS.USER_REMOVES_CUSTOM_FIELD]: (state, action) => {
    return removeArrayValue('customFields', state, action);
  },
  [WORK_OBJECTS_FORM_ACTIONS.USER_ADDS_CUSTOM_FIELD]: (state) => {
    return addCustomField(state);
  },
  [WORK_OBJECTS_FORM_ACTIONS.USER_TYPES_CUSTOM_FIELD_NAME]: (state, action) => {
    return userTypesInArrayObject('name', state, action);
  },
  [WORK_OBJECTS_FORM_ACTIONS.USER_TYPES_CUSTOM_FIELD_DATA]: (state, action) => {
    return userTypesInArrayObject('data', state, action);
  },
  [WORK_OBJECTS_FORM_ACTIONS.USER_SETS_RESOURCE]: (state) => {
    return userSetsResource(state);
  },
  [WORK_OBJECTS_FORM_ACTIONS.USER_SETS_RESOURCE_PAGE]: (state, action) => {
    return {
      ...state,
      availableResources: { ...state.availableResources, page: action.payload },
    };
  },
  [WORK_OBJECTS_FORM_ACTIONS.USER_SETS_WORKFLOW]: (state) => {
    return setWorkflow(state);
  },
  [WORK_OBJECTS_FORM_ACTIONS.USER_SETS_SOURCE]: (state) => {
    return setSource(state);
  },
  [WORK_OBJECTS_FORM_ACTIONS.USER_SETS_DESTINATION]: (state) => {
    return setDestination(state);
  },
  [WORK_OBJECTS_FORM_ACTIONS.USER_SETS_VENDOR]: (state) => {
    return setVendor(state);
  },
  [WORK_OBJECTS_FORM_ACTIONS.USER_SWITCHES_SOURCE_TO_DEFAULT]: (state) => {
    if (!state.originalSource?.value?.value) {
      return state;
    }
    const originalSource = cloneDeep(state.originalSource);
    const isWorkflowSingleStep = state.workflow?.value.stepCount === 1;
    return {
      ...state,
      source: {
        ...originalSource,
        errors: [],
      },
      destination: isWorkflowSingleStep
        ? {
            ...originalSource,
            errors: [],
          }
        : state.destination,
    };
  },
  [WORK_OBJECTS_FORM_ACTIONS.USER_SWITCHES_DESTINATION_TO_DEFAULT]: (state) => {
    if (!state.originalDestination?.value?.value) {
      return state;
    }
    const originalDestination = cloneDeep(state.originalDestination);
    return {
      ...state,
      destination: {
        ...originalDestination,
        errors: [],
      },
    };
  },
  [WORK_OBJECTS_FORM_ACTIONS.USER_TOGGLES_RESOURCE]: (state, action) => {
    return {
      ...state,
      currentResource: {
        ...state.currentResource,
        value: action.payload.id,
        externalId: action.payload.externalId,
        label: action.payload.name,
        image: action.payload?.images?.length
          ? action.payload.images[0].url
          : '',
        location: action.payload?.location
          ? [
              action.payload.location?.city,
              action.payload.location?.country,
            ].join(', ')
          : '',
        type: state.availableResources.tab,
      },
    };
  },
  [WORK_OBJECTS_FORM_ACTIONS.USER_TOGGLES_WORKFLOW]: (state, action) => {
    return {
      ...state,
      currentWorkflow: {
        ...state.currentWorkflow,
        value: action.payload.id,
        label: action.payload.name,
        stepCount: action.payload.workflowSteps?.length,
      },
    };
  },
  [WORK_OBJECTS_FORM_ACTIONS.USER_TOGGLES_SOURCE]: (state, action) => {
    return {
      ...state,
      currentSource: {
        ...state.currentSource,
        value: action.payload.id,
        externalId: action.payload.externalId,
        label: action.payload.name,
        location: action.payload?.location
          ? [
              action.payload.location?.city,
              action.payload.location?.country,
            ].join(', ')
          : '',
      },
    };
  },
  [WORK_OBJECTS_FORM_ACTIONS.USER_TOGGLES_DESTINATION]: (state, action) => {
    return {
      ...state,
      currentDestination: {
        ...state.currentDestination,
        value: action.payload.id,
        externalId: action.payload.externalId,
        label: action.payload.name,
        location: action.payload?.location
          ? [
              action.payload.location?.city,
              action.payload.location?.country,
            ].join(', ')
          : '',
      },
    };
  },
  [WORK_OBJECTS_FORM_ACTIONS.USER_TOGGLES_VENDOR]: (state, action) => {
    return {
      ...state,
      currentVendor: action.payload
        ? {
            ...state.currentVendor,
            value: action.payload.id,
            externalId: action.payload.externalId,
            label: action.payload.name,
            location: action.payload?.location
              ? [
                  action.payload.location?.city,
                  action.payload.location?.country,
                ].join(', ')
              : '',
          }
        : INITIAL_FORM_STATE().currentVendor,
    };
  },
  [WORK_OBJECTS_FORM_ACTIONS.USER_CHANGES_RESOURCES_TAB]: (state, action) => {
    return {
      ...state,
      availableResources: {
        ...INITIAL_MODAL_FILTERING,
        tab: action.payload,
      },
    };
  },
  [WORK_OBJECTS_FORM_ACTIONS.USER_OPENS_RESOURCES_MODAL]: (state) => {
    return {
      ...state,
      isResourcesModalOpen: true,
    };
  },
  [WORK_OBJECTS_FORM_ACTIONS.USER_OPENS_WORKFLOWS_MODAL]: (state) => {
    return {
      ...state,
      isWorkflowsModalOpen: true,
    };
  },
  [WORK_OBJECTS_FORM_ACTIONS.USER_OPENS_SOURCES_MODAL]: (state) => {
    return {
      ...state,
      isSourcesModalOpen: true,
    };
  },
  [WORK_OBJECTS_FORM_ACTIONS.USER_OPENS_DESTINATIONS_MODAL]: (state) => {
    return {
      ...state,
      isDestinationsModalOpen: true,
    };
  },
  [WORK_OBJECTS_FORM_ACTIONS.USER_OPENS_VENDORS_MODAL]: (state) => {
    return {
      ...state,
      isVendorsModalOpen: true,
    };
  },
  [WORK_OBJECTS_FORM_ACTIONS.USER_CANCELS_RESOURCES_MODAL]: (state) => {
    return {
      ...state,
      currentResource: INITIAL_FORM_STATE().currentResource,
      isResourcesModalOpen: false,
      availableResources: INITIAL_FORM_STATE().availableResources,
    };
  },
  [WORK_OBJECTS_FORM_ACTIONS.USER_CANCELS_WORKFLOWS_MODAL]: (state) => {
    return {
      ...state,
      currentWorkflow: INITIAL_FORM_STATE().currentWorkflow,
      isWorkflowsModalOpen: false,
      availableWorkflows: INITIAL_FORM_STATE().availableWorkflows,
    };
  },
  [WORK_OBJECTS_FORM_ACTIONS.USER_CANCELS_SOURCES_MODAL]: (state) => {
    return {
      ...state,
      currentSource: INITIAL_FORM_STATE().currentSource,
      isSourcesModalOpen: false,
      availableSources: INITIAL_FORM_STATE().availableSources,
    };
  },
  [WORK_OBJECTS_FORM_ACTIONS.USER_CANCELS_DESTINATIONS_MODAL]: (state) => {
    return {
      ...state,
      currentDestination: INITIAL_FORM_STATE().currentDestination,
      isDestinationsModalOpen: false,
      availableDestinations: INITIAL_FORM_STATE().availableDestinations,
    };
  },
  [WORK_OBJECTS_FORM_ACTIONS.USER_CANCELS_VENDORS_MODAL]: (state) => {
    return {
      ...state,
      currentVendor: INITIAL_FORM_STATE().currentVendor,
      isVendorsModalOpen: false,
      availableVendors: INITIAL_FORM_STATE().availableVendors,
    };
  },
  [WORK_OBJECTS_FORM_ACTIONS.USER_SEARCHES_RESOURCES]: (state, action) => {
    return {
      ...state,
      availableResources: {
        ...state.availableResources,
        search: action.payload,
        page: 1,
      },
    };
  },
  [WORK_OBJECTS_FORM_ACTIONS.USER_SEARCHES_WORKFLOWS]: (state, action) => {
    return {
      ...state,
      availableWorkflows: {
        ...state.availableWorkflows,
        search: action.payload,
        page: 1,
      },
    };
  },
  [WORK_OBJECTS_FORM_ACTIONS.USER_SEARCHES_SOURCES]: (state, action) => {
    return {
      ...state,
      availableSources: {
        ...state.availableSources,
        search: action.payload,
        page: 1,
      },
    };
  },
  [WORK_OBJECTS_FORM_ACTIONS.USER_SETS_SOURCES_PAGE]: (state, action) => {
    return {
      ...state,
      availableSources: {
        ...state.availableSources,
        page: action.payload,
      },
    };
  },
  [WORK_OBJECTS_FORM_ACTIONS.USER_SEARCHES_DESTINATIONS]: (state, action) => {
    return {
      ...state,
      availableDestinations: {
        ...state.availableDestinations,
        search: action.payload,
        page: 1,
      },
    };
  },
  [WORK_OBJECTS_FORM_ACTIONS.USER_SEARCHES_VENDORS]: (state, action) => {
    return {
      ...state,
      availableVendors: {
        ...state.availableVendors,
        search: action.payload,
        page: 1,
      },
    };
  },
  [WORK_OBJECTS_FORM_ACTIONS.USER_SETS_DESTINATIONS_PAGE]: (state, action) => {
    return {
      ...state,
      availableDestinations: {
        ...state.availableDestinations,
        page: action.payload,
      },
    };
  },
  [WORK_OBJECTS_FORM_ACTIONS.USER_SETS_VENDORS_PAGE]: (state, action) => {
    return {
      ...state,
      availableVendors: {
        ...state.availableVendors,
        page: action.payload,
      },
    };
  },
  [WORK_OBJECTS_FORM_ACTIONS.USER_SORTS_RESOURCES]: (state, action) => {
    return {
      ...state,
      availableResources: {
        ...state.availableResources,
        ...action.payload,
      },
    };
  },
  [WORK_OBJECTS_FORM_ACTIONS.USER_SORTS_WORKFLOWS]: (state, action) => {
    return {
      ...state,
      availableWorkflows: {
        ...state.availableWorkflows,
        ...action.payload,
      },
    };
  },
  [WORK_OBJECTS_FORM_ACTIONS.USER_SETS_WORKFLOW_PAGE]: (state, action) => {
    return {
      ...state,
      availableWorkflows: {
        ...state.availableWorkflows,
        page: action.payload,
      },
    };
  },
  [WORK_OBJECTS_FORM_ACTIONS.USER_SORTS_SOURCES]: (state, action) => {
    return {
      ...state,
      availableSources: {
        ...state.availableSources,
        ...action.payload,
      },
    };
  },
  [WORK_OBJECTS_FORM_ACTIONS.USER_SORTS_DESTINATIONS]: (state, action) => {
    return {
      ...state,
      availableDestinations: {
        ...state.availableDestinations,
        ...action.payload,
      },
    };
  },
  [WORK_OBJECTS_FORM_ACTIONS.USER_SORTS_VENDORS]: (state, action) => {
    return {
      ...state,
      availableVendors: {
        ...state.availableVendors,
        ...action.payload,
      },
    };
  },

  [WORK_OBJECTS_FORM_ACTIONS.APP_IS_LOADING_WORKFLOW_DATA]: (state) => {
    return { ...state, isLoadingWorkflowData: true };
  },
  [WORK_OBJECTS_FORM_ACTIONS.APP_LOADS_WORKFLOW_DATA]: (state, action) => {
    return appLoadsWorkflowData(state, action);
  },
  [WORK_OBJECTS_FORM_ACTIONS.USER_SUBMITS_FORM]: (state) => {
    return { ...state, loading: true, isDirty: false };
  },
  [WORK_OBJECTS_FORM_ACTIONS.APP_FINISHES_SUBMISSION]: (state) => {
    return { ...state, loading: false };
  },
  [WORK_OBJECTS_FORM_ACTIONS.RESET_STATE]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [WORK_OBJECTS_FORM_ACTIONS.INITIAL_STATE]: () => {
    return INITIAL_FORM_STATE();
  },
};

export const { reducer, initialState } = makeReducer(
  WORK_OBJECTS_FORM_REDUCER_CONFIG,
  INITIAL_FORM_STATE(),
);
