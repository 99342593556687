import * as _get from 'lodash.get';
import { MAX_FIELD_CHARS } from './workflowsFormReducer';

export const workflowStateToPOSTParams = (state) => {
  return {
    id: state.id,
    name: state.name.value,
    externalId: state.externalId.value || '',
    description: state.description.value,
    workflowSteps: state.workflowSteps.map((step, stepIndex) => ({
      id: step.id || undefined,
      source: step.source.value.value ? { id: step.source.value.value } : null,
      order: stepIndex + 1,
      inspectionPairs: step.inspectionPairs.map(
        (inspection, inspectionIndex) => ({
          id: inspection.id || undefined,
          order: inspectionIndex + 1,
          isSkipable: !inspection.isRequired.value,
          isScheduled: inspection.isScheduled.value,
          inspectionType: { id: inspection.inspectionType.value.value },
          inspectionPlan: inspection.inspectionPlan.value.value
            ? { id: inspection.inspectionPlan.value.value }
            : null,
          origin: inspection.inspectionOrigin.value,
        }),
      ),
    })),
  };
};

export const setStringValue = (responseKey, data, formKey = responseKey) => {
  const value = _get(data, responseKey) || '';
  return {
    value,
    errors: [],
    charsLeft: MAX_FIELD_CHARS[formKey] - value.length,
  };
};

export const workflowToDetailState = (response) => ({
  ...response,
  workflowSteps: response.workflowSteps.sort((a, b) => a.order - b.order),
});

export const workflowsToFormState = (response) => ({
  id: response.id,
  name: setStringValue('name', response),
  externalId: setStringValue('externalId', response),
  description: setStringValue('description', response),
  workflowSteps: (response?.workflowSteps || [])
    .sort((a, b) => a.order - b.order)
    .map((step) => ({
      id: step.id,
      source: {
        value: {
          value: step?.source?.id || '',
          externalId: step?.source?.externalId || '',
          label: step?.source?.name || '',
          location: [
            step?.source?.location?.city || '',
            step?.source?.location?.country || '',
          ].join(', '),
        },
        errors: [],
      },
      inspectionPairs: (step?.inspectionPairs || []).map((inspection) => ({
        id: inspection.id,
        inspectionType: {
          value: {
            value: inspection?.inspectionType?.id || '',
            label: inspection?.inspectionType?.name || '',
          },
          errors: [],
        },
        inspectionPlan: {
          value: {
            value: inspection?.inspectionPlan?.id || '',
            label: inspection.inspectionPlan?.name || 'Inherited from Asset',
          },
          errors: [],
        },
        inspectionOrigin: {
          value: inspection.origin,
          errors: [],
        },
        isRequired: { value: !inspection?.isSkipable, errors: [] },
        isScheduled: { value: inspection?.isScheduled, errors: [] },
      })),
    })),
});
