import { useEffect } from 'react';
import debounce from 'lodash.debounce';
import { cloneDeep } from 'lodash';

import {
  ALL_DEPENDENCY_OPTIONS,
  DEPENDENCY,
  MULTIPLE_CHOICE_ANSWER_TYPES,
  SIMPLE_DEPENDENCY_OPTIONS,
} from 'config/questionOptions';

import {
  entityToGroupedSelect,
  entityToSelectOption,
  formatQuestionCriteria,
} from 'lib/dataTransform';
import tagsService from 'lib/tagsService';
import useNavigationPrompt from 'lib/useNavigationPrompt';
import { downloadResponse } from 'lib/download';
import { LIST_ACTIONS } from 'lib/list-helper/makeListReducer';
import useQueryList from 'lib/list-helper/useQueryList';

import {
  reducer,
  initialState,
  PAGE_ACTIONS,
  QUESTION_GROUP_FORM_ACTIONS,
  QUESTION_MODAL_ACTIONS,
} from './settingsQuestionGroupsListReducer';
import settingsService from './settingsService';
import {
  mapLocationStateToGroupFormState,
  questionGroupToDetailState,
  questionOptionsToFormState,
  questionToGroupFormState,
} from './dataTransform';
import settingsQuestionGroupsFormValidator from './settingsQuestionGroupsFormValidator';

const fetchQuestionGroupTemplates = debounce(
  (params, state, dispatch) => {
    dispatch({ type: LIST_ACTIONS.SET_LOADING, payload: true });
    return settingsService
      .getQuestionGroupTemplates(params)
      .then((response) => {
        const groupList = response.data.map((group) =>
          questionGroupToDetailState(group, state.questionOptions),
        );
        dispatch({
          type: LIST_ACTIONS.APP_LOADS_DATA,
          payload: {
            data: groupList,
            initialData: groupList,
            count: response.count,
            form: null,
          },
        });
      })
      .catch((e) => {
        dispatch({
          type: LIST_ACTIONS.SET_ERRORS,
          payload: e.message,
        });
      })
      .finally(() => {
        dispatch({ type: LIST_ACTIONS.SET_LOADING, payload: false });
      });
  },
  { leading: 400, trailing: 400 },
);

const fetchQuestionTemplates = debounce(
  (params, state, dispatch) => {
    return settingsService
      .getQuestionTemplates(params)
      .then((response) => {
        dispatch({
          type: QUESTION_MODAL_ACTIONS.APP_LOADS_QUESTION_TEMPLATES,
          payload: {
            list: response.data.map((q) =>
              questionToGroupFormState(q, state.questionOptions),
            ),
            count: response.count,
          },
        });
      })
      .catch((e) => {
        dispatch({
          type: LIST_ACTIONS.SET_ERRORS,
          payload: e.message,
        });
      });
  },
  { leading: 400, trailing: 400 },
);

const fetchQuestionOptions = (dispatch) => {
  return settingsService.getQuestionOptions().then((options) => {
    dispatch({
      type: PAGE_ACTIONS.APP_LOADS_QUESTION_OPTIONS,
      payload: questionOptionsToFormState(options),
    });
  });
};

const fetchAssetTypes = (dispatch) => {
  return settingsService
    .getSettingsAssetTypes({
      page: null,
      pageSize: null,
    })
    .then((res) => {
      dispatch({
        type: PAGE_ACTIONS.APP_LOADS_ASSET_TYPES,
        payload: res.data.map(entityToSelectOption('name', 'id')),
      });
    });
};

const fetchInspectionTypes = (dispatch) => {
  return settingsService
    .getSettingsInspectionTypes({
      select: ['id', 'name'],
      filters: { status: 'active' },
    })
    .then((res) => {
      dispatch({
        type: PAGE_ACTIONS.APP_LOADS_INSPECTION_TYPES,
        payload: res.data.map(entityToSelectOption('name', 'id')),
      });
    });
};
const fetchCustomAttributes = (dispatch) => {
  return settingsService
    .getCustomAttributes({
      select: ['id', 'name'],
      pageSize: 0,
      filters: { includeAnyOptions: true },
    })
    .then((res) => {
      dispatch({
        type: PAGE_ACTIONS.APP_LOADS_CUSTOM_ATTRIBUTES,
        payload: res.data.map(entityToGroupedSelect('name', 'id', 'options')),
      });
    });
};
const fetchCompanyAttributes = (dispatch) => {
  return settingsService
    .getCompanyAttributes({
      select: ['id', 'name'],
      pageSize: 0,
      filters: { includeAnyOptions: true },
    })
    .then((res) => {
      dispatch({
        type: PAGE_ACTIONS.APP_LOADS_COMPANY_ATTRIBUTES,
        payload: res.data.map(entityToGroupedSelect('name', 'id', 'options')),
      });
    });
};

const getDependencyAnswerOptions = (otherQuestion) => {
  const isMultipleChoice = MULTIPLE_CHOICE_ANSWER_TYPES.includes(
    otherQuestion.answerType?.value,
  );
  if (isMultipleChoice) {
    const criteria =
      otherQuestion.answerOptions?.map(({ label, value }) => ({
        label: formatQuestionCriteria(DEPENDENCY.SPECIFIC_ANSWER, label),
        value,
      })) || [];

    return [...criteria, ...ALL_DEPENDENCY_OPTIONS];
  } else {
    return [...SIMPLE_DEPENDENCY_OPTIONS, ...ALL_DEPENDENCY_OPTIONS];
  }
};

const fetchTags = (dispatch) => {
  return tagsService.getTags().then((tags) => {
    dispatch({
      type: PAGE_ACTIONS.APP_LOADS_TAGS,
      payload: tags.data.map(entityToSelectOption('label', 'id')),
    });
  });
};

const createQuestionFormOptions = (state, question) => {
  const deps = [];
  const maxOrder = state.form.questions.reduce(
    (acc, q) => (q.order > acc ? q.order : acc),
    0,
  );
  let dependencyAnswers = [];
  let isMoveUpDisabled = false;
  let isMoveDownDisabled = false;
  let isDeleteDisabled = false;

  state.form.questions.forEach((otherQuestion) => {
    if (otherQuestion.order === question.order) {
      return;
    }

    if (otherQuestion.order < question.order) {
      deps.push({
        value: otherQuestion.order,
        label: `#${otherQuestion.order + 1}`,
      });
    }

    if (question.dependencyQuestion?.value?.value === otherQuestion.order) {
      dependencyAnswers = getDependencyAnswerOptions(otherQuestion);

      if (otherQuestion.order === question.order - 1) {
        isMoveUpDisabled = true;
      }
    }

    if (otherQuestion.dependencyQuestion?.value?.value === question.order) {
      isDeleteDisabled = true;
      if (otherQuestion.order === question.order + 1) {
        isMoveDownDisabled = true;
      }
    }
  });

  if (question.order === 0) {
    isMoveUpDisabled = true;
  }

  if (question.order === maxOrder) {
    isMoveDownDisabled = true;
  }

  return {
    ...state.questionOptions,
    dependencyAnswerOptions: dependencyAnswers,
    possibleDependencies: deps.sort((a, b) => a.order - b.order),
    isMoveUpDisabled,
    isMoveDownDisabled,
    isDeleteDisabled,
  };
};

const useSettingsQuestionGroupsList = ({ location, history }) => {
  const { dispatch, state, ...list } = useQueryList(
    location,
    history,
    reducer,
    initialState,
  );

  useNavigationPrompt(state.form?.isDirty);

  useEffect(() => {
    if (location.state?.responseState) {
      dispatch({
        type: PAGE_ACTIONS.RESET_STATE,
        payload: mapLocationStateToGroupFormState(location.state),
      });
    } else {
      fetchQuestionOptions(dispatch);
      fetchTags(dispatch);
      fetchAssetTypes(dispatch);
      fetchInspectionTypes(dispatch);
      fetchCustomAttributes(dispatch);
      fetchCompanyAttributes(dispatch);
    }
  }, []);

  const questionGroupTemplateParams = {
    pageSize: state.pageSize,
    page: state.page,
    search: state.search,
    order: {
      [state.sortBy]: state.sortOrder,
    },
  };
  useEffect(() => {
    if (
      state.questionOptions &&
      !location.state?.responseState &&
      !state.form
    ) {
      fetchQuestionGroupTemplates(questionGroupTemplateParams, state, dispatch);
    }
  }, [
    state.search,
    state.page,
    state.pageSize,
    state.sortBy,
    state.sortOrder,
    state.questionOptions,
  ]);

  const questionTemplateParams = {
    search: state.addQuestionOptions.search,
    page: state.addQuestionOptions.page,
    pageSize: state.addQuestionOptions.pageSize,
    order: {
      [state.addQuestionOptions.sortBy]: state.addQuestionOptions.sortOrder,
    },
    filters: {
      exclude: state.form?.questions?.length
        ? state.form.questions.map((q) => Number(q.questionTemplateId))
        : undefined,
    },
  };
  useEffect(() => {
    if (state.addQuestionOptions.isModalOpen) {
      fetchQuestionTemplates(questionTemplateParams, state, dispatch);
    }
  }, [
    state.addQuestionOptions.page,
    state.addQuestionOptions.search,
    state.addQuestionOptions.sortBy,
    state.addQuestionOptions.sortOrder,
    state.addQuestionOptions.isModalOpen,
  ]);

  useEffect(() => {
    if (state.form && state.redirectScrollY) {
      window.scrollTo({
        top: state.redirectScrollY,
      });
      dispatch({
        type: PAGE_ACTIONS.RESET_STATE,
        payload: {
          redirectScrollY: undefined,
        },
      });
    }
  }, [state.form, state.redirectScrollY]);

  const userClicksAddQuestionGroup = () => {
    const formGroup = questionGroupToDetailState({});
    dispatch({
      type: PAGE_ACTIONS.USER_OPENS_ADD_QUESTION_GROUP_MODAL,
      payload: formGroup,
    });
  };

  const userClicksEditQuestionGroup = (group) => {
    const formGroup = cloneDeep(group);
    dispatch({
      type: PAGE_ACTIONS.USER_OPENS_QUESTION_GROUP_FORM,
      payload: formGroup,
    });
  };

  const userClicksCancelQuestionGroupForm = () => {
    dispatch({
      type: PAGE_ACTIONS.USER_CANCELS_QUESTION_GROUP_FORM,
    });
  };

  const userClicksSaveQuestionGroupForm = () => {
    dispatch({
      type: PAGE_ACTIONS.USER_SAVES_QUESTION_GROUP,
    });
  };

  useEffect(() => {
    if (state.processing) {
      settingsQuestionGroupsFormValidator(state.form)
        .then(settingsService.saveQuestionGroup)
        .then(() => {
          fetchQuestionGroupTemplates(
            questionGroupTemplateParams,
            state,
            dispatch,
          );
          fetchTags(dispatch);
        })
        .then(() => {
          dispatch({
            type: PAGE_ACTIONS.APP_FINISHES_SUBMISSION,
          });
        })
        .catch((e) => {
          dispatch({
            type: QUESTION_GROUP_FORM_ACTIONS.SET_FORM_ERRORS,
            payload: e.payload,
          });
          document.getElementsByClassName('is-invalid')[0]?.scrollIntoView();
        });
    }
  }, [state.processing]);

  const userChangesQuestionGroupFormInput = (key, value) => {
    dispatch({
      type: QUESTION_GROUP_FORM_ACTIONS.USER_CHANGES_GROUP_INPUT,
      payload: {
        key,
        value,
      },
    });
  };
  const userSetsGroupedSelect = (key, value, changeData) => {
    dispatch({
      type: QUESTION_GROUP_FORM_ACTIONS.USER_SETS_GROUPED_SELECT,
      payload: {
        key,
        value,
        changeData,
      },
    });
  };

  const userChangesQuestionInput = (order, key, value) => {
    if (key === 'dependencyAction' && value.value === false) {
      // Clear subordinate inputs when dependency action is 'None'
      // Criteria is cleared recursively by next check
      userChangesQuestionInput(order, 'dependencyQuestion', null);
    }

    if (key === 'dependencyQuestion') {
      // Clear criteria when changing dependency question
      userChangesQuestionInput(order, 'dependencyCriteria', null);
    }

    dispatch({
      type: QUESTION_GROUP_FORM_ACTIONS.USER_CHANGES_QUESTION_INPUT,
      payload: {
        order,
        key,
        value,
      },
    });
  };

  const userMovesQuestion = (qOrder, dir) => {
    dispatch({
      type: QUESTION_GROUP_FORM_ACTIONS.USER_MOVES_QUESTION,
      payload: {
        qOrder,
        dir,
      },
    });
  };

  const userRemovesQuestion = (id) => {
    dispatch({
      type: QUESTION_GROUP_FORM_ACTIONS.USER_REMOVES_QUESTION,
      payload: id,
    });
  };

  const getQuestionFormOptions = (question) =>
    createQuestionFormOptions(state, question);

  const userOpensAddQuestionModal = () => {
    dispatch({
      type: QUESTION_MODAL_ACTIONS.USER_OPENS_QUESTION_MODAL,
    });
  };
  const userCancelsAddQuestionModal = () => {
    dispatch({
      type: QUESTION_MODAL_ACTIONS.USER_CANCELS_QUESTION_MODAL,
    });
  };
  const userSortsAddQuestionModal = (col, order) => {
    dispatch({
      type: QUESTION_MODAL_ACTIONS.USER_SORTS_QUESTION_MODAL,
      payload: {
        col,
        order,
      },
    });
  };
  const userSearchesAddQuestionModal = (ev) => {
    dispatch({
      type: QUESTION_MODAL_ACTIONS.USER_SEARCHES_QUESTION_MODAL,
      payload: ev.target.value,
    });
  };
  const userTogglesQuestion = (question) => {
    dispatch({
      type: QUESTION_GROUP_FORM_ACTIONS.USER_TOGGLES_QUESTION,
      payload: question,
    });
  };
  const userAssignsQuestions = () => {
    dispatch({
      type: QUESTION_GROUP_FORM_ACTIONS.USER_ASSIGNS_NEW_QUESTIONS,
    });
  };

  const userSetsAddQuestionModalPage = (page) => {
    dispatch({
      type: QUESTION_MODAL_ACTIONS.USER_SETS_QUESTION_MODAL_PAGE,
      payload: page,
    });
  };

  const userClicksEditQuestion = (question) => {
    const search = new URLSearchParams();
    search.append('redirect', '/settings/question-groups');
    history.push({
      pathname: `/settings/questions/${question.questionTemplateId}/edit`,
      state: {
        redirectState: {
          ...state,
          redirectScrollY: window.scrollY,
        },
      },
      noblock: true,
      search: search.toString(),
    });
  };

  const userClicksExportGroups = () => {
    dispatch({
      type: PAGE_ACTIONS.APP_STARTS_EXPORT,
    });
    settingsService
      .exportSettingsQuestionGroups()
      .then((data) => {
        downloadResponse(data, 'question-groups.csv');
      })
      .finally(() =>
        dispatch({
          type: PAGE_ACTIONS.APP_FINISHES_EXPORT,
        }),
      );
  };

  const userClicksExportAttributes = () => {
    dispatch({
      type: PAGE_ACTIONS.APP_STARTS_EXPORT,
    });
    settingsService
      .exportSettingsQuestionGroupAttributes()
      .then((data) => {
        downloadResponse(data, 'question-group-attributes.csv');
      })
      .finally(() =>
        dispatch({
          type: PAGE_ACTIONS.APP_FINISHES_EXPORT,
        }),
      );
  };

  const selectedIdList = state.addQuestionOptions.selected.map(
    (q) => q.questionTemplateId,
  );
  const getAddQuestionList = () =>
    state.addQuestionOptions.list.map((q) => ({
      ...q,
      selected: !!selectedIdList.includes(q.questionTemplateId),
    }));

  return {
    ...list,
    state,
    dispatch,
    getAddQuestionList,

    userClicksAddQuestionGroup,
    userClicksEditQuestionGroup,
    userClicksCancelQuestionGroupForm,
    userClicksSaveQuestionGroupForm,

    getQuestionFormOptions,
    userChangesQuestionGroupFormInput,
    userSetsGroupedSelect,
    userChangesQuestionInput,
    userRemovesQuestion,
    userTogglesQuestion,
    userAssignsQuestions,
    userMovesQuestion,
    userClicksEditQuestion,

    userClicksExportGroups,
    userClicksExportAttributes,

    userOpensAddQuestionModal,
    userCancelsAddQuestionModal,
    userSortsAddQuestionModal,
    userSearchesAddQuestionModal,
    userSetsAddQuestionModalPage,
  };
};

export default useSettingsQuestionGroupsList;
