import makeLogger from 'lib/makeLogger';
import request from 'lib/request';
import { queryParamsToGETParams, userStateToPOSTParams } from './dataTransform';
import { processUserFormAPIError } from './usersFormValidator';

const log = makeLogger('usersService');

const defaultUserParams = {
  select: [
    'name',
    'companyName',
    'status',
    'companyPosition',
    'city',
    'country',
  ],
  relations: [
    'profilePhoto',
    'roles',
    'emails',
    'phoneNumbers',
    'tags',
    'sourcesTagged',
    'coordinatorsTagged',
  ],
};

const defaultUserListParams = {
  page: 1,
  pageSize: 10,
  select: ['name', 'companyName', 'status'],
  relations: ['profilePhoto', 'roles', 'emails', 'phoneNumbers'],
};

const getUsers = (params) => {
  log('Attempting to fetch users with params', params);

  return request
    .get('/users', {
      params: queryParamsToGETParams({ ...defaultUserListParams, ...params }),
    })
    .then((response) => {
      log('Users successfully fetched.', response);
      return response;
    })
    .catch((e) => {
      log(
        'There was an issue in fetching the users for the required params',
        params,
        e,
      );
      return Promise.reject(e);
    });
};

const exportUsers = () => {
  log('Attempting to export users');

  return request
    .get('/users/export')
    .then((res) => {
      log('User export successfully fetched');
      return res;
    })
    .catch((err) => {
      log('User export failed with error: ', err);
      return Promise.reject(err);
    });
};

const getUser = (id, params = {}) => {
  log('Attempting to fetch user with id', id);

  return request
    .get(`/users/${id}`, { params: { ...defaultUserParams, ...params } })
    .then((response) => {
      log('User successfully fetched', response);

      return response;
    })
    .catch((e) => {
      log('There was an issue in fetching the user for the required id', id, e);
      return Promise.reject({
        payload: {
          errors: [
            'An error has occured while performing this operation. Please try again',
          ],
        },
      });
    });
};

const sendResetPasswordEmail = (email) => {
  log(`Sending ResetPassword email to ${email}`);

  return request
    .post('/users/reset-password?resend-welcome=true', { email: email })
    .then((response) => {
      log(`Reset Password email successfully sent to ${email}`, response);
      return response;
    })
    .catch((e) => {
      log(`Error when sending password reset email to ${email}`, e);
      return Promise.reject(e);
    });
};

const saveUser = (user, config = {}) => {
  log('Attempting to save user.');

  const query = user.id ? `/users/${user.id}` : '/users';
  const method = user.id ? 'put' : 'post';

  return request[method](query, {
    ...userStateToPOSTParams(user, config.isOwnProfile),
  })
    .then((response) => {
      log('User successfully saved.', response);
      return response;
    })
    .catch((e) => {
      log('There was an issue in saving user', e.response.data.message);
      switch (e.response.data.errorCode) {
        case 'entity_body_001':
          // validation error
          const error = processUserFormAPIError(e.response.data.details, user);
          return Promise.reject({ payload: error });
        default:
          return Promise.reject({
            payload: {
              errors: [
                'An error has occured while performing this operation. Please try again',
              ],
            },
          });
      }
    });
};

const saveUserPhoto = (id, photo) => {
  log('Attempting to save photo for user', id);

  const formData = new FormData();
  formData.append('file', photo);

  return request
    .post(`/users/${id}/upload-photo`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((response) => {
      log('Photo uploaded successfully');
      return response;
    })
    .catch((e) => {
      log('There was an error in uploading the photo', e);
      return Promise.reject({
        payload: { errors: ['There was an error in uploading the photo'] },
      });
    });
};

const usersService = {
  getUsers,
  exportUsers,
  getUser,
  saveUser,
  saveUserPhoto,
  sendResetPasswordEmail,
};

export default usersService;
