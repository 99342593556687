import * as _get from 'lodash.get';
import { cloneDeep } from 'lodash';
import { MAX_FIELD_CHARS } from './workObjectsFormReducer';
import WORK_OBJECT_STATUS from 'config/workObjectStatus';
import PARTIAL_WORK_OBJECT_STATUS from 'config/partialWorkObjectStatus';

import { reduceLocation } from 'lib/dataTransform';
import { formatDateToHTMLDateString } from 'lib/dateHelpers';
import { parseISO } from 'date-fns';

export const workObjectStateToPOSTParams = (state) => {
  const isAsset = state.resource.type === 'assets';
  const isCustomSource =
    state.originalSource.value?.value !== state.source.value.value;
  const isCustomDestination =
    state.originalDestination.value?.value !== state.destination.value.value;

  return {
    asset: isAsset ? { id: state.resource.value.value } : undefined,
    source: !isAsset ? { id: state.resource.value.value } : undefined,
    vendor: state.vendor?.value?.value
      ? {
          id: state.vendor.value.value,
        }
      : null,
    externalId: state.externalId.value || '',
    quantity: Number(state.quantity.value),
    status: state.status.value.value,
    deadline: state.deadline.value,
    customFields: state.customFields.map((field, index) => ({
      name: field.name.value,
      data: field.data.value,
      order: index + 1,
    })),
    originalWorkflow: { id: state.workflow.value.value },
    customWorkObjectSteps:
      isCustomSource || isCustomDestination
        ? [
            {
              source: { id: state.source.value.value },
              order: state.source.order,
            },
            {
              source: { id: state.destination.value.value },
              order: state.destination.order,
            },
          ]
        : [],
  };
};

export const workObjectsToFormState = (response) => {
  const resource = response?.asset
    ? {
        value: {
          value: response.asset?.id || '',
          externalId: response.asset?.externalId || '',
          label: response.asset?.name || '',
          image: response.asset.images?.length
            ? response.asset.images[0].url
            : '',
          location: '',
        },
        type: 'assets',
        errors: [],
      }
    : {
        value: {
          value: response?.source?.id || '',
          externalId: response.source?.externalId || '',
          label: response?.source?.name || '',
          image: '',
          location:
            reduceLocation(response?.source?.location, 'city', 'country') || '',
        },
        type: 'sources',
        errors: [],
      };

  const sortedSteps = response.workObjectSteps.sort(
    (a, b) => a.order - b.order,
  );
  const sortedOriginalSteps = response.originalWorkflow.workflowSteps.sort(
    (a, b) => a.order - b.order,
  );

  const workflowLength = sortedSteps.length;
  const originalWorkflowLength = sortedOriginalSteps.length;

  const sourceStep = workflowLength ? sortedSteps[0] : undefined;

  const destinationStep = workflowLength
    ? sortedSteps[workflowLength - 1]
    : undefined;

  const originalSource = originalWorkflowLength
    ? sortedOriginalSteps[0]
    : undefined;
  const originalDestination = originalWorkflowLength
    ? sortedOriginalSteps[originalWorkflowLength - 1]
    : undefined;

  return {
    resource,
    originalQuantity: response?.quantity || 0,
    externalId: setStringValue('externalId', response),
    quantity: {
      value: response?.quantity || 0,
      errors: [],
    },
    status: {
      value: {
        value: response?.status || '',
        label: WORK_OBJECT_STATUS[response?.status],
      },
      errors: [],
    },
    deadline: {
      value: response?.deadline
        ? formatDateToHTMLDateString(parseISO(response.deadline))
        : '',
      errors: [],
    },
    customFields: (response?.customFields || []).map((field) => ({
      name: { value: field?.name || '', errors: [] },
      data: { value: field?.data || '', errors: [] },
    })),
    workflow: {
      value: {
        value: response?.originalWorkflow?.id || '',
        label: response?.originalWorkflow?.name || '',
        stepCount: response?.workObjectSteps?.length,
      },
      errors: [],
    },
    source: {
      value: sourceStep?.source
        ? {
            value: sourceStep.source.id || '',
            externalId: sourceStep?.source?.externalId || '',
            label: sourceStep.source.name || '',
            location:
              reduceLocation(sourceStep.source.location, 'city', 'country') ||
              '',
          }
        : null,
      order: sourceStep?.order || '',
      errors: [],
    },
    destination: {
      value: sourceStep?.source
        ? {
            value: destinationStep?.source?.id || '',
            externalId: destinationStep?.source?.externalId || '',
            label: destinationStep?.source?.name || '',
            location:
              reduceLocation(
                destinationStep?.source?.location,
                'city',
                'country',
              ) || '',
          }
        : null,
      order: destinationStep?.order || '',
      errors: [],
    },
    vendor: {
      value: response?.vendor?.id
        ? {
            value: response.vendor.id || '',
            externalId: response?.vendor?.externalId || '',
            label: response.vendor?.name || '',
            location: [
              response.vendor?.location?.city ?? null,
              response.vendor?.location?.country ?? null,
            ]
              .filter((item) => item)
              .join(', '),
          }
        : null,
      errors: [],
    },
    originalSource: {
      value: originalSource.source
        ? {
            value: originalSource.source.id || '',
            externalId: originalSource?.source?.externalId || '',
            label: originalSource.source.name || '',
            location:
              reduceLocation(
                originalSource.source.location,
                'city',
                'country',
              ) || '',
          }
        : null,
      order: originalSource.order || '',
      errors: [],
    },
    originalDestination: {
      value: originalDestination.source
        ? {
            value: originalDestination.source.id || '',
            externalId: originalDestination?.source?.externalId || '',
            label: originalDestination.source.name || '',
            location:
              reduceLocation(
                originalDestination.source.location,
                'city',
                'country',
              ) || '',
          }
        : null,
      order: originalDestination.order || '',
      errors: [],
    },
    id: response?.id,
    hasPartials: !!response?.partialWorkObjects?.length,
    hasAcceptedPartials: (response?.partialWorkObjects || []).some(
      (partial) => partial.status === PARTIAL_WORK_OBJECT_STATUS.ACCEPTED,
    ),
  };
};

export const workObjectsToDetailState = (response) => {
  const partialsClone = cloneDeep(response?.partialWorkObjects || []);
  partialsClone.sort((a, b) => a?.id - b?.id);
  return {
    ...response,
    partialWorkObjects: partialsClone.map((partial) => ({
      ...partial,
      partialWorkObjectSteps: partial.partialWorkObjectSteps.map((step) => ({
        ...step,
        inspectionPairs: step.inspectionPairs.map((pair) => ({
          ...pair,
          inspection: pair.inspection
            ? {
                ...pair.inspection,
                actualDecisions: pair.inspection.actualDecisions
                  .filter((d) => !d.isAutomatic)
                  .sort(
                    (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
                  ),
              }
            : null,
        })),
      })),
    })),
  };
};

export const setStringValue = (responseKey, data, formKey = responseKey) => {
  const value = _get(data, responseKey) || '';
  return {
    value,
    errors: [],
    charsLeft: MAX_FIELD_CHARS[formKey] - value.length,
  };
};

export const getStatus = (status) => {
  return WORK_OBJECT_STATUS[status] || '';
};
