import React, { useContext, useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';

import { AppContext } from 'App';
import {
  companyMenuItems,
  qmAdminMenuItems,
} from 'components/sideMenu/menuConfig';
import ROLES from 'config/roles';
import { MULTIPLE_COMPANIES_ROLES } from '../../companyPermissions';

import { APP_HOME, ADMIN_HOME } from 'config/navigation';
import NETWORK_EVENTS from 'events/network-events';
import appBus from 'lib/appBus';
import ProtectedComponent from 'lib/components/protected-component/ProtectedComponent';
import useRoles from 'lib/useRoles';

import Header from 'components/header/Header';
import Menu from 'components/sideMenu/Menu';
import CompanySelectDropdown from '../company-select-dropdown/CompanySelectDropdown';

import AssetsRoutes from 'modules/assets/routes';
import UsersRoutes from 'modules/users/routes';
import SourcesRoutes from 'modules/sources/routes';
import InspectionsRoutes from 'modules/inspections/routes';
import InspectionTypesRoutes from 'modules/inspection-types/routes';
import InpectionPlansRoutes from 'modules/inspection-plans/routes';
import WorkflowsRoutes from 'modules/workflows/routes';
import WorkObjectsRoutes from 'modules/work-objects/routes';
import SettingsRoutes from 'modules/settings/routes';
import DashboardRoutes from 'modules/dashboard/routes';
import CompaniesRoutes from 'modules/companies/routes';
import UnsubscribeRoutes from 'modules/unsubscribe/routes';
import PlaygroundRoutes from 'modules/playground/routes';

import { ReactComponent as CompactLogo } from 'assets/logo/exacture-compact-rgb-on-light.svg';

import './AdminPanel.scss';

const companyRoutes = (
  <>
    <AssetsRoutes />
    <UsersRoutes />
    <SourcesRoutes />
    <InspectionTypesRoutes />
    <InpectionPlansRoutes />
    <InspectionsRoutes />
    <WorkflowsRoutes />
    <WorkObjectsRoutes />
    <SettingsRoutes />
    <DashboardRoutes />
    <UnsubscribeRoutes />
    <PlaygroundRoutes />
    <Route
      path='/'
      exact
      render={({ history }) => {
        history.push(APP_HOME);
        return null;
      }}
    />
  </>
);

const qmAdminRoutes = (
  <>
    <UsersRoutes />
    <CompaniesRoutes />
    <UnsubscribeRoutes />
    <Route
      path='/'
      exact
      render={({ history }) => {
        history.push(ADMIN_HOME);
        return null;
      }}
    />
  </>
);

function AdminPanel() {
  const {
    appState: { company },
  } = useContext(AppContext);
  const history = useHistory();
  const { isAllowed } = useRoles();

  const hasCompanySelected = !!company;
  const menuItems = hasCompanySelected ? companyMenuItems : qmAdminMenuItems;
  const routes = hasCompanySelected ? companyRoutes : qmAdminRoutes;
  const isQMAdmin = isAllowed([ROLES.QM_ADMIN]);

  useEffect(() => {
    // QM-1299: When the user hits a 403 or 404 on a route, instead of
    // displaying an error page we just go up one level until we hit
    // an endpoint that the user can see
    const onNotFound = (req) => {
      const { pathname } = history.location;
      const isHome = hasCompanySelected
        ? pathname === APP_HOME
        : pathname === ADMIN_HOME;

      if (isHome) {
        // To prevent redirect loops, if we're not finding/allowed to see
        // even the home route we give up. We don't try to refresh the page
        // either to avoid a refresh loop.
        // TODO: When we have non-ideal state pages redirect to an 'Oops' page.
        return;
      }

      if (req.config.method === 'get') {
        history.push(pathname.slice(-1) === '/' ? '..' : '.');
      }
    };

    appBus.sub(NETWORK_EVENTS.NOT_FOUND, onNotFound);
    appBus.sub(NETWORK_EVENTS.FORBIDDEN, onNotFound);

    return () => {
      appBus.unsub(NETWORK_EVENTS.NOT_FOUND, onNotFound);
      appBus.unsub(NETWORK_EVENTS.FORBIDDEN, onNotFound);
    };
  }, [hasCompanySelected, history]);

  return (
    <>
      <div className='sideMenu'>
        <CompactLogo className='header-logo' />
        <Menu items={menuItems} />
        <ProtectedComponent allowed={MULTIPLE_COMPANIES_ROLES}>
          <CompanySelectDropdown logOut={isQMAdmin && hasCompanySelected} />
        </ProtectedComponent>
      </div>
      <div className='page'>
        <div className='header'>
          <Header />
        </div>
        <div className='content'>{routes}</div>
      </div>
    </>
  );
}

export default AdminPanel;
