import React, { useContext } from 'react';
import { AppContext } from 'App';
import { Link } from 'react-router-dom';

import classNames from 'classnames';
import { DEFECT_LABEL, DEFECT_WEIGHT } from 'config/defects';
import { getInspectionStatusLabel } from 'config/inspectionStatus';
import {
  ANSWER_MEANING_LABEL,
  ANSWER_TYPE,
  PRINT_ON_REPORT,
  QUESTION_TYPE_LABEL,
} from 'config/questionOptions';

import { getTranslation, reduceLocation } from 'lib/dataTransform';
import { formatDate } from 'lib/dateHelpers';

import { formatOtherActualDefectLabel } from '../../dataTransform';
import useInspectionReportPrint from '../../useInspectionReportPrint';

import './InspectionReportPrint.scss';

const PRINT_CONFIG = {
  [PRINT_ON_REPORT.ALWAYS]: () => true,
  [PRINT_ON_REPORT.ON_FAIL]: (q) => q.actualDefects?.length,
  [PRINT_ON_REPORT.NEVER]: () => false,
};

function UnitsTable({ asset }) {
  const { UOM, Qty } = asset.measureTable?.columnValues || {};

  if (!UOM || !Qty) {
    return null;
  }

  const displayUOM = UOM.filter((_, idx) => Qty[idx]);
  const displayQty = Qty.filter((qty) => qty);

  if (!displayQty.length) {
    return null;
  }

  return (
    <table className='asset-units-table'>
      <thead>
        <tr>
          <th>Units of Measurement</th>
          {displayUOM.map((uom) => (
            <th className='measurement-unit' key={uom}>
              {uom}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Quantity</td>
          {displayQty.map((qty, i) => (
            <th className='measurement-quantity' key={i}>
              {qty}
            </th>
          ))}
        </tr>
      </tbody>
    </table>
  );
}

function getFormattedAnswer(question, language) {
  const rejectionStatus =
    typeof question.isRejected === 'boolean'
      ? question.isRejected
        ? 'Reject'
        : 'Accept'
      : null;

  switch (question.answerType) {
    case ANSWER_TYPE.DATE_SELECTION:
      return formatDate(new Date(question.actualAnswer));
    case ANSWER_TYPE.MULTIPLE_CHOICE:
    case ANSWER_TYPE.BARCODE_INPUT:
      const answer = question.answer.options.find(
        (opt) => opt.order === Number(question.actualAnswer),
      );
      const translation = getTranslation(answer?.label || [], language).display;
      return !!answer
        ? `${translation} (${ANSWER_MEANING_LABEL[answer.meaning]})`
        : rejectionStatus !== null
        ? `(${rejectionStatus})`
        : false;
    default:
      return question.actualAnswer ?? rejectionStatus;
  }
}

function getConclusion(inspection) {
  const hasDecisionPoint = !!inspection.currentDecisionPoint;
  const lastDecision =
    inspection.actualDecisions?.[inspection.actualDecisions.length - 1]?.name;
  return hasDecisionPoint ? 'Pending decision' : lastDecision;
}

function questionFailedAQL(question, countDefectiveUnits = true) {
  if (!question.useAqlLevel || !question.isAqlLevelOverwritten) {
    return false;
  }
  const { actualDefects } = question;
  const countVal = countDefectiveUnits ? 'defectiveUnits' : 'qty';

  const sums = Object.values(DEFECT_WEIGHT).reduce(
    (sums, weight) => ({ ...sums, [weight]: 0 }),
    {},
  );

  actualDefects.forEach(
    (defect) => (sums[defect.weight] += parseInt(defect[countVal])),
  );

  return !!Object.keys(sums).filter(
    (k) => sums[k] > parseInt(question[`${k}DefectAllowed`]) ?? 0,
  ).length;
}

const filterPrintOnReport = (question) =>
  PRINT_CONFIG[question.printOnReport](question);

function ReportAnswerDetail({ data }) {
  const questionPhotos = (data.actualPhotos || []).map((p) => ({
    url: p.url,
    id: p.id,
    caption: ' ',
  }));
  const defectPhotos = data.actualDefects
    ?.map((d) =>
      d.photos.map((p) => ({
        url: p.url,
        id: p.id,
        caption: getTranslation(d.defect.name).display,
      })),
    )
    .flat();
  const photos = [...questionPhotos, ...defectPhotos];

  const show = {
    questionNotes: !!data.questionNotes,
    singleMeasurements: !!data.singleMeasurements?.length,
    actualDefects: !!data.actualDefects?.length,
    photos: !!photos.length,
  };

  return (
    <>
      <tr className='answer-summary'>
        <td className='question-order'>{data.order + 1}</td>
        <td className='question-name'>
          <p>{getTranslation(data.name).display}</p>
        </td>
        <td className='actual-answer'>{getFormattedAnswer(data) || '-'}</td>
        <td rowSpan='2' className='sample-size'>
          <div className='calculated-sample-size'>{data.sampleSize}</div>
          <div className='aql-standards'>
            {data.isAqlLevelOverwritten ? (
              <>
                <div className='aql-level'>{data.aqlLevel}</div>
                <div className='defect-weight' data-weight='Major'>
                  {data.majorDefect}
                </div>
                <div className='defect-weight' data-weight='Minor'>
                  {data.minorDefect}
                </div>
                <div className='defect-weight' data-weight='Functional'>
                  {data.functionalDefect}
                </div>
              </>
            ) : (
              !!data.useAqlLevel && (
                <div className='aql-override-status'>
                  AQL inherited from plan
                </div>
              )
            )}
          </div>
        </td>
      </tr>
      <tr
        className={classNames('answer-details', { 'show-photos': show.photos })}
      >
        <td></td>
        <td colSpan='2' className='answer-body'>
          <div className='question-type'>{QUESTION_TYPE_LABEL[data.type]}</div>
          {show.questionNotes && (
            <p className='question-notes'>{data.questionNotes}</p>
          )}
          {show.singleMeasurements && (
            <div className='single-measurements'>
              {data.singleMeasurements.map((measure) => (
                <div
                  key={measure.id}
                  className={classNames('measure', {
                    exceeded: measure.defects?.length,
                  })}
                >
                  {measure.value}
                </div>
              ))}
            </div>
          )}
          {show.actualDefects && (
            <div className='actual-defects'>
              <h5>Defects</h5>
              {data.actualDefects
                .map(formatOtherActualDefectLabel)
                .map((defect) => (
                  <div className='defect' key={defect.id}>
                    {getTranslation(defect.defect.name).display}
                    {` - `}
                    {DEFECT_LABEL[defect.weight]}
                    {` - `}
                    {defect.qty} defect(s) on {defect.defectiveUnits} unit(s)
                    {!!defect.notes && (
                      <p className='defect-notes'>{defect.notes}</p>
                    )}
                  </div>
                ))}
            </div>
          )}
        </td>
      </tr>
      {show.photos && (
        <tr className='answer-photos'>
          <td></td>
          <td colSpan='3' className='photos'>
            {photos.map((photo) => (
              <figure className='photo' key={photo.id}>
                <img alt={photo.caption} src={photo.url} />
                <figcaption>{photo.caption}</figcaption>
              </figure>
            ))}
          </td>
        </tr>
      )}
    </>
  );
}

function QuestionGroup({ data }) {
  const filtered = data.questions.filter(filterPrintOnReport);

  if (!filtered.length) {
    return null;
  }

  return (
    <table className='question-group'>
      <tbody>
        <tr>
          <td colSpan='4'>
            <h4>{data.name}</h4>
          </td>
        </tr>
        {filtered.map((question) => (
          <ReportAnswerDetail key={question.id} data={question} />
        ))}
      </tbody>
    </table>
  );
}

export default function InspectionReport(props) {
  const { appState } = useContext(AppContext);

  const isEnableInspectionFunctionalPlusMajor =
    !!appState?.company?.customConfig?.enableInspectionFunctionalPlusMajor;

  const isEnableInspectionFunctionalPlusMajorCount =
    isEnableInspectionFunctionalPlusMajor && false;
  const isEnableInspectionFunctionalPlusMajorStandard =
    isEnableInspectionFunctionalPlusMajor;

  const { inspection, company } = useInspectionReportPrint(props);

  if (!inspection) {
    return null;
  }

  const { questionGroups, locationSource, asset, inspector } = inspection;

  const flatQuestions = questionGroups.map((g) => g.questions).flat();
  const flatDefects = flatQuestions
    .map((q) => q.actualDefects)
    .flat()
    .map(formatOtherActualDefectLabel);
  const defectSummary = flatDefects.reduce((acc, actualDefect) => {
    const defectKey = getTranslation(actualDefect.defect.name).display;

    if (!acc[defectKey]) {
      acc[defectKey] = {
        id: defectKey,
        name: defectKey,
        count: Object.values(DEFECT_WEIGHT).reduce((acc, curr) => {
          acc[curr] = 0;
          return acc;
        }, {}),
      };
    }

    acc[defectKey].count[actualDefect.weight] += actualDefect.defectiveUnits;
    return acc;
  }, {});

  const sampleSize = flatQuestions.reduce(
    (max, q) => (q.sampleSize > max ? q.sampleSize : max),
    0,
  );

  const source = inspection.source ?? locationSource;
  const conclusion = getConclusion(inspection);
  const numFailedQuestions = flatQuestions.filter((q) =>
    questionFailedAQL(q),
  ).length;

  const companyInfo = {
    addr1: company.location?.address,
    addr2: reduceLocation(company.location, 'city', 'state'),
    addr3: reduceLocation(company.location, 'zipCode', 'country'),
    phone: company.phones?.find((p) => p.isPreferred)?.phone,
    email: company.emails?.find((p) => p.isPreferred)?.email,
  };

  const showAQLStandards =
    inspection.criticalDefectValue !== null &&
    inspection.majorDefect !== null &&
    inspection.minorDefect !== null &&
    inspection.functionalDefect !== null;

  return (
    <div className='inspection-report-page container-m'>
      <article className='inspection-report'>
        <header>
          <h3 className='company-name'>{company.name}</h3>
          <div className='company-address'>
            {!!companyInfo.addr1 && <div>{companyInfo.addr1}</div>}
            {!!companyInfo.addr2 && <div>{companyInfo.addr2}</div>}
            {!!companyInfo.addr3 && <div>{companyInfo.addr3}</div>}
          </div>
          <div className='company-contact'>
            {!!companyInfo.phone && (
              <div className='company-phone'>{companyInfo.phone}</div>
            )}
            {!!companyInfo.email && (
              <div className='company-email'>{companyInfo.email}</div>
            )}
          </div>
        </header>
        <h2 className='report-title'>Inspection Report</h2>
        <summary>
          <div className='summary-title'>
            <div className='summary-left'>
              <h4 className='inspection-type'>
                {inspection.inspectionType?.name}
                <span className='inspection-id'>{inspection.id}</span>
              </h4>
              <div className='inspection-date'>
                {formatDate(inspection?.finishedAt)}
              </div>
              {!!conclusion && (
                <div className='inspection-conclusion'>{conclusion}</div>
              )}
              <div className='inspection-result'>
                {getInspectionStatusLabel(inspection.result)}
              </div>
              {inspection.aqlLevel && (
                <div className='inspection-aql-level labelled-value'>
                  <span className='label'>AQL Level</span>
                  <span className='value'>{inspection.aqlLevel}</span>
                </div>
              )}
            </div>
            <div className='summary-right'>
              {!!inspection.workObjectId && (
                <div className='work-object-internal-id'>
                  <Link
                    to={`/work-objects/${inspection.workObjectId}${
                      inspection.partialOrder > 1
                        ? `?currentTab=${inspection.partialOrder - 1}`
                        : ''
                    }`}
                  >
                    {inspection.workObjectId} – {inspection.partialOrder}
                  </Link>
                </div>
              )}
              {!!inspection.workObjectExternalId && (
                <div className='work-object-external-id'>
                  {inspection.workObjectExternalId} – {inspection.partialOrder}
                </div>
              )}
            </div>
          </div>
          {!!inspection.woCustomFields.length && (
            <div className='summary-attributes'>
              <h5>Work object attributes</h5>
              <div className='attribute-grid'>
                {inspection.woCustomFields
                  .filter((a) => a.data)
                  .map((attr) => (
                    <div key={attr.id}>
                      {attr.name}: <b>{attr.data}</b>
                    </div>
                  ))}
              </div>
            </div>
          )}
          <div className='summary-items'>
            {!!asset && (
              <div className='asset-info'>
                <div className='flex-row'>
                  {!!asset.images?.length && (
                    <div className='asset-picture'>
                      <img alt='' src={asset.images[0].url} />
                    </div>
                  )}
                  <div className='asset-description'>
                    <div className='asset-name'>{asset.name}</div>
                    <div className='asset-id'>{asset.showId}</div>
                    {!!inspection.partialQty && (
                      <div>
                        Qty produced: <strong>{inspection.partialQty}</strong>
                      </div>
                    )}
                    {!!sampleSize && (
                      <div>
                        Sample Size: <strong>{sampleSize}</strong>
                      </div>
                    )}
                  </div>
                </div>
                <UnitsTable asset={asset} />
              </div>
            )}
            <div className='source-info'>
              <div>
                <div className='source-name'>{source.name}</div>
                {source.type?.name && (
                  <div className='source-type'>{source.type?.name}</div>
                )}
              </div>
              <div className='source-id'>{source.externalId || source.id}</div>
              <div className='source-address'>
                {!!source.location?.address && <p>{source.location.address}</p>}
                <p>
                  {reduceLocation(
                    source.location,
                    'city',
                    'district',
                    'state',
                    'country',
                  )}
                </p>
              </div>
            </div>
            {!!inspector && (
              <div className='inspector-info'>
                {!!inspector.profilePhoto?.url && (
                  <img
                    alt=''
                    src={inspector.profilePhoto.url}
                    className='inspector-avatar'
                  />
                )}
                <div className='inspector-description'>
                  <div className='inspector-name'>{inspector.name}</div>
                  {!!inspector.companyName && (
                    <div className='inspector-company'>
                      {inspector.companyName}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          <table className='summary-aql-overview'>
            <tbody>
              <tr>
                <td>
                  <table className='defect-overview-table'>
                    <caption>Defect overview</caption>
                    <thead>
                      <tr>
                        <th>Defect name</th>
                        <th>Critical</th>
                        <th>Major</th>
                        <th>Minor</th>
                        <th>Functional</th>
                        {!!isEnableInspectionFunctionalPlusMajorCount && (
                          <th>
                            Functional
                            <br />
                            &amp; Major
                          </th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(defectSummary).map((key) => (
                        <tr key={defectSummary[key].id}>
                          <td>{defectSummary[key].name}</td>
                          <td>{defectSummary[key].count.critical}</td>
                          <td>{defectSummary[key].count.major}</td>
                          <td>{defectSummary[key].count.minor}</td>
                          <td>{defectSummary[key].count.functional}</td>
                          {!!isEnableInspectionFunctionalPlusMajorCount && (
                            <td>
                              {defectSummary[key].count.functional +
                                defectSummary[key].count.major}
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </td>
                <td>
                  <table className='aql-overview-table'>
                    <caption>Inspection standards</caption>
                    <thead>
                      <tr>
                        <th></th>
                        <th>Critical</th>
                        <th>Major</th>
                        <th>Minor</th>
                        <th>Functional</th>
                        {!!isEnableInspectionFunctionalPlusMajorStandard && (
                          <th>
                            Functional
                            <br />
                            &amp; Major
                          </th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {!!showAQLStandards && (
                        <tr>
                          <td>AQL</td>
                          <td>{inspection.criticalDefectValue}</td>
                          <td>{inspection.majorDefect}</td>
                          <td>{inspection.minorDefect}</td>
                          <td>{inspection.functionalDefect}</td>
                          {!!isEnableInspectionFunctionalPlusMajorStandard && (
                            <td>
                              {Number(inspection.majorDefect) +
                                Number(inspection.functionalDefect)}
                            </td>
                          )}
                        </tr>
                      )}
                      <tr>
                        <td>Accept criteria</td>
                        <td>{inspection.criticalDefectAllowed}</td>
                        <td>{inspection.majorDefectAllowed}</td>
                        <td>{inspection.minorDefectAllowed}</td>
                        <td>{inspection.functionalDefectAllowed}</td>
                        {!!isEnableInspectionFunctionalPlusMajorStandard && (
                          <td>
                            {Number(inspection.majorDefectAllowed) +
                              Number(inspection.functionalDefectAllowed)}
                          </td>
                        )}
                      </tr>
                      <tr>
                        <td>Reject criteria</td>
                        <td>{inspection.criticalDefectAllowed + 1}</td>
                        <td>{inspection.majorDefectAllowed + 1}</td>
                        <td>{inspection.minorDefectAllowed + 1}</td>
                        <td>{inspection.functionalDefectAllowed + 1}</td>
                        {!!isEnableInspectionFunctionalPlusMajorStandard && (
                          <td>
                            {Number(inspection.majorDefectAllowed) +
                              Number(inspection.functionalDefectAllowed) +
                              2}
                          </td>
                        )}
                      </tr>
                      <tr>
                        <td>Defects found</td>
                        <td>{inspection.criticalDefectsCountAll}</td>
                        <td>{inspection.majorDefectsCountAll}</td>
                        <td>{inspection.minorDefectsCountAll}</td>
                        <td>{inspection.functionalDefectsCountAll}</td>
                        {!!isEnableInspectionFunctionalPlusMajorStandard && (
                          <td>
                            {Number(inspection.majorDefectsCountAll) +
                              Number(inspection.functionalDefectsCountAll)}
                          </td>
                        )}
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </summary>
        <h2>Inspection Results</h2>
        {!!numFailedQuestions && (
          <div className='sum-failed-aql-questions'>
            Number of questions that failed AQL: <b>{numFailedQuestions}</b>
          </div>
        )}
        {!!inspection.summary && (
          <div className='summary-notes'>
            <h4>Inspection summary:</h4>
            <p>{inspection.summary}</p>
          </div>
        )}
        {questionGroups.map((group) => (
          <QuestionGroup key={group.id} data={group} />
        ))}
      </article>
    </div>
  );
}
