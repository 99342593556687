import { formatDate } from 'lib/dateHelpers';
import { formatAqlLevelSummary } from 'lib/dataTransform';

import Table from 'lib/components/table/Table';
import InspectionPlanStatusPill from '../inspection-plans-status-pill/InspectionPlansStatusPill';
import StatusPill, {
  STATUS_PILL_VARIANT,
} from 'lib/components/status-pill/StatusPill';
import WarningPill from 'lib/components/warning-pill/WarningPill';

import './InspectionPlansTable.scss';
import Card from '../card/Card';

const inspectionPlansListColumns = [
  {
    title: 'ID',
    sortId: 'id',
    className: 'id-cell',
    render: (record) => (
      <>
        {!record.linkedResource && <WarningPill overlay='Asset not assigned' />}
        <span>{record.id}</span>
      </>
    ),
  },
  {
    title: 'Target',
    sortId: 'linkedResource.name',
    className: 'target-cell',
    render: (record) => {
      const srcType = ['asset', 'source'].includes(record?.linkedResource?.type)
        ? record.linkedResource.type
        : null;
      const srcData = srcType ? record[srcType] ?? null : null;

      if (srcData?.id) {
        return (
          <Card
            title={srcData.name}
            subtitle={
              <>
                <div>
                  ID: <strong>{srcData.id}</strong> (
                  {srcType === 'asset' ? 'Asset' : 'Source'})
                </div>
                {srcData.externalId && (
                  <div>
                    {' '}
                    Ext. ID: <strong>{srcData.externalId}</strong>
                  </div>
                )}
              </>
            }
          />
        );
      }
      return null;
    },
  },
  {
    title: 'Name',
    sortId: 'name',
    className: 'name-cell',
    render: (record) => record.name,
  },
  {
    title: 'Description',
    sortId: 'description',
    className: 'name-cell',
    render: (record) => record.description,
  },
  {
    title: 'Inspection type',
    sortId: 'inspectionType.name',
    className: 'name-cell',
    render: (record) => (
      <span className='pill-container'>
        <StatusPill variant={STATUS_PILL_VARIANT.NOTIFY}>
          {record['inspectionType.name']}
        </StatusPill>
      </span>
    ),
  },
  {
    title: 'Date published / AQL',
    className: 'aql-cell',
    sortId: 'publishedAtWithAql',
    textAlign: 'left',
    render: (record) => (
      <>
        {!!record.publishedAt && <div>{formatDate(record.publishedAt)}</div>}
        {!!record.useAqlLevel && (
          <div>
            {formatAqlLevelSummary(
              record.aqlLevel,
              record.majorDefect,
              record.minorDefect,
              record.functionalDefect,
            )}
          </div>
        )}
      </>
    ),
  },
  {
    title: 'Status',
    className: 'pill-cell',
    sortId: 'status',
    textAlign: 'right',
    render: (record) => (
      <span className='pill-container'>
        <InspectionPlanStatusPill status={record.status} />
      </span>
    ),
  },
];

const InspectionPlansTable = ({ userClicksOnRow, ...props }) => (
  <Table
    columns={inspectionPlansListColumns}
    onRowClick={(record) => userClicksOnRow(record.id)}
    className='inspection-plans-table'
    {...props}
  />
);

export default InspectionPlansTable;
