import { useEffect, useReducer, useContext } from 'react';
import debounce from 'lodash.debounce';

import { AppContext } from 'App';
import INSPECTION_STATUS, {
  EDITABLE_STATUS_LIST,
  PERFORMABLE_STATUS_LIST,
} from 'config/inspectionStatus';
import useRoles from 'lib/useRoles';

import {
  reducer,
  initialState,
  INSPECTIONS_DETAIL_ACTIONS,
} from './inspectionsDetailReducer';
import inspectionsService from './inspectionsService';
import {
  EDIT_THIRD_PARTY_ANYTIME_ROLES,
  EDIT_THIRD_PARTY_PUBLISHED_ROLES,
  REASSIGN_INSPECTION_ROLES,
  VIEW_INSPECTION_ROLES,
} from './inspectionsPermissions';

const getAvailableInspectors = debounce(
  (params, dispatch) => {
    return inspectionsService
      .getInspectors(params)
      .then((response) => {
        dispatch({
          type: INSPECTIONS_DETAIL_ACTIONS.APP_LOADS_INSPECTORS,
          payload: { data: response.data, count: response.count },
        });
      })
      .catch(() => {
        dispatch({
          type: INSPECTIONS_DETAIL_ACTIONS.RESET_STATE,
          payload: {
            errors: ['An error occured while fetching inspection details'],
          },
        });
      });
  },
  400,
  { leading: true, trailing: true },
);

const cancelInspection = (id, notes, dispatch) =>
  inspectionsService
    .cancelInspection(id, notes)
    .catch((e) =>
      dispatch({
        type: INSPECTIONS_DETAIL_ACTIONS.RESET_STATE,
        payload: e.payload,
      }),
    )
    .then(() => {
      dispatch({
        type: INSPECTIONS_DETAIL_ACTIONS.USER_CLOSES_CANCEL_MODAL,
      });
      fetchInspection(id, dispatch);
    });

const fetchInspection = (id, dispatch) =>
  inspectionsService
    .getInspection(id)
    .then((results) => {
      dispatch({
        type: INSPECTIONS_DETAIL_ACTIONS.APP_LOADS_INSPECTION,
        payload: {
          inspection: results,
          loading: false,
          errors: [],
        },
      });
    })
    .catch((e) => {
      dispatch({
        type: INSPECTIONS_DETAIL_ACTIONS.RESET_STATE,
        payload: e.payload,
      });
    });

const useInspectionsDetail = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { isAllowed } = useRoles();
  const {
    appState: { currentUser },
  } = useContext(AppContext);

  const isEditInternalAllowed =
    EDITABLE_STATUS_LIST.includes(state.status) && !state.isThirdParty;

  const isReassignInternalAllowed =
    isEditInternalAllowed ||
    (state.status === INSPECTION_STATUS.IN_PROGRESS &&
      isAllowed(REASSIGN_INSPECTION_ROLES));

  const isEditThirdPartyAllowed =
    state.isThirdParty &&
    (isAllowed(EDIT_THIRD_PARTY_ANYTIME_ROLES) ||
      ([INSPECTION_STATUS.DRAFT, INSPECTION_STATUS.PUBLISHED].includes(
        state.status,
      ) &&
        isAllowed(EDIT_THIRD_PARTY_PUBLISHED_ROLES)));

  const isUserClickable = () => {
    return isAllowed(VIEW_INSPECTION_ROLES);
  };

  const userClicksOnAvatar = (user) => {
    props.history.push(`/users/${user.id}`);
  };

  const id = props.match?.params?.id;

  useEffect(() => {
    fetchInspection(id, dispatch);
  }, []);

  const userClicksInspectorSelect = () => {
    dispatch({
      type: INSPECTIONS_DETAIL_ACTIONS.USER_OPENS_INSPECTORS_MODAL,
    });
  };
  const userCancelsInspectorsModal = () => {
    dispatch({
      type: INSPECTIONS_DETAIL_ACTIONS.USER_CANCELS_INSPECTORS_MODAL,
    });
  };
  const userSearchesInspectors = (ev) => {
    dispatch({
      type: INSPECTIONS_DETAIL_ACTIONS.USER_SEARCHES_INSPECTORS,
      payload: ev.target.value,
    });
  };

  const userClicksCancel = () => {
    dispatch({
      type: INSPECTIONS_DETAIL_ACTIONS.USER_OPENS_CANCEL_MODAL,
    });
  };

  const userSubmitsCancelModal = () => {
    cancelInspection(id, state.cancelNotes.notes, dispatch);
  };

  const userCancelsCancelModal = () => {
    dispatch({
      type: INSPECTIONS_DETAIL_ACTIONS.USER_CLOSES_CANCEL_MODAL,
    });
  };

  const userTypesCancelNotes = (ev) => {
    dispatch({
      type: INSPECTIONS_DETAIL_ACTIONS.USER_TYPES_CANCEL_NOTES,
      payload: ev.target.value,
    });
  };

  const userSelectsInspector = (inspector) => {
    inspectionsService
      .saveInspectorAndDate(id, { inspector })
      .then(() => {
        dispatch({
          type: INSPECTIONS_DETAIL_ACTIONS.USER_SELECTS_INSPECTOR,
          payload: inspector,
        });
      })
      .catch((e) =>
        dispatch({
          type: INSPECTIONS_DETAIL_ACTIONS.APP_FAILS_ASSIGN_INSPECTOR,
          payload: e.payload,
        }),
      );
  };
  const userClearsInspector = () => {
    userSelectsInspector(null);
  };

  const userSortsInspectors = (col, order) => {
    dispatch({
      type: INSPECTIONS_DETAIL_ACTIONS.USER_SORTS_INSPECTORS,
      payload: {
        sortBy: col,
        sortOrder: order,
      },
    });
  };
  const userSetsInspectorsPage = (page) => {
    dispatch({
      type: INSPECTIONS_DETAIL_ACTIONS.USER_SETS_PAGE_FOR_INSPECTORS,
      payload: page,
    });
  };

  const userSetsDate = (value) => {
    inspectionsService
      .saveInspectorAndDate(id, {
        inspectionDate: value,
      })
      .then(() => {
        dispatch({
          type: INSPECTIONS_DETAIL_ACTIONS.USER_SETS_DATE,
          payload: value,
        });
      })
      .catch((e) =>
        dispatch({
          type: INSPECTIONS_DETAIL_ACTIONS.RESET_STATE,
          payload: e.payload,
        }),
      );
  };

  const userSetsWindow = (value) => {
    const [startDate, endDate] = value;

    if (!!startDate === !!endDate) {
      inspectionsService
        .saveInspectorAndDate(id, {
          inspectionWindowStartDate: startDate || null,
          inspectionWindowEndDate: endDate || null,
        })
        .then(() => {
          dispatch({
            type: INSPECTIONS_DETAIL_ACTIONS.USER_SETS_WINDOW,
            payload: value,
          });
        })
        .catch((e) =>
          dispatch({
            type: INSPECTIONS_DETAIL_ACTIONS.RESET_STATE,
            payload: e.payload,
          }),
        );
    } else {
      dispatch({
        type: INSPECTIONS_DETAIL_ACTIONS.USER_SETS_WINDOW,
        payload: value,
      });
    }
  };

  const userClearsDate = () => {
    userSetsDate(null);
  };

  const userClearsWindow = () => {
    userSetsWindow(['', '']);
  };

  const userClicksPlanNeedsReview = () => {
    props.history.push(
      `/inspection-plans/${state.inspectionPlan.id}?needs-review=true`,
    );
  };

  const sourceSearch = state.source?.id || state.locationSource?.id;
  const availableInspectorsParams = {
    search: state.availableInspectors.search,
    order: {
      [state.availableInspectors.sortBy]: state.availableInspectors.sortOrder,
    },
    page: state.availableInspectors.page,
    pageSize: state.availableInspectors.pageSize,
    filters: {
      sourceIds: sourceSearch ? [sourceSearch] : undefined,
      status: 'active',
    },
  };

  useEffect(() => {
    if (state.availableInspectors.isModalOpen) {
      getAvailableInspectors(availableInspectorsParams, dispatch);
    }
  }, [
    state.availableInspectors.search,
    state.availableInspectors.sortBy,
    state.availableInspectors.page,
    state.availableInspectors.sortOrder,
    state.availableInspectors.isModalOpen,
  ]);

  const isCurrentUserAssigned =
    Number(state.inspector?.value?.id) === Number(currentUser.id);
  const isStatusPerformable = PERFORMABLE_STATUS_LIST.includes(state.status);
  const { canPerformWebAppInspections } = state.inspector?.value || {};
  const isPerformAllowed =
    !state.isThirdParty &&
    isCurrentUserAssigned &&
    isStatusPerformable &&
    canPerformWebAppInspections;

  const isCancellable =
    !state.isThirdParty &&
    [INSPECTION_STATUS.IN_PROGRESS, INSPECTION_STATUS.PUBLISHED].includes(
      state.status,
    );

  return {
    state,
    isUserClickable,
    userClicksOnAvatar,
    isEditInternalAllowed,
    isReassignInternalAllowed,
    isEditThirdPartyAllowed,
    isPerformAllowed,
    isCancellable,
    userSetsDate,
    userClearsDate,
    userSetsWindow,
    userClearsWindow,
    userSortsInspectors,
    userSetsInspectorsPage,
    userSelectsInspector,
    userSearchesInspectors,
    userCancelsInspectorsModal,
    userClicksInspectorSelect,
    userClicksCancel,
    userClicksPlanNeedsReview,
    userSubmitsCancelModal,
    userCancelsCancelModal,
    userTypesCancelNotes,
    userClearsInspector,
  };
};

export default useInspectionsDetail;
