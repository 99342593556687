import debounce from 'lodash.debounce';
import { useEffect, useReducer } from 'react';
import {
  reducer,
  initialState,
  PAGE_ACTIONS,
  QUESTION_FORM_ACTIONS,
  DEFECTS_MODAL_ACTIONS,
} from './settingsQuestionsFormReducer';

import { defectToFormState, questionToFormState } from './dataTransform';

import { entityToSelectOption, upsertTranslation } from 'lib/dataTransform';
import questionFormValidator from './settingsQuestionsListValidator';
import useQueryParams from 'lib/useQueryParams';
import { QUESTION_TYPE, ANSWER_TYPE } from 'config/questionOptions';
import { SYSTEM_DEFECTS } from 'config/defects';
import useQuestionOptions from 'lib/questionOptionsService';
import useLanguages from 'lib/languageService';
import settingsService from './settingsService';
import { DEFAULT_LANGUAGE } from 'config/languages';
import useNavigationPrompt from 'lib/useNavigationPrompt';

function isSystemDefect(defect) {
  return Object.values(SYSTEM_DEFECTS).includes(defect.name);
}

function isDefectsDisabled(answerType) {
  return [
    ANSWER_TYPE.QUANTITATIVE_INPUT,
    ANSWER_TYPE.DATE_SELECTION,
    ANSWER_TYPE.TEXT_AREA_INPUT,
  ].includes(answerType);
}
const fetchAnswerOptions = (dispatch) => {
  return settingsService
    .getSettingsAnswers()
    .then((list) => {
      dispatch({
        type: PAGE_ACTIONS.APP_LOADS_ANSWER_OPTIONS,
        payload: list.data.map(entityToSelectOption('name', 'id')),
      });
    })
    .catch((e) => {
      dispatch({
        type: PAGE_ACTIONS.SET_ERRORS,
        payload: e.payload,
      });
    });
};

const fetchAssetCustomMeasures = (dispatch) => {
  return settingsService
    .getAssetCustomMeasures()
    .then((list) => {
      dispatch({
        type: PAGE_ACTIONS.APP_LOADS_ASSET_CUSTOM_MEASURES,
        payload: list.data.map(entityToSelectOption('label', 'id')),
      });
    })
    .catch((e) => {
      dispatch({
        type: PAGE_ACTIONS.SET_ERRORS,
        payload: e.payload,
      });
    });
};

const fetchQuestionTemplate = (id, options, dispatch) => {
  settingsService.getQuestionTemplates({ filters: { id } }).then((response) => {
    dispatch({
      type: PAGE_ACTIONS.APP_LOADS_QUESTION_TEMPLATE,
      payload: questionToFormState(response.data[0], options),
    });
  });
};

const fetchDefects = debounce(
  (params, dispatch, defectOptions) => {
    return settingsService
      .getSettingsDefects(params)
      .then((response) => {
        dispatch({
          type: DEFECTS_MODAL_ACTIONS.APP_LOADS_DEFECTS,
          payload: {
            list: response.data
              .filter((d) => !isSystemDefect(d))
              .map((d) => defectToFormState(d, defectOptions)),
            count: response.count,
          },
        });
      })
      .catch(() => {
        dispatch({
          type: DEFECTS_MODAL_ACTIONS.SET_ERRORS,
          payload: ['An error occured while fetching defects'],
        });
      });
  },
  400,
  { leading: true, trailing: true },
);

const prepareStateForValidation = (form) => ({
  ...form,
  isEditing: true,
});

const useSettingsQuestionsForm = ({ location, history, match }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { getParams } = useQueryParams(location, history);
  const { questionOptions } = useQuestionOptions();
  const { languages } = useLanguages();

  useNavigationPrompt(state.isDirty);

  const { redirect } = getParams();

  useEffect(() => {
    fetchAnswerOptions(dispatch);
    fetchAssetCustomMeasures(dispatch);
  }, []);

  useEffect(() => {
    if (
      match.params.id &&
      !state.form.id &&
      Object.keys(questionOptions).length
    ) {
      fetchQuestionTemplate(match.params.id, questionOptions, dispatch);
    }
  }, [state.form, questionOptions]);

  const defectsParams = {
    search: state.availableDefects.search,
    order: {
      [state.availableDefects.sortBy]: state.availableDefects.sortOrder,
    },
    pageSize: state.availableDefects.pageSize,
    page: state.availableDefects.page,
  };
  useEffect(() => {
    if (state.availableDefects.isModalOpen) {
      fetchDefects(defectsParams, dispatch, questionOptions.questionWeight);
    }
  }, [
    state.availableDefects.search,
    state.availableDefects.page,
    state.availableDefects.sortBy,
    state.availableDefects.sortOrder,
    state.availableDefects.isModalOpen,
  ]);

  const userCancelsQuestionForm = () => {
    if (redirect) {
      history.push({
        pathname: redirect,
        state: {
          responseState: location.state?.redirectState,
        },
      });
    } else {
      history.push('/settings/questions');
    }
  };

  const userSavesQuestionForm = () => {
    dispatch({
      type: QUESTION_FORM_ACTIONS.USER_SUBMITS_FORM,
    });
  };

  useEffect(() => {
    if (state.processing) {
      questionFormValidator(prepareStateForValidation(state.form))
        .then((question) => settingsService.saveQuestion(question))
        .then((response) => {
          const newDocs = state.form.documents.filter((d) => !d.file.url);
          if (newDocs.length) {
            return Promise.all(
              newDocs.map((doc) =>
                settingsService.saveQuestionDocument(
                  response.id,
                  doc.value,
                  doc.file,
                ),
              ),
            );
          }
          return response;
        })
        .then(() => {
          if (redirect) {
            settingsService
              .getQuestionTemplates({ filters: { id: state.form.id } })
              .then((response) => {
                history.push({
                  pathname: redirect,
                  state: {
                    responseState: location.state?.redirectState,
                    question: response.data[0],
                  },
                });
              });
          } else {
            history.push('/settings/questions');
          }
        })
        .catch((e) => {
          dispatch({
            type: QUESTION_FORM_ACTIONS.SET_ERRORS,
            payload: e.payload,
          });
          document.getElementsByClassName('is-invalid')[0]?.scrollIntoView();
        })
        .finally(() => {
          dispatch({
            type: QUESTION_FORM_ACTIONS.APP_FINISHES_SUBMISSION,
          });
        });
    }
  }, [state.processing]);

  const userSetsLanguage = (code) => {
    dispatch({
      type: PAGE_ACTIONS.USER_SETS_LANGUAGE,
      payload: code,
    });
  };
  useEffect(() => {
    // Pre-select first language on langauges loaded
    if (languages.length && !state.language) {
      dispatch({
        type: PAGE_ACTIONS.USER_SETS_LANGUAGE,
        payload: languages[0].code,
      });
    }
  }, [languages, state.language]);

  const userChangesQuestionName = (value) => {
    const { language, form } = state;
    const newName = upsertTranslation(form.name.value, value, language);
    dispatch({
      type: QUESTION_FORM_ACTIONS.USER_CHANGES_QUESTION_INPUT,
      payload: {
        key: 'name',
        value: newName,
        arrayIdx: false,
      },
    });
  };

  const userChangesQuestionInput = (key, value, arrayIdx = false) => {
    if (key === 'name') {
      return userChangesQuestionName(value);
    }
    // Some inputs require further validation,
    // changing some keys invalidates the state of others
    const question = state.form;
    switch (key) {
      case 'type':
        if (value?.value === QUESTION_TYPE.INFORMATIONAL_NON_AQL) {
          userChangesQuestionInput('useAqlLevel', false);
        }
        break;
      case 'answerType':
        // Temporary fix, defects aren't enabled for some answer types
        if (isDefectsDisabled(value?.value)) {
          appResetsSelectedDefects();
        }
        userChangesQuestionInput('expectedBarcodeUOM', null);
        break;
      case 'dependencyAction':
        userChangesQuestionInput('dependencyQuestion', null);
        break;
      case 'dependencyQuestion':
        userChangesQuestionInput('dependencyCriteria', null);
        break;
      case 'dynamicAction':
        userChangesQuestionInput('dynamicCriteria', null);
        break;
      case 'aqlLevel':
        if (value === null) {
          userChangesQuestionInput('aqlMajor', null);
          userChangesQuestionInput('aqlMinor', null);
          userChangesQuestionInput('aqlFunctional', null);
          userChangesQuestionInput('criticalDefect', '');
        }
        break;
      case 'sampleRule':
        // Reset sibling input error state
        userChangesQuestionInput('sampleQty', question.sampleQty.value);
        break;
      case 'lowerToleranceRule':
        userChangesQuestionInput(
          'lowerTolerance',
          question.lowerTolerance.value,
        );
        break;
      case 'upperToleranceRule':
        userChangesQuestionInput(
          'upperTolerance',
          question.upperTolerance.value,
        );
        break;
      case 'dynamicRule':
        if (value?.value === 'first_inspection') {
          userChangesQuestionInput('dynamicCriteria', '1');
        }
        break;
      case 'customExpectedMeasureValue':
        if (typeof value === 'number') {
          // Hard to debug type-casting happens on api submit
          value = value.toString();
        }
        value = (value || '').replace(',', '.').replace(/[^0-9.-]/g, '');
        break;
      case 'lowerTolerance':
      case 'upperTolerance':
      case 'dynamicCriteria':
      case 'sampleQty':
        if (typeof value === 'number') {
          // Hard to debug type-casting happens on api submit
          value = value.toString();
        }
        value = (value || '').replace(',', '.').replace(/[^0-9.]/g, '');
        break;
      case 'lowerToleranceWeight':
        // FIXME: Remove workaround once BE is properly fixed
        const lowerIdx = question.defects.findIndex(
          (def) => def.name === SYSTEM_DEFECTS.LOWER_TOLERANCE,
        );
        if (lowerIdx !== -1) {
          userChangesQuestionInput('defects.weight', value, lowerIdx);
        }
        break;
      case 'upperToleranceWeight':
        // FIXME: Remove workaround once BE is properly fixed
        const upperIdx = question.defects.findIndex(
          (def) => def.name === SYSTEM_DEFECTS.UPPER_TOLERANCE,
        );
        if (upperIdx !== -1) {
          userChangesQuestionInput('defects.weight', value, upperIdx);
        }
        break;
      case 'expectedMeasureTableResult':
        if (value === null || value.value === 'Custom') {
          userChangesQuestionInput('expectedBarcodeUOM', null);
        }
        if (value?.value !== 'Custom') {
          userChangesQuestionInput('upperTolerance', null);
          userChangesQuestionInput('lowerTolerance', null);
        }
        break;
      default:
        break;
    }
    return dispatch({
      type: QUESTION_FORM_ACTIONS.USER_CHANGES_QUESTION_INPUT,
      payload: {
        key,
        value,
        arrayIdx,
      },
    });
  };

  const userAddsTool = () => {
    dispatch({
      type: QUESTION_FORM_ACTIONS.USER_ADDS_QUESTION_TOOL,
    });
  };

  const userRemovesTool = (toolIdx) => {
    dispatch({
      type: QUESTION_FORM_ACTIONS.USER_REMOVES_QUESTION_TOOL,
      payload: toolIdx,
    });
  };

  const userAddsAssetReferenceDocument = () => {
    dispatch({
      type: QUESTION_FORM_ACTIONS.USER_ADDS_ASSET_REFERENCE_DOCUMENT,
    });
  };

  const userRemovesAssetReferenceDocument = (docIdx) => {
    dispatch({
      type: QUESTION_FORM_ACTIONS.USER_REMOVES_ASSET_REFERENCE_DOCUMENT,
      payload: docIdx,
    });
  };

  const userRemovesDefect = (defect) => {
    dispatch({
      type: QUESTION_FORM_ACTIONS.USER_REMOVES_QUESTION_DEFECT,
      payload: defect,
    });
  };

  const userAddsDocuments = (fileArray) => {
    dispatch({
      type: QUESTION_FORM_ACTIONS.USER_ADDS_QUESTION_DOCUMENTS,
      payload: fileArray,
    });
  };

  const userRemovesDocument = (docIdx) => {
    dispatch({
      type: QUESTION_FORM_ACTIONS.USER_REMOVES_QUESTION_DOCUMENT,
      payload: docIdx,
    });
  };
  const userResetsDocuments = () => {
    dispatch({
      type: QUESTION_FORM_ACTIONS.USER_RESETS_QUESTION_DOCUMENTS,
    });
  };

  const getQuestionOptions = () => {
    const isDocumentListChanged =
      JSON.stringify(state.form?.documents) !==
      JSON.stringify(state.form?.initialDocuments);

    return {
      ...questionOptions,
      otherMeasureOptions: state.otherMeasureOptions,
      answerOptions: state.answerOptions,
      isDocumentListChanged,
    };
  };

  const getDefectsList = () => {
    return state.availableDefects.list.map((defect) => ({
      ...defect,
      selected: state.availableDefects.selected.some(
        (sel) => sel.id === defect.id,
      ),
    }));
  };

  const appResetsSelectedDefects = () => {
    dispatch({
      type: DEFECTS_MODAL_ACTIONS.APP_RESETS_SELECTED_DEFECTS,
    });
  };
  const userOpensDefectsModal = () => {
    dispatch({
      type: DEFECTS_MODAL_ACTIONS.USER_OPENS_DEFECTS_MODAL,
    });
  };
  const userCancelsDefectsModal = () => {
    dispatch({
      type: DEFECTS_MODAL_ACTIONS.USER_CANCELS_DEFECTS_MODAL,
    });
  };
  const userTogglesDefect = (defect) => {
    return dispatch({
      type: DEFECTS_MODAL_ACTIONS.USER_TOGGLES_DEFECT,
      payload: defect,
    });
  };
  const userEditsDefectList = () => {
    return dispatch({
      type: QUESTION_FORM_ACTIONS.USER_EDITS_DEFECTS_LIST,
    });
  };
  const userSearchesDefects = (ev) => {
    return dispatch({
      type: DEFECTS_MODAL_ACTIONS.USER_SEARCHES_DEFECTS,
      payload: ev.target.value,
    });
  };
  const userClicksCreateDefect = () => {
    settingsService
      .saveDefect({
        name: {
          value: [
            {
              language: DEFAULT_LANGUAGE,
              text: state.availableDefects.search,
            },
          ],
        },
      })
      .then(() =>
        fetchDefects(defectsParams, dispatch, questionOptions.questionWeight),
      )
      .catch(() => {
        dispatch({
          type: DEFECTS_MODAL_ACTIONS.SET_ERRORS,
          payload: ['Could not create defect'],
        });
      });
  };

  const userSortsDefects = (col, order) => {
    return dispatch({
      type: DEFECTS_MODAL_ACTIONS.USER_SORTS_DEFECTS,
      payload: {
        sortBy: col,
        sortOrder: order,
      },
    });
  };

  const userSetsDefectsPage = (page) => {
    return dispatch({
      type: DEFECTS_MODAL_ACTIONS.USER_SETS_DEFECTS_PAGE,
      payload: page,
    });
  };

  const handlePasteEvent = (event) => {
    userAddsDocuments(event.clipboardData?.files ?? []);
  };

  useEffect(() => {
    window.addEventListener('paste', handlePasteEvent);
    return () => {
      window.removeEventListener('paste', handlePasteEvent);
    };
  }, []);

  return {
    state,
    questionOptions,
    languages,
    dispatch,
    getQuestionOptions,
    getDefectsList,

    userSetsLanguage,
    userSavesQuestionForm,
    userCancelsQuestionForm,

    userChangesQuestionInput,
    userAddsTool,
    userRemovesTool,
    userAddsAssetReferenceDocument,
    userRemovesAssetReferenceDocument,
    userAddsDocuments,
    userRemovesDocument,
    userResetsDocuments,
    userRemovesDefect,

    appResetsSelectedDefects,
    userOpensDefectsModal,
    userCancelsDefectsModal,
    userTogglesDefect,
    userEditsDefectList,
    userSearchesDefects,
    userSortsDefects,
    userClicksCreateDefect,
    userSetsDefectsPage,
  };
};

export default useSettingsQuestionsForm;
