import { useEffect, useReducer } from 'react';
import {
  reducer,
  initialState,
  INSPECTION_TYPES_DETAILS_ACTIONS,
} from './inspectionTypesDetailsReducer';
import inspectionTypesService from './inspectionTypesService';

const defaultInspectionTypesDetailsParams = {
  select: ['name', 'status', 'type'],
  relations: ['usageDecisions'],
};

const useInspectionTypesDetails = ({ match }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const id = match?.params?.id;

    id &&
      inspectionTypesService
        .getInspectionType({ id, ...defaultInspectionTypesDetailsParams })
        .then((results) =>
          dispatch({
            type: INSPECTION_TYPES_DETAILS_ACTIONS.RESET_STATE,
            payload: {
              inspectionType: results,
              loading: false,
              errors: [],
            },
          }),
        );
  }, []);

  return {
    state,
  };
};

export default useInspectionTypesDetails;
