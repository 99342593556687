import { INSPECTION_TARGET, INSPECTOR_TYPE } from 'config/inspectionConfig';
import { reduceLocation } from 'lib/dataTransform';
import { getMinFutureCalendarDate } from 'lib/dateHelpers';

import AddButton from 'lib/components/add-button/AddButton';
import AssetsModal from 'lib/components/assets-modal/AssetsModal';
import Avatar from 'lib/components/avatar/Avatar';
import Button from 'lib/components/button/Button';
import DatePicker from 'lib/components/date-picker/DatePicker';
import DateRangePicker from 'lib/components/date-range-picker/DateRangePicker';
import DeleteButton from 'lib/components/delete-button/DeleteButton';
import DocumentUpload from 'lib/components/document-upload/DocumentUpload';
import EditButton from 'lib/components/edit-button/EditButton';
import ErrorBag from 'lib/components/error-bag/ErrorBag';
import File from 'lib/components/file/File';
import Input from 'lib/components/input/Input';
import InspectorsModal from '../inspectors-modal/InspectorsModal';
import Radio from 'lib/components/radio/Radio';
import ResourceSelectButton from 'lib/components/resource-select-button/ResourceSelectButton';
import Select from 'lib/components/select/Select';
import SourcesModal from 'lib/components/sources-modal/SourcesModal';
import UploadButton from 'lib/components/upload-button/UploadButton';

import useInspectionThirdPartyForm from '../../useInspectionThirdPartyForm';
import SELECTORS from './fixtures/selectors.json';

import './InspectionThirdPartyForm.scss';

const InspectionThirdPartyForm = (props) => {
  const {
    state,
    inspectorsModal,
    assetsModal,
    sourcesModal,
    ...vm
  } = useInspectionThirdPartyForm(props);

  const SubmitButton = state.id ? EditButton : AddButton;
  const submitText = state.id
    ? 'Save changes'
    : 'Create third party inspection';

  const showInternalInspector =
    state.inspectorType.value === INSPECTOR_TYPE.INTERNAL;

  const showThirdPartyInspector = !showInternalInspector;

  const showAssetSelect =
    state.inspectionTarget.value === INSPECTION_TARGET.ASSET;

  return (
    <div className='inspection-third-party-form'>
      <form noValidate>
        <div className='form-row'>
          <Radio
            label='Inspector type'
            data-testid={SELECTORS.inspectorType}
            options={vm.inspectorTypeOptions}
            errors={state.inspectorType.errors}
            value={state.inspectorType.value}
            onChange={vm.userSetsInspectorType}
          />
        </div>
        {showInternalInspector && (
          <div className='form-row inspector-select'>
            <div className='form-label'>Select inspector</div>
            <div className='form-group'>
              <ResourceSelectButton
                data-testid={SELECTORS.inspectorSelect}
                onClick={vm.userClicksOpenInspectorModal}
                title={state.inspector.value?.name}
                errors={state.inspector.errors}
                subtitle={state.inspector.value?.companyName}
                thumbnail={
                  !!state.inspector.value && (
                    <Avatar
                      url={state.inspector.value.profilePhoto?.url}
                      placeholder={state.inspector.value.name}
                    />
                  )
                }
              />
              {state.inspector.value && (
                <DeleteButton onClick={() => vm.userSelectsInspector(null)} />
              )}
            </div>
          </div>
        )}
        {showThirdPartyInspector && (
          <>
            <div className='form-row'>
              <Input
                label='Inspector name'
                data-testid={SELECTORS.thirdPartyInspectorName}
                value={state.thirdPartyInspectorName.value}
                errors={state.thirdPartyInspectorName.errors}
                onChange={vm.userTypesInspectorName}
              />
            </div>
            <div className='form-row'>
              <Input
                label='Inspecting company'
                data-testid={SELECTORS.thirdPartyInspectingCompany}
                value={state.thirdPartyInspectingCompany.value}
                errors={state.thirdPartyInspectingCompany.errors}
                onChange={vm.userTypesInspectingCompany}
              />
            </div>
          </>
        )}
        <div className='form-row'>
          <Radio
            label='Target of inspection'
            data-testid={SELECTORS.inspectionTarget}
            options={vm.inspectionTargetOptions}
            errors={state.inspectionTarget.errors}
            value={state.inspectionTarget.value}
            onChange={vm.userSetsInspectionTarget}
            disabled={vm.isResourceSelectDisabled}
          />
        </div>
        {showAssetSelect && (
          <div className='form-row asset-select'>
            <ResourceSelectButton
              label='Select asset'
              data-testid={SELECTORS.assetSelect}
              onClick={vm.userClicksOpenAssetModal}
              errors={state.asset.errors}
              title={state.asset.value?.name}
              thumbUrl={state.asset.value?.images?.[0]?.url}
              disabled={vm.isResourceSelectDisabled}
            />
          </div>
        )}
        <div className='form-row source-select'>
          <ResourceSelectButton
            label='Select source'
            data-testid={SELECTORS.sourceSelect}
            onClick={vm.userClicksOpenSourceModal}
            title={state.source.value?.name}
            errors={state.source.errors}
            subtitle={reduceLocation(
              state.source.value?.location,
              'country',
              'city',
            )}
            thumbUrl={state.source.value?.images?.[0]?.url}
            disabled={vm.isResourceSelectDisabled}
          />
        </div>
        <div className='form-row type-select'>
          <Select
            label='Inspection type'
            value={state.inspectionType.value}
            errors={state.inspectionType.errors}
            onChange={vm.userSetsInspectionType}
            options={vm.getInspectionTypeOptions()}
          />
        </div>
        <div className='form-row window-input'>
          <DateRangePicker
            label='Inspection window'
            min={getMinFutureCalendarDate()}
            data-testid={SELECTORS.inspectionWindow}
            errors={state.inspectionWindow.errors}
            value={state.inspectionWindow.value}
            inline
            onChange={vm.userSetsInspectionWindow}
            isClearable
          />
        </div>
        <div className='form-row date-input'>
          <DatePicker
            label='Inspection date'
            data-testid={SELECTORS.inspectionDate}
            errors={state.inspectionDate.errors}
            value={state.inspectionDate.value}
            onChange={vm.userSetsInspectionDate}
          />
        </div>
        <div className='form-row'>
          <DocumentUpload
            label='Associated documents'
            data={state.documents.list}
            errors={state.documents.errors}
            onChange={vm.userTypesDocumentName}
            onDelete={vm.userDeletesDocument}
            onUpload={vm.userUploadsDocuments}
            hasChanges={vm.isDocumentListChanged()}
            onCancel={vm.userResetsDocuments}
            disabled={vm.isScheduledInspection()}
          />
        </div>
        <div className='form-row'>
          <Radio
            label='Inspection result'
            data-testid={SELECTORS.inspectionResult}
            options={vm.inspectionResultOptions}
            errors={state.inspectionResult.errors}
            value={state.inspectionResult.value}
            onChange={vm.userSetsInspectionResult}
            disabled={vm.isScheduledInspection()}
          />
        </div>
        <div className='form-row date-input'>
          <DatePicker
            label='Valid until'
            data-testid={SELECTORS.validUntil}
            errors={state.validUntil.errors}
            value={vm.isValidUntilDisabled ? '' : state.validUntil.value}
            min={state.inspectionDate.value}
            onChange={vm.userSetsValidUntil}
            disabled={vm.isValidUntilDisabled}
          />
        </div>
        <div className='form-row inspection-report-container'>
          <div className='form-label'>Inspection report</div>
          {!!state.inspectionReport.file && !vm.isReportDisabled && (
            <File
              data={state.inspectionReport.file}
              name={state.inspectionReport.value}
            />
          )}
          <div className='list-controls'>
            <UploadButton
              disabled={vm.isReportDisabled}
              onUpload={vm.userUploadsInspectionReport}
            >
              Upload
            </UploadButton>
            {vm.isReportChanged() && !vm.isReportDisabled && (
              <Button
                type='button'
                onClick={vm.userResetsReport}
                data-testid={SELECTORS.cancelReportChanges}
                className='medium-button'
              >
                Cancel changes
              </Button>
            )}
          </div>
          <ErrorBag errors={state.inspectionReport.errors} />
        </div>
        <SubmitButton
          className='submit-button'
          data-testid={SELECTORS.submitButton}
          type='button'
          disabled={state.isSubmitting}
          onClick={vm.userSubmitsForm}
        >
          {submitText}
        </SubmitButton>
      </form>
      <InspectorsModal
        title='Select inspector'
        data-testid={SELECTORS.inspectorsModal}
        className='inspection-list--inspector-modal'
        {...inspectorsModal.getModalProps()}
      />
      <SourcesModal
        title='Select source'
        data-testid={SELECTORS.sourcesModal}
        className='inspection-list--sources-modal'
        {...sourcesModal.getModalProps()}
      />
      <AssetsModal
        title='Select asset'
        data-testid={SELECTORS.assetsModal}
        className='inspection-list--assets-modal'
        {...assetsModal.getModalProps()}
      />
    </div>
  );
};

export default InspectionThirdPartyForm;
