import { useState } from 'react';
import classNames from 'classnames';

import Accordion from 'lib/components/accordion/Accordion';
import StatusPill, {
  STATUS_PILL_VARIANT,
} from 'lib/components/status-pill/StatusPill';
import Tooltip from 'lib/components/tooltip/Tooltip';

import { ReactComponent as PencilOutline } from 'assets/images/pencil-outline.svg';

import './SettingsQuestionGroup.scss';
import {
  QUESTION_GROUP_STATUS,
  QUESTION_GROUP_STATUS_LABEL,
} from 'config/questionOptions';

const QUESTION_COLUMNS = [
  {
    title: '',
    className: 'edit-cell',
    textAlign: 'center',
  },
  {
    title: '#',
    className: 'id-cell',
    textAlign: 'left',
  },
  {
    title: 'Question',
    className: 'question-cell',
    textAlign: 'left',
  },
  {
    title: 'Question Type',
    className: 'question-type-cell',
    textAlign: 'left',
  },
  {
    title: 'Sample Size',
    className: 'sample-size-cell',
    textAlign: 'right',
  },
  {
    title: '',
    className: 'toggle-cell',
    textAlign: 'right',
  },
];

const QuestionGroupTitle = ({ data, editable, onEditClick }) => (
  <>
    <div className='question-group-name'>
      {data.name.value}
      {editable && (
        <button
          type='button'
          onClick={onEditClick}
          className='edit-question-group-button'
        >
          <Tooltip overlay='Edit question group' placement='top'>
            <PencilOutline />
          </Tooltip>
        </button>
      )}
    </div>
    <div className='question-group-details'>
      {!!data.order.value && (
        <div className='question-group-order'>
          <span>Order:</span>
          <StatusPill variant={STATUS_PILL_VARIANT.NOTIFY}>
            {data.order.value}
          </StatusPill>
        </div>
      )}
      {!!data.tags?.value?.length && (
        <div className='question-group-tags'>
          <span>Tags:</span>
          {(data.tags.value || []).map((tag) => (
            <StatusPill key={tag.value} variant={STATUS_PILL_VARIANT.NOTIFY}>
              {tag.label}
            </StatusPill>
          ))}
        </div>
      )}
      {!!data.assetTypes?.value?.length && (
        <div className='question-group-asset-types'>
          <span>Asset types:</span>
          {(data.assetTypes.value || []).map((type) => (
            <StatusPill key={type.value} variant={STATUS_PILL_VARIANT.NOTIFY}>
              {type.label}
            </StatusPill>
          ))}
        </div>
      )}
      {!!data.inspectionTypes?.value?.length && (
        <div className='question-group-inspection-types'>
          <span>Inspection types:</span>
          {(data.inspectionTypes.value || []).map((type) => (
            <StatusPill key={type.value} variant={STATUS_PILL_VARIANT.NOTIFY}>
              {type.label}
            </StatusPill>
          ))}
        </div>
      )}
      {!!data.customAttributes?.value?.length && (
        <div className='question-group-custom-attributes'>
          <span>Custom attributes:</span>
          {(data.customAttributes.value || []).map((attr) => (
            <StatusPill key={attr.value} variant={STATUS_PILL_VARIANT.NOTIFY}>
              {attr.groupLabel}:{' '}
              <strong>{attr?.label || `Any (${attr.groupLabel})`}</strong>
            </StatusPill>
          ))}
        </div>
      )}
      {!!data.companyAttributes?.value?.length && (
        <div className='question-group-company-attributes'>
          <span>Company attributes:</span>
          {(data.companyAttributes.value || []).map((attr) => (
            <StatusPill key={attr.value} variant={STATUS_PILL_VARIANT.NOTIFY}>
              {attr.groupLabel}:{' '}
              <strong>{attr?.label || `Any (${attr.groupLabel})`}</strong>
            </StatusPill>
          ))}
        </div>
      )}
    </div>
  </>
);

const SettingsQuestionGroup = ({
  data,
  editable,
  initialOpen,
  children,
  className,
  onEditClick,
  hasErrors = false,
  ...props
}) => {
  const [isOpen, setOpen] = useState(initialOpen ?? true);

  const actions =
    data.status?.value === QUESTION_GROUP_STATUS.DRAFT ? (
      <StatusPill variant={STATUS_PILL_VARIANT.WARNING}>
        {QUESTION_GROUP_STATUS_LABEL[QUESTION_GROUP_STATUS.DRAFT]}
      </StatusPill>
    ) : null;

  return (
    <Accordion
      title={
        <QuestionGroupTitle
          onEditClick={onEditClick}
          editable={editable}
          data={data}
        />
      }
      actions={actions}
      isOpen={isOpen || hasErrors}
      onToggle={() => setOpen(!isOpen)}
      className={classNames('qm-settings-question-group', className)}
      {...props}
    >
      <div className='questions-table'>
        {children.length ? (
          <div className={classNames('table-row table-header')}>
            {QUESTION_COLUMNS.map((col, idx) => (
              <div
                key={idx}
                className={classNames('table-cell', col.className, {
                  [`align-${col.textAlign}`]: col.textAlign,
                })}
              >
                {col.title}
              </div>
            ))}
          </div>
        ) : (
          <div className='empty'>Question group is empty</div>
        )}
        {children}
      </div>
    </Accordion>
  );
};

export default SettingsQuestionGroup;
