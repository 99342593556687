import React from 'react';
import PropTypes from 'prop-types';
import './ProgressBar.scss';

function ProgressBar({ percent }) {
  return (
    <div className='progressBar'>
      <div
        className='selected'
        style={{
          width: `${percent}%`,
        }}
      />
    </div>
  );
}

ProgressBar.propTypes = {
  percent: PropTypes.number,
};

ProgressBar.defaultProps = {
  percent: 0,
};

export default ProgressBar;
