import { formatDate, getMinFutureCalendarDate } from 'lib/dateHelpers';

import ActivityTimeline from 'lib/components/activity-timeline/ActivityTimeline';
import AddButton from 'lib/components/add-button/AddButton';
import Button from 'lib/components/button/Button';
import Card from 'lib/components/card/Card';
import DateRangePicker from 'lib/components/date-range-picker/DateRangePicker';
import DeleteButton from 'lib/components/delete-button/DeleteButton';
import EditButton from 'lib/components/edit-button/EditButton';
import ErrorBag from 'lib/components/error-bag/ErrorBag';
import InspectionReadyModal from '../inspection-ready-modal/InspectionReadyModal';
import Modal from 'lib/components/modal/Modal';
import ProtectedComponent from 'lib/components/protected-component/ProtectedComponent';
import Tabs from 'lib/components/tabs/Tabs';
import Textarea from 'lib/components/textarea/Textarea';
import Tooltip from 'lib/components/tooltip/Tooltip';

import WorkObjectWorkFlow from '../work-object-workflow/WorkObjectWorkflow';
import {
  ADD_WORK_OBJECT_ROLES,
  ACCEPT_PARTIAL_ROLES,
  READY_FOR_INSPECTION_ROLES,
  CREATE_PARTIAL_ROLES,
} from 'modules/work-objects/workObjectsPermissions';
import { getStatus } from 'modules/work-objects/dataTransform';
import useWorkObjectsDetail from 'modules/work-objects/useWorkObjectsDetail';

import { ReactComponent as SearchCheck } from 'assets/images/search-check-outline.svg';
import { ReactComponent as CheckIcon } from 'assets/images/check.svg';
import { ReactComponent as Pencil } from 'assets/images/pencil-solid.svg';

import './WorkObjectsDetailPage.scss';
import { useMemo } from 'react';
import WORK_OBJECT_ACTIVITIES from 'config/activities/work-object.activities';

const WorkObjectsDetailPage = (props) => {
  const { state, ...vm } = useWorkObjectsDetail(props);
  const showTabContent =
    !state.workObject?.partialWorkObjects?.length ||
    state.currentTab !== state.workObject?.partialWorkObjects?.length;

  const vendorDisplayText = useMemo(() => {
    let output = null;

    const v = state?.workObject?.vendor;

    if (v) {
      const vStrArray = [
        v.name?.length ? v.name : null,
        `${v.externalId?.length ? 'External ' : ''}ID: ${
          v.externalId?.length ? v.externalId : v.id
        }`,
        v?.location?.city?.length ? v.location.city : null,
        v?.location?.country?.length ? v.location.country : null,
      ];

      output = vStrArray.filter((i) => !!i).join(', ');
    }

    return output;
  }, [JSON.stringify(state?.workObject?.vendor)]);

  const resourceProps = state.workObject?.asset
    ? {
        title: state.workObject?.asset?.name || '',
        image: state.workObject?.asset.images?.length
          ? state.workObject?.asset.images[0].url
          : null,
      }
    : {
        title: state.workObject?.source?.name || '',
        image: state.workObject?.source?.images?.[0]?.url,
      };

  const woCreationActivityType =
    !!state?.workObject?.activities?.length &&
    (state.workObject.activities[state.workObject.activities.length - 1]
      ?.actions?.type ??
      null);

  const getWorkObjectOriginLabel = useMemo(() => {
    switch (woCreationActivityType) {
      case WORK_OBJECT_ACTIVITIES.WORK_OBJECT_CREATED:
        return 'Created';
      case WORK_OBJECT_ACTIVITIES.WORK_OBJECT_IMPORTED:
        return 'Imported';
      default:
        return 'Unknown';
    }
  }, [woCreationActivityType]);

  if (state.loading) {
    // TODO: handle loading state
    return null;
  }

  if (!state.workObject) {
    //TODO: handle api error
    return null;
  }

  return (
    <div
      className='work-objects-detail-page'
      data-testid='WorkObjectsDetailPage.container'
    >
      <div className='work-object-header'>
        <h1>Work object {state.workObject?.id}</h1>
        <div className='buttons-container'>
          {vm.showCreatePartialButton() &&
            !state.workObject?.partialWorkObjects?.length && (
              <ProtectedComponent allowed={CREATE_PARTIAL_ROLES}>
                <AddButton
                  onClick={vm.userOpensInspectionModal}
                  className='ready-button'
                  data-testid='WorkObjectsDetailPage.createPartial'
                  disabled={vm.isWorkObjectActionsDisabled}
                >
                  Create partial
                </AddButton>
              </ProtectedComponent>
            )}
          {state.workObject?.userCanEdit && (
            <ProtectedComponent allowed={ADD_WORK_OBJECT_ROLES}>
              <EditButton
                onClick={() =>
                  props.history.push(
                    `/work-objects/${state.workObject.id}/edit`,
                  )
                }
                data-testid='WorkObjectsDetailPage.editButton'
                disabled={vm.isWorkObjectDone}
              >
                Edit
              </EditButton>
            </ProtectedComponent>
          )}
        </div>
      </div>
      <div className='header-info'>
        <p>ID: {state.workObject?.id}</p>
        {!!state.workObject?.externalId && (
          <p>External ID: {state.workObject?.externalId}</p>
        )}
        <p>Status: {getStatus(state.workObject?.status)}</p>
        <p>Deadline: {formatDate(state.workObject?.deadline) || ''}</p>
        <p>Origin: {getWorkObjectOriginLabel}</p>
        {!!vendorDisplayText && <p>Vendor: {vendorDisplayText}</p>}
      </div>
      {!!state?.workObject?.customFields.length && (
        <div className='custom-fields'>
          {state?.workObject?.customFields.map((customField, idx) => (
            <p key={idx}>
              {customField?.name}: <strong>{customField?.data}</strong>
            </p>
          ))}
        </div>
      )}
      <Card
        {...resourceProps}
        subtitle={
          <>
            <div>
              <span>ID: {state.workObject?.asset?.id}</span>
              {state.workObject?.asset?.externalId && (
                <span> External ID: {state.workObject?.asset?.externalId}</span>
              )}
            </div>
          </>
        }
        description={
          <>
            <div>
              <span>
                Quantity: <strong>{state.workObject?.quantity}</strong>
              </span>
            </div>
            <span>
              Remaining Quantity:{' '}
              <strong>{state.workObject?.remainingQuantity}</strong>
            </span>
          </>
        }
        className='work-object-target'
        data-testid='WorkObjectDetailPage.target'
      />
      {!!state.workObject?.partialWorkObjects?.length && (
        <Tabs showSeparator>
          {state.workObject.partialWorkObjects.map((partial, index) => (
            <Tabs.Item
              active={index === state.currentTab}
              key={partial?.id || index}
              onClick={() => vm.userClicksOnTab(index)}
            >
              <div className='tab-header'>
                <strong>{`${state.workObject?.id}-${index + 1}`}</strong>
                {!!partial?.isReadyForInspection && (
                  <span className='tab-icon success'>
                    <Tooltip placement='top' overlay={'Ready for inspection'}>
                      <CheckIcon />
                    </Tooltip>
                  </span>
                )}
              </div>
              <span className='tab-info'>
                Quantity: <strong>{partial?.quantity}</strong>
                <br />
                Deadline: <strong>{formatDate(partial?.deadline)}</strong>
              </span>
            </Tabs.Item>
          ))}
          {vm.showCreatePartialButton() && (
            <ProtectedComponent allowed={CREATE_PARTIAL_ROLES}>
              <Button
                onClick={vm.userOpensInspectionModal}
                className='medium-button outline'
                data-testid='WorkObjectsDetailPage.createPartial'
                disabled={vm.isWorkObjectActionsDisabled}
              >
                Create partial
              </Button>
            </ProtectedComponent>
          )}
        </Tabs>
      )}
      <div className='tabs-content'>
        <section className='workflow-content'>
          <div className='tabs-content-header'>
            {!!state.workObject?.partialWorkObjects?.length && (
              <h2>
                {state.workObject?.id}-{state.currentTab + 1}
              </h2>
            )}
            {vm.isPartialSubmitted(state.currentTab) &&
              state.workObject?.userCanEdit && (
                <ProtectedComponent allowed={ACCEPT_PARTIAL_ROLES}>
                  <Button
                    className='medium-button'
                    onClick={() => vm.userAcceptsPartial(state.currentTab)}
                    data-testid='WorkObjectsDetailPage.accept'
                    disabled={vm.isWorkObjectActionsDisabled}
                  >
                    {vm.isFullQuantity(state.currentTab)
                      ? 'Accept'
                      : 'Accept partial'}
                  </Button>
                </ProtectedComponent>
              )}
            {vm.isPartialSubmitted(state.currentTab) &&
              state.workObject?.userCanEdit && (
                <ProtectedComponent allowed={ACCEPT_PARTIAL_ROLES}>
                  <Button
                    className='medium-button'
                    onClick={() => vm.userRejectsPartial(state.currentTab)}
                    data-testid='WorkObjectsDetailPage.reject'
                    disabled={vm.isWorkObjectActionsDisabled}
                  >
                    {vm.isFullQuantity(state.currentTab)
                      ? 'Reject'
                      : 'Reject partial'}
                  </Button>
                </ProtectedComponent>
              )}
            {vm.showPartialReadyForInspectionButton(state.currentTab) && (
              <ProtectedComponent allowed={READY_FOR_INSPECTION_ROLES}>
                <Button
                  prefix={<SearchCheck />}
                  onClick={() =>
                    vm.makePartialReadyForInspection(state.currentTab)
                  }
                  className='medium-button'
                  data-testid='WorkObjectsDetailPage.readyInspectionPartial'
                  disabled={vm.isWorkObjectActionsDisabled}
                >
                  Set "Ready for inspection"
                </Button>
              </ProtectedComponent>
            )}
            {vm.showPartialUnreadyForInspectionButton(state.currentTab) && (
              <ProtectedComponent allowed={READY_FOR_INSPECTION_ROLES}>
                <Button
                  prefix={<SearchCheck />}
                  onClick={() =>
                    vm.makePartialReadyForInspection(state.currentTab, false)
                  }
                  className='medium-button'
                  data-testid='WorkObjectsDetailPage.unreadyInspectionPartial'
                  disabled={vm.isWorkObjectActionsDisabled}
                >
                  Undo "Ready for inspection"
                </Button>
              </ProtectedComponent>
            )}
            {vm.showPartialButton(state.currentTab) && (
              <ProtectedComponent allowed={ACCEPT_PARTIAL_ROLES}>
                <button
                  className='edit-partial-button'
                  onClick={() => vm.userOpensEditPartialModal()}
                  data-testid='WorkObjectsDetailPage.editPartial'
                  disabled={vm.isWorkObjectActionsDisabled}
                >
                  <Pencil />
                  Edit
                </button>
              </ProtectedComponent>
            )}
            {vm.showPartialButton(state.currentTab) && (
              <ProtectedComponent allowed={ACCEPT_PARTIAL_ROLES}>
                <DeleteButton
                  onClick={() => vm.userRejectsPartial(state.currentTab)}
                  data-testid='WorkObjectsDetailPage.deletePartial'
                  disabled={vm.isWorkObjectActionsDisabled}
                >
                  Delete
                </DeleteButton>
              </ProtectedComponent>
            )}
          </div>
          {showTabContent && (
            <WorkObjectWorkFlow data={state} actions={vm} {...props} />
          )}
        </section>
        {showTabContent && (
          <ActivityTimeline
            data={
              state.workObject?.partialWorkObjects[state.currentTab]
                ?.activities || state.workObject?.activities
            }
          />
        )}
      </div>
      <InspectionReadyModal
        data={{
          quantity: state.workObject?.remainingQuantity,
          partialDefaultQuantity:
            state?.workObject?.partialWorkObjects[state.currentTab]?.quantity ||
            null,
          partialDefaultDeadline:
            state?.workObject?.partialWorkObjects[state.currentTab]?.deadline ||
            null,
          partialQuantity: state.partialQuantity,
          defaultDeadline: state.workObject?.deadline,
          deliveryDate: state.deliveryDate,
          isEditing: state.isEditingPartial,
        }}
        isOpen={state.isInspectionModalOpen}
        onRequestClose={vm.userCancelsInspectionModal}
        onQuantityChange={vm.userTypesPartialQuantity}
        onDateChange={vm.userSetsPartialDeliveryDate}
        onSubmit={vm.userSubmitsPartial}
        confirmClose={state.isPartialDirty}
        errors={state.errors}
      />
      <Modal
        title='Add notes'
        actions={
          <>
            <Button onClick={vm.userConfirmsInspectionAction}>
              {state.takeDecisionModal.action?.name}
            </Button>
            <Button
              onClick={vm.userCancelsInspectionAction}
              className='link-button'
            >
              Cancel
            </Button>
          </>
        }
        isOpen={state.takeDecisionModal.isModalOpen}
        onRequestClose={vm.userCancelsInspectionAction}
        confirmClose={state.takeDecisionModal.isDirty}
        className='work-objects-detail-page--decision-modal'
      >
        <p>Please provide some information about this decision</p>
        <Textarea
          value={state.takeDecisionModal.notes.value}
          charsLeft={state.takeDecisionModal.notes.charsLeft}
          errors={state.takeDecisionModal.notes.errors}
          onChange={vm.userTypesActionNote}
          rows={8}
        />
      </Modal>
      <Modal
        title='Schedule Inspection'
        actions={
          <>
            <Button
              disabled={vm.isPairWindowModalSubmitDisabled}
              onClick={vm.userConfirmsPairWindowModal}
            >
              Submit
            </Button>
          </>
        }
        isOpen={state.pairWindowModal.isModalOpen}
        onRequestClose={vm.userCancelsPairWindowModal}
        confirmClose={state.pairWindowModal.isDirty}
        className='work-objects-detail-page--schedule-inspection-modal'
      >
        <ErrorBag errors={state.pairWindowModal.errors} />
        <p>
          Select a range of dates in which you want the inspection to be
          performed. The actual date of the inspection can be outside of this
          window
        </p>
        <DateRangePicker
          min={getMinFutureCalendarDate()}
          value={state.pairWindowModal.value}
          onChange={vm.userChangesPairWindowRange}
          label='Select date range'
          inline
          isClearable
        />
      </Modal>
    </div>
  );
};

export default WorkObjectsDetailPage;
