/* eslint-disable no-unused-vars */
import { useContext, useEffect, useReducer } from 'react';

import { PERFORMABLE_STATUS_LIST } from 'config/inspectionStatus';
import {
  ANSWER_TYPE,
  DEPENDENCY,
  PHOTO_REQUIRED,
} from 'config/questionOptions';
import { AppContext } from 'App';
import useNavigationPrompt from 'lib/useNavigationPrompt';

import {
  getDerivedAnswerProperties,
  isQuestionHidden,
  inspectionToPerformInspectionState,
} from './dataTransform';
import inspectionsService from './inspectionsService';
import performInspectionValidator from './performInspectionValidator';
import {
  reducer,
  initialState,
  PERFORM_INSPECTION_ACTIONS,
  MAX_PHOTO_NUMBER,
  ALLOWED_MIMETYPES,
  MAX_PHOTO_MB,
  MAX_DOC_MB,
} from './performInspectionReducer';

function fetchInspection(id, dispatch) {
  inspectionsService
    .getInspection(id, {
      relations: [
        'asset.customMeasurements',
        'questionGroups',
        'inspectionType',
        'inspector',
      ],
    })
    .then((inspection) => {
      dispatch({
        type: PERFORM_INSPECTION_ACTIONS.APP_LOADS_INSPECTION,
        payload: {
          inspection: inspectionToPerformInspectionState(inspection),
          loading: false,
          errors: [],
        },
      });
    })
    .catch((e) => {
      dispatch({
        type: PERFORM_INSPECTION_ACTIONS.RESET_STATE,
        payload: {
          ...e.payload,
          loading: false,
        },
      });
    });
}

function prepareStateForValidation(state) {
  return {
    ...state,
    inspection: {
      ...state.inspection,
      questionGroups: state.inspection.questionGroups.map((group) => ({
        ...group,
        questions: group.questions.map((question) => {
          return {
            ...question,
            ...getDerivedAnswerProperties(
              group,
              question,
              state.inspection.asset,
            ),
          };
        }),
      })),
    },
  };
}

export default function usePerformInspection(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const id = props.match?.params?.id;
  const {
    appState: { currentUser },
  } = useContext(AppContext);

  useNavigationPrompt(!!state.isDirty);

  useEffect(() => {
    fetchInspection(id, dispatch);
  }, []);

  const userStartsInspection = () => {
    inspectionsService
      .startInspection(id)
      .then(() => {
        fetchInspection(id, dispatch);
      })
      .catch((e) => {
        dispatch({
          type: PERFORM_INSPECTION_ACTIONS.RESET_STATE,
          payload: e.payload,
        });
      });
  };

  const onQuestionInputChange = (groupId, questionId, field, value, index) => {
    dispatch({
      type: PERFORM_INSPECTION_ACTIONS.USER_CHANGES_QUESTION_INPUT,
      payload: {
        groupId,
        questionId,
        field,
        value,
        index,
      },
    });
  };

  const onPhotoUpload = (groupId, questionId, files) => {
    const errors = [];
    const question = state.inspection.questionGroups
      .find((q) => q.id === groupId)
      .questions.find((q) => q.id === questionId);
    const lenExisting = question.photos.value.length;

    if (lenExisting + files.length > MAX_PHOTO_NUMBER) {
      files = Array.from(files).slice(0, MAX_PHOTO_NUMBER - lenExisting);
      errors.push(`A maximum of ${MAX_PHOTO_NUMBER} images is allowed`);
    }

    const filteredMimetypes = Array.from(files).filter((file) =>
      ALLOWED_MIMETYPES.includes(file.type),
    );
    if (files.length > filteredMimetypes.length) {
      errors.push(`Allowed file types are: ${ALLOWED_MIMETYPES.join(', ')}`);
    }

    const filteredSizes = filteredMimetypes.filter(
      (file) => file.size <= MAX_PHOTO_MB * 1024 * 1024,
    );
    if (filteredMimetypes.length > filteredSizes.length) {
      errors.push(`Maximum photo size is ${MAX_PHOTO_MB} MB`);
    }

    dispatch({
      type: PERFORM_INSPECTION_ACTIONS.USER_UPLOADS_PHOTO,
      payload: {
        groupId,
        questionId,
        files: filteredSizes,
        errors,
      },
    });
  };

  const onPhotoRemove = (groupId, questionId, index) => {
    dispatch({
      type: PERFORM_INSPECTION_ACTIONS.USER_REMOVES_PHOTO,
      payload: {
        groupId,
        questionId,
        index,
      },
    });
  };

  const onDocumentUpload = (groupId, questionId, files) => {
    const errors = [];
    const filteredSizes = Array.from(files).filter(
      (file) => file.size <= MAX_DOC_MB * 1024 * 1024,
    );
    if (files.length > filteredSizes.length) {
      errors.push(`Maximum document size is ${MAX_DOC_MB} MB`);
    }

    dispatch({
      type: PERFORM_INSPECTION_ACTIONS.USER_UPLOADS_DOCUMENT,
      payload: {
        groupId,
        questionId,
        files: filteredSizes,
        errors,
      },
    });
  };

  const onDocumentInputChange = (groupId, questionId, docIdx, value) => {
    dispatch({
      type: PERFORM_INSPECTION_ACTIONS.USER_CHANGES_DOCUMENT_NAME,
      payload: {
        groupId,
        questionId,
        docIdx,
        value,
      },
    });
  };

  const onDocumentRemove = (groupId, questionId, index) => {
    dispatch({
      type: PERFORM_INSPECTION_ACTIONS.USER_REMOVES_DOCUMENT,
      payload: {
        groupId,
        questionId,
        index,
      },
    });
  };

  const onInputChange = (field, value) => {
    dispatch({
      type: PERFORM_INSPECTION_ACTIONS.USER_CHANGES_INPUT,
      payload: {
        field,
        value,
      },
    });
  };

  const uploadInspectionPhotos = () => {
    const promises = [];
    state.inspection.questionGroups
      .map((group) =>
        group.questions.filter((question) => {
          // Sometimes, a user might change their mind about a
          // previous question and make the current one hidden
          // after already having selected photos for it
          // We don't upload these as they won't be seen anyway
          const { isHidden, isAnswered } = getDerivedAnswerProperties(
            group,
            question,
          );
          return !isHidden && isAnswered;
        }),
      )
      .flat()
      .forEach((question) =>
        question.photos.value.forEach((photo) => {
          promises.push(
            inspectionsService.saveInspectionPhoto(
              state.inspection.id,
              question.id,
              photo,
            ),
          );
        }),
      );

    return Promise.all(promises);
  };

  const uploadInspectionDocuments = () => {
    const promises = [];
    state.inspection.questionGroups
      .map((group) =>
        group.questions.filter((question) => {
          // Sometimes, a user might change their mind about a
          // previous question and make the current one hidden
          // after already having selected documents for it
          // We don't upload these as they won't be seen anyway
          const { isHidden, isAnswered } = getDerivedAnswerProperties(
            group,
            question,
          );
          return !isHidden && isAnswered;
        }),
      )
      .flat()
      .forEach((question) =>
        question.actualDocuments.value.forEach((doc) => {
          promises.push(
            inspectionsService.saveInspectionDocument(
              state.inspection.id,
              question.id,
              doc.value,
              doc.file,
            ),
          );
        }),
      );

    return Promise.all(promises);
  };

  const userClicksFinishInspection = () => {
    performInspectionValidator(prepareStateForValidation(state))
      .then(() =>
        dispatch({
          type: PERFORM_INSPECTION_ACTIONS.APP_SUBMITS_INSPECTION,
        }),
      )
      .then(() =>
        Promise.all([uploadInspectionPhotos(), uploadInspectionDocuments()]),
      )
      .then(() =>
        inspectionsService.finishInspection(prepareStateForValidation(state)),
      )
      .then(() => props.history.push(`/inspections/${state.inspection.id}`))
      .catch((e) => {
        dispatch({
          type: PERFORM_INSPECTION_ACTIONS.RESET_STATE,
          payload: e.payload,
        });
        document.getElementsByClassName('is-invalid')[0]?.scrollIntoView();
      })
      .finally(() =>
        dispatch({
          type: PERFORM_INSPECTION_ACTIONS.APP_FINISHES_SUBMITTING_INSPECTION,
        }),
      );
  };

  const isCurrentUserAssigned =
    Number(state.inspection?.inspector?.id) === Number(currentUser.id);
  const isStatusPerformable = PERFORMABLE_STATUS_LIST.includes(
    state.inspection?.status,
  );
  const { canPerformWebAppInspections } = state.inspection?.inspector || {};
  const isPerformAllowed =
    isCurrentUserAssigned && isStatusPerformable && canPerformWebAppInspections;

  return {
    state,
    userStartsInspection,
    isQuestionHidden,
    isPerformAllowed,
    onInputChange,
    onPhotoUpload,
    onPhotoRemove,
    onDocumentUpload,
    onDocumentInputChange,
    onDocumentRemove,
    onQuestionInputChange,
    userClicksFinishInspection,
  };
}
