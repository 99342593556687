import _get from 'lodash.get';
import { cloneDeep } from 'lodash';

import {
  DEFAULT_RESULT_RECORDING_TYPE,
  DEPENDENCY,
  DEFAULT_QUESTION_GROUP_STATUS,
} from 'config/questionOptions';

import {
  entityToSelectOption,
  formatQuestionCriteria,
  getTranslation,
} from 'lib/dataTransform';
import { questionToDetailState } from 'lib/components/question-detail/dataTransform';

export const MAX_FIELD_CHARS = {
  name: 100,
};

export const setStringValue = (responseKey, data, formKey = responseKey) => {
  const value = _get(data, responseKey) ?? '';
  return {
    value,
    errors: [],
    charsLeft: MAX_FIELD_CHARS[formKey] - value.length,
  };
};

const optionByValue = (options, body) => (bodyKey, optionsKey = bodyKey) =>
  (options[optionsKey] || []).find((opt) => opt.value === body[bodyKey]) ??
  null;

/**
 * Transform question group options received from API to objects that
 * Select components can consume
 * @param {object} response Question templates api response
 * @returns {object} Question group form Select options
 */
export const questionOptionsToFormState = (response) => {
  const opts = Object.entries(response).reduce((acc, [key, values]) => {
    acc[key] = values.map(entityToSelectOption('label', 'id'));
    return acc;
  }, {});

  opts.dynamicAction = [
    {
      label: 'None',
      value: false,
    },
    ...opts.dynamicAction,
  ];

  opts.dependencyAction = [
    {
      label: 'None',
      value: false,
    },
    ...opts.dependencyAction,
  ];

  return opts;
};

export const formAnswerToPOSTParams = (state) => {
  const options = state.options.map((option) => ({
    label: option.label.value?.filter((v) => v?.text !== ''),
    order: option.order,
    id: option.id,
    meaning: option.meaning.value,
  }));
  return {
    name: state.name.value,
    options,
  };
};

export const defectToFormState = (defect, weightOptions) => {
  return {
    ...defect,
    weight: weightOptions.find((opt) => opt.value === defect.weight) || null,
  };
};

export const questionDependencyToFormState = (
  question,
  dependencyQuestion,
  questionOptions,
) => {
  const byValue = optionByValue(questionOptions, question);

  const isCustomAnswer =
    question.dependencyCriteria === DEPENDENCY.SPECIFIC_ANSWER;
  const customAnswer = isCustomAnswer
    ? dependencyQuestion?.body.answer?.options?.find(
        (opt) => opt.order === question.dependencyAnswerOptionOrder,
      )
    : null;

  const dependencyCriteriaValue = isCustomAnswer
    ? {
        label: formatQuestionCriteria(
          question.dependencyCriteria,
          customAnswer?.label,
        ),
        value: question.dependencyAnswerOptionOrder,
      }
    : {
        label: formatQuestionCriteria(question.dependencyCriteria),
        value: question.dependencyCriteria,
      };

  return {
    order: question.order,
    useDependency: question.useDependency,
    dependencyQuestionTemplateOrder: question.dependencyQuestionTemplateOrder,
    dependencyAction: {
      value: question.useDependency
        ? byValue('dependencyAction')
        : {
            value: false,
            label: 'None',
          },
      errors: [],
    },
    dependencyCriteria: {
      value: question.useDependency ? dependencyCriteriaValue : null,
      errors: [],
    },
    dependencyQuestion: {
      value: question.useDependency
        ? {
            label: `#${question.dependencyQuestionTemplateOrder + 1}`,
            value: question.dependencyQuestionTemplateOrder,
          }
        : null,
      errors: [],
    },
  };
};

export const questionToFormState = (question, questionOptions) => {
  const byValue = optionByValue(questionOptions, question);
  return {
    id: question.id,
    externalId: question.externalId,
    name: {
      value: question.name || [],
      errors: [],
    },
    keywords: setStringValue('keywords', question),
    type: {
      value: byValue('type'),
      errors: [],
    },

    useAqlLevel: {
      value: question.useAqlLevel ?? true,
      errors: [],
    },
    aqlLevel: {
      value: byValue('aqlLevel'),
      errors: [],
    },
    aqlMajor: {
      value: byValue('majorDefect'),
      errors: [],
    },
    aqlMinor: {
      value: byValue('minorDefect'),
      errors: [],
    },
    aqlFunctional: {
      value: byValue('functionalDefect'),
      errors: [],
    },
    criticalDefect: {
      value: question.criticalDefectValue,
      errors: [],
    },
    criticalDefectRule: {
      value: question.criticalDefectRule || 'Exact',
      errors: [],
    },
    sampleQty: setStringValue('sampleQty', question),
    sampleRule: {
      value: question.sampleRule || 'Exact',
      errors: [],
    },
    expectedBarcodeUOM: {
      value: byValue('expectedBarcodeUOM'),
      errors: [],
    },
    questionWeight: {
      value: byValue('questionWeight'),
      errors: [],
    },
    answerType: {
      value: byValue('answerType'),
      errors: [],
    },
    answer: {
      value: question.answer
        ? {
            label: question.answer?.name,
            value: question.answer?.id,
          }
        : null,
      errors: [],
    },
    answerOptions: (question.answer?.options || []).map((opt) => ({
      label: getTranslation(opt.label).display,
      value: opt.order,
    })),
    photoRequired: {
      value: question.photoRequired,
      errors: [],
    },
    printOnReport: {
      value: byValue('printOnReport'),
      errors: [],
    },
    tools: (question.tools || []).map((tool) => ({
      value: tool.name,
      errors: [],
    })),
    assetReferenceDocNames: (question.assetReferenceDocNames || []).map(
      (doc) => ({
        value: doc.name,
        errors: [],
      }),
    ),
    documents: (question.documents || []).map((doc) => ({
      file: doc,
      value: doc.documentName || doc.name || '',
      errors: [],
    })),
    initialDocuments: (question.documents || []).map((doc) => ({
      file: doc,
      value: doc.documentName || doc.name || '',
      errors: [],
    })),
    defects:
      (question.defects || []).map((d) =>
        defectToFormState(d, questionOptions.questionWeight),
      ) || [],
    dependentQuestionsCount: question.dependentQuestionsCount,

    dynamicAction: {
      value: question.useDynamic
        ? byValue('dynamicAction')
        : {
            value: false,
            label: 'None',
          },
      errors: [],
    },
    dynamicCriteria: {
      value: question.dynamicCriteria || '',
      errors: [],
    },
    dynamicRule: {
      value: byValue('dynamicRule'),
      errors: [],
    },

    expectedMeasureTableResult: {
      value: byValue('expectedMeasureTableResult'),
      errors: [],
    },

    individualMeasurementsRequired: {
      value:
        question.individualMeasurementsRequired ??
        DEFAULT_RESULT_RECORDING_TYPE,
      errors: [],
    },

    customExpectedMeasureValue: {
      value: question.customExpectedMeasureValue ?? '',
      errors: [],
    },

    customExpectedUom: {
      value: question.customExpectedUom
        ? {
            label: question.customExpectedUom.label,
            value: question.customExpectedUom.id,
          }
        : null,
      errors: [],
    },
    otherMeasure: {
      value: question.otherMeasure
        ? {
            label: question.otherMeasure,
            value: question.otherMeasure,
          }
        : null,
      errors: [],
    },
    lowerTolerance: {
      value: question.lowerToleranceValue ?? '',
      errors: [],
    },
    lowerToleranceRule: {
      value: question.lowerToleranceRule || 'Exact',
      errors: [],
    },
    lowerToleranceWeight: {
      value: byValue('lowerToleranceWeight', 'questionWeight'),
      errors: [],
    },

    upperTolerance: {
      value: question.upperToleranceValue ?? '',
      errors: [],
    },
    upperToleranceRule: {
      value: question.upperToleranceRule || 'Exact',
      errors: [],
    },
    upperToleranceWeight: {
      value: byValue('upperToleranceWeight', 'questionWeight'),
      errors: [],
    },
  };
};

export const formGroupedQuestionToPOSTParams = (question) => {
  const criteria = question.dependencyCriteria?.value?.value;
  const customAnswer = !Object.values(DEPENDENCY).includes(criteria);
  const useDependency =
    question.dependencyAction.value?.value !== false && question.order > 0;
  return {
    id: question.id ?? undefined,
    order: question.order,
    questionTemplateId: question.questionTemplateId,
    useDependency: useDependency,
    dependencyAction: useDependency
      ? question.dependencyAction?.value?.value || null
      : null,
    dependencyCriteria: useDependency
      ? (customAnswer ? DEPENDENCY.SPECIFIC_ANSWER : criteria) || null
      : null,
    dependencyQuestionTemplateOrder: useDependency
      ? question.dependencyQuestion?.value?.value
      : null,
    dependencyAnswerOptionOrder: customAnswer ? criteria : null,
  };
};

export const formQuestionToPOSTParams = (question) => {
  const useAqlLevel = question.useAqlLevel.value;
  const isHardcodedAQL =
    useAqlLevel && question.aqlLevel?.value?.value !== null;

  const mapped = {
    id: question.id || undefined,
    name: question.name?.value,
    keywords: question.keywords?.value,
    type: question.type.value?.value,

    useAqlLevel: useAqlLevel ?? true,
    aqlLevel: isHardcodedAQL ? question.aqlLevel?.value?.value || null : null,
    majorDefect: isHardcodedAQL ? question.aqlMajor?.value?.value : null,
    minorDefect: isHardcodedAQL ? question.aqlMinor?.value?.value : null,
    functionalDefect: isHardcodedAQL
      ? question.aqlFunctional?.value?.value
      : null,
    criticalDefectValue: isHardcodedAQL ? question.criticalDefect?.value : null,
    criticalDefectRule: isHardcodedAQL
      ? question.criticalDefectRule?.value
      : null,

    sampleQty: !useAqlLevel ? question.sampleQty?.value : null,
    sampleRule: !useAqlLevel ? question.sampleRule?.value || null : null,

    questionWeight: question.questionWeight?.value?.value,
    answerType: question.answerType?.value?.value || null,
    expectedBarcodeUOM: question.expectedBarcodeUOM.value?.value ?? null,
    answer: question.answer.value ? { id: question.answer.value.value } : null,
    photoRequired: question.photoRequired?.value,
    printOnReport: question.printOnReport?.value?.value,
    tools: question.tools?.map((tool) => ({ name: tool.value })),
    assetReferenceDocNames: question.assetReferenceDocNames?.map((doc) => ({
      name: doc.value,
    })),
    defects: question.defects.map((defect) => ({
      id: defect.id,
      weight: defect.weight?.value,
    })),
    documents: question.documents
      .filter((doc) => doc.file.url)
      .map((doc) => ({
        id: doc.file.id,
        documentName: doc.value,
      })),
    useDynamic: question.dynamicAction.value?.value !== false,
    dynamicAction: question.dynamicAction?.value?.value || null,
    dynamicCriteria: question.dynamicCriteria
      ? question.dynamicCriteria.value
      : null,
    dynamicRule: question.dynamicRule?.value?.value || null,

    expectedMeasureTableResult:
      question.answerType.value?.value === 'quantitative_input'
        ? question.expectedMeasureTableResult?.value?.value || null
        : null,
    individualMeasurementsRequired:
      question.answerType.value?.value === 'quantitative_input' &&
      (!question.expectedMeasureTableResult?.value?.value ||
        question.expectedMeasureTableResult?.value?.value === 'Custom' ||
        question.expectedMeasureTableResult?.value?.value === 'Other')
        ? question.individualMeasurementsRequired.value
        : undefined,
    customExpectedMeasureValue:
      question.answerType.value?.value === 'quantitative_input' &&
      question.expectedMeasureTableResult?.value?.value === 'Custom'
        ? question.customExpectedMeasureValue?.value
        : null,

    customExpectedUom:
      question.answerType.value?.value === 'quantitative_input' &&
      question.expectedMeasureTableResult?.value?.value === 'Custom'
        ? {
            id: question.customExpectedUom?.value?.value,
          }
        : null,
    otherMeasure:
      question.answerType.value?.value === 'quantitative_input' &&
      question.expectedMeasureTableResult?.value?.value === 'Other'
        ? question.otherMeasure?.value?.value
        : null,

    lowerToleranceValue:
      question.answerType.value?.value === 'quantitative_input' &&
      question.lowerTolerance.value !== undefined
        ? question.lowerTolerance.value
        : null,
    lowerToleranceRule:
      question.answerType.value?.value === 'quantitative_input' &&
      question.lowerTolerance.value !== undefined
        ? question.lowerToleranceRule.value
        : null,
    lowerToleranceWeight:
      question.answerType.value?.value === 'quantitative_input' &&
      question.lowerTolerance.value !== undefined
        ? question.lowerToleranceWeight.value?.value
        : null,

    upperToleranceValue:
      question.answerType.value?.value === 'quantitative_input' &&
      question.upperTolerance.value !== undefined
        ? question.upperTolerance.value
        : null,
    upperToleranceRule:
      question.answerType.value?.value === 'quantitative_input' &&
      question.upperTolerance.value !== undefined
        ? question.upperToleranceRule.value
        : null,
    upperToleranceWeight:
      question.answerType.value?.value === 'quantitative_input' &&
      question.upperTolerance.value !== undefined
        ? question.upperToleranceWeight.value?.value
        : null,
  };
  return mapped;
};

export const groupQuestionToGroupFormState = (
  groupQuestion,
  siblingQuestions,
  questionOptions,
) => {
  // For the question group form, we have questions in detail state,
  // except for dependency, which needs to be in form state.
  // To prevent the QuestionDetail component from crashing, we prefix
  // the dependency inputs with [group] and hide the detail dependency
  const detailState = questionToDetailState(
    groupQuestion.body,
    questionOptions,
  );
  const formState = questionDependencyToFormState(
    groupQuestion,
    siblingQuestions.find(
      (q) => q.order === groupQuestion.dependencyQuestionTemplateOrder,
    ),
    questionOptions,
  );

  return {
    ...detailState,
    ...formState,
    id: groupQuestion.id,
    questionTemplateId: groupQuestion.body.id,
  };
};

/** Transform a simple Question Template to a format usable inside a group */
export const questionToGroupFormState = (question, questionOptions) =>
  groupQuestionToGroupFormState({ body: question }, [], questionOptions);

export const questionGroupToDetailState = (group, questionOptions) => {
  return {
    ...group,
    name: {
      value: group.name ?? '',
      errors: [],
    },
    status: {
      value: group.status ?? DEFAULT_QUESTION_GROUP_STATUS,
      errors: [],
    },
    order: {
      value: group.order ?? '',
      errors: [],
    },
    tags: {
      value: (group.tags ?? []).map(entityToSelectOption('label', 'id')),
      errors: [],
    },
    inspectionTypes: {
      value: (group.inspectionTypes ?? []).map(
        entityToSelectOption('name', 'id'),
      ),
    },
    customAttributes: {
      value: (group?.customAttributes || []).map((attr) => ({
        groupLabel: attr.customAttribute?.name,
        groupValue: attr.customAttribute?.id,
        label: attr.option?.name || `Any (${attr.customAttribute?.name})`,
        value: attr.option?.id || null,
      })),
      errors: [],
    },
    companyAttributes: {
      value: (group?.companyAttributes || []).map((attr) => ({
        groupLabel: attr.companyAttribute?.name,
        groupValue: attr.companyAttribute?.id,
        label: attr.option?.name || `Any (${attr.companyAttribute?.name})`,
        value: attr.option?.id || null,
      })),
      errors: [],
    },
    assetTypes: {
      value: (group.assetTypes ?? []).map(entityToSelectOption('name', 'id')),
    },
    questions: (group.questions || []).map((q) =>
      groupQuestionToGroupFormState(q, group.questions, questionOptions),
    ),
  };
};

export const questionGroupFormToPOSTParams = (group) => {
  return {
    name: group.name.value,
    status: group.status.value,
    order: group.order.value,
    tags: group.tags.value.map((tag) => tag.label),
    assetTypes: group.assetTypes.value.map((type) => ({
      id: type.value,
    })),
    inspectionTypes: group.inspectionTypes.value.map((type) => ({
      id: type.value,
    })),
    customAttributes: group.customAttributes.value.map((attr) => ({
      customAttribute: { id: attr.groupValue },
      option: attr.value !== null ? { id: attr.value } : null,
    })),
    companyAttributes: group.companyAttributes.value.map((attr) => ({
      companyAttribute: { id: Number(attr.groupValue) },
      option: attr.value !== null ? { id: Number(attr.value) } : null,
    })),
    questions: group.questions.map(formGroupedQuestionToPOSTParams),
  };
};

export const companyAttributeOptionToPageState = (option) => ({
  ...option,
  name: option.name ?? '',
  errors: [],
});
export const companyAttributeOptionToPOSTParams = (option) => ({
  id: option.id || undefined,
  name: option.name,
});
const emptyAttribute = { isRemovable: true };
export const companyAttributeToPageState = (attrib) => ({
  ...attrib,
  label: {
    value: attrib.name ?? '',
    errors: [],
  },
  errors: [],
  options: (attrib.options.length ? attrib.options : [emptyAttribute]).map(
    companyAttributeOptionToPageState,
  ),
});
export const companyAttributeToPOSTParams = (attrib) => ({
  id: attrib.id,
  name: attrib.label.value,
  options: attrib.options
    .filter((opt) => opt.name !== '')
    .map(companyAttributeOptionToPOSTParams),
});
export const workObjectsToDetailState = (response) => {
  const partialsClone = cloneDeep(response?.partialWorkObjects || []);
  partialsClone.sort((a, b) => a?.id - b?.id);
  return {
    ...response,
    partialWorkObjects: partialsClone,
  };
};

/**
 * Question Groups List - Get proper form state after navigation to another
 * form for editing sub-items (e.g. replace in the group form a question that
 * we edited via shortcut to question page)
 * @param {object} locationState location state
 * @returns {object} new form state
 */
export const mapLocationStateToGroupFormState = (locationState) => {
  const { responseState = {}, question: editedQuestion = {} } = cloneDeep(
    locationState,
  );

  const qIdx = (responseState.form?.questions || []).findIndex(
    (q) => q.questionTemplateId === editedQuestion.id,
  );
  if (qIdx === -1) {
    return responseState;
  }

  // Update question details in form
  const oldFormQuestion = responseState.form.questions[qIdx];
  const editedQuestionDetail = questionToDetailState(
    editedQuestion,
    responseState.questionOptions,
  );
  responseState.form.questions[qIdx] = {
    /* Keep our UI's group attributes choices */
    ...oldFormQuestion,
    /* Apply any changes, but only to the detail part of the question */
    ...editedQuestionDetail,
    /* Re-apply dependency and id information */
    id: oldFormQuestion.id,
    order: oldFormQuestion.order,
    dependencyAction: oldFormQuestion.dependencyAction,
    dependencyQuestion: oldFormQuestion.dependencyQuestion,
    dependencyCriteria: oldFormQuestion.dependencyCriteria,
  };

  // Invalidate following questions dependency if edited question is parent
  // and its answer type has changed
  const isAnswerTypeChanged =
    oldFormQuestion.answerType?.value !== editedQuestion.answerType?.value ||
    oldFormQuestion.answer?.value !== editedQuestion.answer?.value;
  responseState.form.questions = responseState.form.questions.map((q) => {
    const shouldUnsetDependencyCriteria =
      isAnswerTypeChanged &&
      q.dependencyQuestion?.value?.value === oldFormQuestion.order &&
      ![DEPENDENCY.HAS_DEFECTS, DEPENDENCY.NO_DEFECTS].includes(
        q.dependencyCriteria?.value?.value,
      );
    return shouldUnsetDependencyCriteria
      ? {
          ...q,
          dependencyCriteria: {
            value: null,
            errors: ['Dependency answer type changed'],
          },
        }
      : q;
  });

  // Update question in all other background groups loaded on current page
  responseState.data = responseState.data.map((group) => ({
    ...group,
    questions: group.questions.map((q) => {
      if (q.questionTemplateId === editedQuestion.id) {
        return {
          ...editedQuestion,
          order: q.order,
          id: q.id,
          questionTemplateId: editedQuestion.id,
          useDependency: q.useDependency,
          dependencyAction: q.dependencyAction,
          dependencyCriteria: q.dependencyCriteria,
          dependencyQuestion: q.dependencyQuestion,
        };
      } else {
        return q;
      }
    }),
  }));

  return responseState;
};
