import ROLES from 'config/roles';

export const companyMenuItems = [
  {
    label: 'Dashboard',
    link: '/dashboard',
  },
  {
    label: 'Work Objects',
    link: '/work-objects',
    allowed: [
      ROLES.QM_ADMIN,
      ROLES.CLIENT_ADMIN,
      ROLES.ASSET_MANAGER,
      ROLES.SOURCE_MANAGER,
      ROLES.COORDINATOR,
      ROLES.EXTERNAL_SOURCE_OWNER,
      ROLES.INTERNAL_SOURCE_OWNER,
      ROLES.VENDOR,
      ROLES.ASSET_OWNER,
    ],
  },
  {
    label: 'Workflows',
    link: '/workflows',
    allowed: [
      ROLES.QM_ADMIN,
      ROLES.CLIENT_ADMIN,
      ROLES.ASSET_MANAGER,
      ROLES.SOURCE_MANAGER,
      ROLES.COORDINATOR,
      ROLES.ASSET_OWNER,
    ],
  },
  {
    label: 'Assets',
    link: '/assets',
  },
  {
    label: 'Sources',
    link: '/sources',
    allowed: [
      ROLES.QM_ADMIN,
      ROLES.CLIENT_ADMIN,
      ROLES.ASSET_MANAGER,
      ROLES.SOURCE_MANAGER,
      ROLES.COORDINATOR,
      ROLES.EXTERNAL_SOURCE_OWNER,
      ROLES.INTERNAL_SOURCE_OWNER,
      ROLES.VENDOR,
      ROLES.INSPECTOR,
      ROLES.ASSET_OWNER,
    ],
  },
  {
    label: 'Inspections',
    link: '/inspections',
    allowed: [
      ROLES.ASSET_OWNER,
      ROLES.CLIENT_ADMIN,
      ROLES.COORDINATOR,
      ROLES.INSPECTOR,
      ROLES.INTERNAL_SOURCE_OWNER,
      ROLES.EXTERNAL_SOURCE_OWNER,
      ROLES.VENDOR,
      ROLES.ASSET_MANAGER,
      ROLES.SOURCE_MANAGER,
      ROLES.QM_ADMIN,
    ],
  },
  {
    label: 'Inspection plans',
    link: '/inspection-plans',
    allowed: [
      ROLES.QM_ADMIN,
      ROLES.CLIENT_ADMIN,
      ROLES.ASSET_MANAGER,
      ROLES.SOURCE_MANAGER,
      ROLES.COORDINATOR,
      ROLES.INSPECTOR,
      ROLES.EXTERNAL_SOURCE_OWNER,
      ROLES.INTERNAL_SOURCE_OWNER,
      ROLES.VENDOR,
      ROLES.ASSET_OWNER,
    ],
  },
  {
    label: 'Inspection types',
    link: '/inspection-types',
    allowed: [
      ROLES.QM_ADMIN,
      ROLES.CLIENT_ADMIN,
      ROLES.ASSET_MANAGER,
      ROLES.SOURCE_MANAGER,
      ROLES.COORDINATOR,
    ],
  },
  {
    label: 'Users',
    link: '/users',
  },
  {
    label: 'Settings',
    link: '/settings',
    allowed: [
      ROLES.QM_ADMIN,
      ROLES.CLIENT_ADMIN,
      ROLES.ASSET_MANAGER,
      ROLES.SOURCE_MANAGER,
    ],
  },
];

export const qmAdminMenuItems = [
  {
    label: 'Companies',
    link: '/companies',
  },
  {
    label: 'Users',
    link: '/users',
  },
];
