import classNames from 'classnames';
import {
  INSPECTION_ORIGIN,
  INSPECTION_ORIGIN_OPTIONS,
} from 'config/inspectionConfig';
import AddButton from 'lib/components/add-button/AddButton';
import Button from 'lib/components/button/Button';
import Checkbox from 'lib/components/checkbox/Checkbox';
import DeleteButton from 'lib/components/delete-button/DeleteButton';
import EditButton from 'lib/components/edit-button/EditButton';
import ErrorBag from 'lib/components/error-bag/ErrorBag';
import Input from 'lib/components/input/Input';
import Radio from 'lib/components/radio/Radio';
import ResourceSelectButton from 'lib/components/resource-select-button/ResourceSelectButton';
import Select from 'lib/components/select/Select';
import SourcesModal from 'lib/components/sources-modal/SourcesModal';
import Textarea from 'lib/components/textarea/Textarea';
import Timeline from 'lib/components/timeline/Timeline';
import TimelineSubitem from 'lib/components/timeline/timeline-subitem/TimelineSubitem';
import Tooltip from 'lib/components/tooltip/Tooltip';

import useWorkflowsForm from 'modules/workflows/useWorkflowsForm';

import './WorkflowsForm.scss';

const renderLabelAndExtId = (v) => {
  return v?.label
    ? `${v.label}${v.externalId ? ` (External ID: ${v.externalId})` : ''}`
    : null;
};

const WorkflowStepInspection = ({
  pair,
  onDelete,
  typeOptions,
  onOriginChange,
  onTypeChange,
  onPlanChange,
  onRequiredChange,
  onScheduledChange,
}) => {
  const isPlanVisible =
    pair.inspectionOrigin.value === INSPECTION_ORIGIN.INTERNAL;
  return (
    <Timeline.Subitem>
      <div className='step-container'>
        <div className='step-item'>
          <Radio
            name={`inspectionOrigin`}
            options={INSPECTION_ORIGIN_OPTIONS}
            onChange={onOriginChange}
            label='Inspection origin'
            value={pair.inspectionOrigin.value}
            errors={pair.inspectionOrigin.errors}
            data-testid='WorkflowsForm.inspectionOrigin'
          />
        </div>
        <div className='step-item'>
          <Select
            name={`inspectionType`}
            options={typeOptions}
            onChange={onTypeChange}
            isSearchable
            placeholder='Select'
            label='Inspection type'
            value={pair.inspectionType.value}
            errors={pair.inspectionType.errors}
            data-testid='WorkflowsForm.inspectionType'
          />
        </div>
        {isPlanVisible && (
          <div className='step-item'>
            <Select
              name={`inspectionPlan`}
              options={pair.inspectionPlan.options}
              isDisabled={!pair.inspectionType?.value?.value}
              isLoading={pair.inspectionPlan.loading}
              onChange={onPlanChange}
              isSearchable
              placeholder='Select'
              label='Inspection plan'
              value={pair.inspectionPlan.value}
              errors={pair.inspectionPlan.errors}
              data-testid='WorkflowsForm.inspectionPlan'
            />
          </div>
        )}
        <Tooltip overlay='Remove inspection' className='delete-inspection-pair'>
          <DeleteButton
            data-testid='WorkflowsForm.deleteInspection'
            onClick={onDelete}
          />
        </Tooltip>
      </div>
      <div className='checkbox-container'>
        <div className='inspection-checkbox'>
          <Checkbox
            label='Required inspection'
            checked={pair.isRequired.value}
            onChange={onRequiredChange}
            data-testid='WorkflowsForm.requiredInspection'
          />
        </div>
        <div className='scheduled-checkbox'>
          <Checkbox
            label='Scheduled inspection'
            checked={pair.isScheduled.value}
            onChange={onScheduledChange}
            data-testid='WorkflowsForm.scheduledInspection'
          />
        </div>
      </div>
    </Timeline.Subitem>
  );
};

const WorkflowStep = ({
  index,
  source,
  isSource,
  isDestination,
  isEmpty,
  onSourceClick,
  onSourceClear,
  onDelete,
  onAddInspection,
  children,
  stepLabel,
  ...props
}) => {
  const hasValue = source.value?.value;

  const sourceDescription =
    isSource &&
    (hasValue
      ? 'Default source can be overridden on the work object level.'
      : 'Without a default, the source will need to be set on each work object using this workflow.');

  const destinationDescription =
    isDestination &&
    (hasValue
      ? 'Default destination can be overridden on the work object level.'
      : 'Without a default, the destination will need to be set on each work object using this workflow.');

  return (
    <Timeline.Item icon={index + 1} {...props}>
      <div className='form-row source-container'>
        <ResourceSelectButton
          placeholder='Select'
          label={stepLabel}
          title={renderLabelAndExtId(source.value) || ''}
          subtitle={source.value?.location || ''}
          errors={source.errors}
          onClick={onSourceClick}
          data-testid='WorkflowsForm.source'
          isClearable={typeof onSourceClear === 'function'}
          onClear={onSourceClear}
        />
        {!!onDelete && (
          <Tooltip overlay='Remove step' className='delete-source'>
            <DeleteButton onClick={onDelete} />
          </Tooltip>
        )}
      </div>
      {(sourceDescription || destinationDescription) && (
        <p className='source-description'>
          {sourceDescription || destinationDescription}
        </p>
      )}
      {children}
      <Button
        type='button'
        className='medium-button add-inspection-pair'
        onClick={onAddInspection}
        data-testid='WorkflowsForm.addInspection'
      >
        Add inspection
      </Button>
    </Timeline.Item>
  );
};

const WorkflowsForm = (props) => {
  const { state, ...vm } = useWorkflowsForm(props);
  const SubmitButton = state.id ? EditButton : AddButton;
  const submitText = state.id ? 'Save changes' : 'Create workflow';

  return (
    <div className='' data-testid='WorkflowsForm.container'>
      <form
        noValidate
        className='workflows-form'
        data-testid='WorkflowsForm.form'
      >
        <div className='form-row'>
          <Input
            name='name'
            type='text'
            value={state.name.value}
            errors={state.name.errors}
            onChange={vm.userTypesName}
            charsLeft={state.name.charsLeft}
            label='Name'
            data-testid='WorkflowsForm.name'
          />
        </div>
        <div className='form-row'>
          <Input
            name='externalId'
            type='text'
            value={state.externalId.value}
            errors={state.externalId.errors}
            onChange={vm.userTypesExternalId}
            charsLeft={state.externalId.charsLeft}
            label='External ID'
            data-testid='WorkflowsForm.externalId'
          />
        </div>
        <div className='form-row'>
          <Textarea
            name='description'
            type='text'
            value={state.description.value}
            errors={state.description.errors}
            onChange={vm.userTypesDescription}
            charsLeft={state.description.charsLeft}
            label='Description'
            data-testid='WorkflowsForm.description'
          />
        </div>
        <Timeline title='Workflow steps' className='primary'>
          {(state.workflowSteps || []).map((step, stepIndex) => {
            const isSource = stepIndex === 0;
            const isDestination = stepIndex === state.workflowSteps.length - 1;
            const isClearable = isSource || isDestination;

            const sourceSelectLabel = isSource
              ? 'Default source'
              : isDestination
              ? 'Default destination'
              : '';

            return (
              <WorkflowStep
                className={classNames({
                  'has-label': isSource || isDestination,
                })}
                index={stepIndex}
                key={stepIndex}
                source={step.source}
                isSource={isSource}
                isDestination={isDestination}
                stepLabel={sourceSelectLabel}
                onAddInspection={() => vm.userAddsInspectionPair(stepIndex)}
                onSourceClick={(e) => vm.userOpensSourcesModal(e, stepIndex)}
                onSourceClear={
                  isClearable ? () => vm.userClearsSource(stepIndex) : undefined
                }
                onDelete={
                  stepIndex > 0
                    ? () => vm.userRemovesSource(stepIndex)
                    : undefined
                }
              >
                {!step.inspectionPairs.length && (
                  <TimelineSubitem className='empty-subitem'>
                    <p>No inspection will take place at this location.</p>
                  </TimelineSubitem>
                )}
                {(step.inspectionPairs || []).map((pair, inspectionIndex) => (
                  <WorkflowStepInspection
                    key={inspectionIndex}
                    pair={pair}
                    index={inspectionIndex}
                    typeOptions={vm.inspectionTypesOptions}
                    onOriginChange={(e) =>
                      vm.userSetsSourceInspectionOrigin(e, {
                        stepIndex,
                        inspectionIndex,
                      })
                    }
                    onTypeChange={(e) =>
                      vm.userSetsSourceInspectionType(e, {
                        stepIndex,
                        inspectionIndex,
                      })
                    }
                    onPlanChange={(e) =>
                      vm.userSetsSourceInspectionPlan(e, {
                        stepIndex,
                        inspectionIndex,
                      })
                    }
                    onRequiredChange={(e) =>
                      vm.userSetsRequiredInspection(e.target.checked, {
                        stepIndex,
                        inspectionIndex,
                      })
                    }
                    onScheduledChange={(e) =>
                      vm.userSetsScheduledInspection(e.target.checked, {
                        stepIndex,
                        inspectionIndex,
                      })
                    }
                    onDelete={() =>
                      vm.userRemovesInspectionPair({
                        stepIndex,
                        inspectionIndex,
                      })
                    }
                  />
                ))}
              </WorkflowStep>
            );
          })}
          <Timeline.NewItem text='New step' onAddNew={vm.userAddsSource} />
        </Timeline>
        <SourcesModal
          isOpen={state.isSourcesModalOpen}
          onRequestClose={vm.userCancelsSourcesModal}
          onSubmit={vm.userSetsSource}
          data={vm.sourcesOptions.sources}
          sortBy={state.availableSources.sortBy}
          sortOrder={state.availableSources.sortOrder}
          setPage={vm.userSetsSourcesPage}
          page={state.availableSources.page}
          pageSize={state.availableSources.pageSize}
          onSearchChange={vm.userSearchesSources}
          setSortBy={vm.userSortsSources}
          count={vm.sourcesOptions.count}
          onRowClick={vm.userTogglesSource}
        />
        <div className='form-row submit-container'>
          <ErrorBag errors={state.errors} />
          <SubmitButton
            type='submit'
            disabled={state.loading}
            onClick={vm.userSubmitsForm}
            data-testid='WorkflowsForm.submit'
          >
            {submitText}
          </SubmitButton>
        </div>
      </form>
    </div>
  );
};

export default WorkflowsForm;
