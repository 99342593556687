import { SORT_ORDER } from 'lib/list-helper/makeListReducer';
import makeLogger from 'lib/makeLogger';
import request from 'lib/request';
import { formStateToPOSTParams } from './dataTransform';
import { processAPIError } from './sourcesFormValidator';
const log = makeLogger('sourcesService');

const defaultSourcesListParams = {
  page: 1,
  pageSize: 10,
  select: ['name', 'status', 'externalId'],
  relations: ['location', 'type'],
};

const defaultSourceParams = {
  select: ['name', 'status', 'externalId'],
  relations: ['location', 'type', 'images', 'owners.profilePhoto', 'tags'],
};

const defaultRelatedWorkObjectsParams = {
  page: 1,
  pageSize: 5,
  order: { lastActivity: SORT_ORDER.DESC },
  select: [
    'id',
    'asset',
    'source',
    'quantity',
    'defaultSource',
    'defaultDestination',
    'defaultSourceExternalId',
    'defaultDestinationExternalId',
    'sourceLocation',
    'destinationLocation',
    'progress',
    'hasMissingInspectionPlan',
    'lastActivity',
    'deadline',
    'workObject.externalId',
  ],
  relations: ['assetImg', 'sourceImg', 'asset', 'source', 'vendor'],
};

const defaultInspectionsListParams = {
  page: 1,
  pageSize: 5,
  select: [
    'assetId',
    'asset.name',
    'asset.externalId',
    'sourceId',
    'source.name',
    'source.externalId',
    'inspectionType.name',
    'inspector.name',
    'statusOrResult',
    'inspectionDate',
    'finishedAt',
    'isThirdParty',
    'inspectorType',
    'thirdPartyInspectorName',
    'thirdPartyInspectingCompany',
    'workObjectExternalId',
    'workObjectDeadline',
  ],
  relations: ['asset', 'source', 'inspectionType', 'inspector.profilePhoto'],
};

const queryParamsToGETParams = (params) => {
  if (params.sortBy && params.sortOrder) {
    params.order = { [params.sortBy]: params.sortOrder };
    delete params.sortBy;
    delete params.sortOrder;
  }

  return params;
};

const exportSources = () => {
  log('Attempting to export sources');

  return request
    .get('/sources/export')
    .then((res) => {
      log('Source export successfully fetched');
      return res;
    })
    .catch((err) => {
      log('Source export failed with error: ', err);
      return Promise.reject(err);
    });
};

const getTypes = () => {
  return request
    .get('/source-types', { params: { select: ['name'] } })
    .then((response) => {
      return response;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

const getOwners = (params) => {
  return request
    .get('/source-owners', {
      params: {
        select: ['name', 'companyName'],
        relations: ['profilePhoto', 'roles'],
        ...params,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

const getSource = (id, params = {}) => {
  log('Attempting to fetch sources with params', params);
  return request
    .get(`/sources/${id}`, {
      params: {
        ...defaultSourceParams,
        ...params,
      },
    })
    .then((response) => {
      log('Sources successfully fetched', response);
      return response;
    })
    .catch((err) => {
      log('Error fetching sources!', params, err);
      return Promise.reject(err);
    });
};

const getSources = (params) => {
  log('Attempting to fetch sources with params', params);
  return request
    .get('/sources', {
      params: queryParamsToGETParams({
        ...defaultSourcesListParams,
        ...params,
      }),
    })
    .then((response) => {
      log('Sources successfully fetched', response);
      return response;
    })
    .catch((err) => {
      log('Error fetching sources!', params, err);
      return Promise.reject(err);
    });
};

const getRelatedWorkObjects = ({ atSource, ...params }) => {
  log('Attempting to fetch related work objects with params', params);
  const filters = { atSource };
  return request
    .get('/work-objects/', {
      params: queryParamsToGETParams({
        filters,
        ...defaultRelatedWorkObjectsParams,
        ...params,
      }),
    })
    .then((response) => {
      log('Related work objects successfully fetched', response);
      return response;
    })
    .catch((err) => {
      log('Error fetching related work objects!', params, err);
      return Promise.reject(err);
    });
};

const getRelatedInspections = (
  sourceId,
  { filters = {}, extraSelect = [], extraRelations = [], ...params } = {},
) => {
  log('Attempting to fetch related inspections with params', params);

  const { select, relations, ...defaultParams } = defaultInspectionsListParams;

  return request
    .get('/inspections', {
      params: queryParamsToGETParams({
        filters: { atSource: sourceId, ...filters },
        ...defaultParams,
        select: [...select, ...extraSelect],
        relations: [...relations, ...extraRelations],
        ...params,
      }),
    })
    .then((response) => {
      log('Inspections successfully fetched.', response);
      return response;
    })
    .catch((e) => {
      log(
        'There was an issue in fetching the inspections for the required params',
        params,
        e,
      );
      return Promise.reject(e);
    });
};

const saveSource = (source) => {
  const query = source.id ? `/sources/${source.id}` : '/sources';
  const method = source.id ? 'put' : 'post';

  return request[method](query, { ...formStateToPOSTParams(source) })
    .then((response) => {
      log('Source saved successfully.', response);
      return response;
    })
    .catch((e) => {
      switch (e.response.data.errorCode) {
        case 'entity_body_001':
          return Promise.reject({
            payload: processAPIError(e.response.data.details, source),
          });
        default:
          return Promise.reject({
            payload: {
              errors: [
                'An error has occured while performing this operation. Please try again',
              ],
            },
          });
      }
    });
};

const saveSourceImages = (id, images) => {
  const formData = new FormData();
  images.map((img) => formData.append('files', img));

  return request
    .post(`/sources/${id}/upload-images`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((response) => {
      log('Images uploaded successfully');
      return response;
    })
    .catch((e) => {
      log('There was an error in uploading the images', e);
      return Promise.reject({
        payload: { errors: ['There was an error in uploading the images'] },
      });
    });
};

const getGeolocation = ({
  address,
  city,
  district,
  state,
  zipCode,
  country,
}) => {
  const parameters = new URLSearchParams();
  parameters.append('address', address);
  parameters.append('city', city);
  parameters.append('district', district);
  parameters.append('state', state);
  parameters.append('zipCode', zipCode);
  parameters.append('country', country);

  return request
    .get(`/sources/geocode?${parameters}`)
    .then((res) => {
      log('Received geocode response', res);
      switch (res.status) {
        case 'OK':
          return res.results;
        case 'ZERO_RESULTS':
          return [];
        default:
          throw new Error(res.status);
      }
    })
    .catch((e) => {
      log('There was an issue in getting geocode data', e);
      return Promise.reject({
        payload: {
          errors: [
            'An error has occured while fetching automatic location information.',
          ],
        },
      });
    });
};

const sourcesService = {
  exportSources,
  getSource,
  getGeolocation,
  getSources,
  getTypes,
  getOwners,
  getRelatedWorkObjects,
  getRelatedInspections,
  saveSource,
  saveSourceImages,
};

export default sourcesService;
