import classNames from 'classnames';

import { getMinFutureCalendarDate } from 'lib/dateHelpers';

import AddButton from 'lib/components/add-button/AddButton';
import CollapsibleRadio from 'lib/components/collapsible-radio/CollapsibleRadio';
import DatePicker from 'lib/components/date-picker/DatePicker';
import DateRangePicker from 'lib/components/date-range-picker/DateRangePicker';
import DeleteButton from 'lib/components/delete-button/DeleteButton';
import EditButton from 'lib/components/edit-button/EditButton';
import ErrorBag from 'lib/components/error-bag/ErrorBag';
import LinkableResourcesModal from 'lib/components/linkable-resources-modal/LinkableResourcesModal';
import ResourceSelectButton from 'lib/components/resource-select-button/ResourceSelectButton';
import Select from 'lib/components/select/Select';
import SourcesModal from 'lib/components/sources-modal/SourcesModal';

import InspectionPlansModal from '../inspection-plans-modal/InspectionPlansModal';
import InspectorsModal from '../inspectors-modal/InspectorsModal';

import useInspectionsForm from '../../useInspectionsForm';

import './InspectionsForm.scss';
import Button from 'lib/components/button/Button';

const InspectionsForm = (props) => {
  const vm = useInspectionsForm(props);
  const SubmitButton = vm.state.id ? EditButton : AddButton;
  const submitText = vm.state.id ? 'Save changes' : 'Create inspection';

  const isInspectionPlanSelectDisabled = vm.state.type.value === null;
  const isSourceSelectButtonVisible =
    vm.state.linkedResource.value?.resourceType === 'asset';
  const isInspectionTypeVisible =
    vm.state.linkedResource.value?.id &&
    vm.state.linkedResource.value?.resourceType;

  const [windowStart, windowEnd] = vm.state.inspectionWindow.value;
  const isClearWindowVisible = windowStart || windowEnd;

  const renderInspecorSelectDescr = () => {
    let output = 'Optional and can be assigned later';

    if (!vm.state.id) {
      if (vm.state.inspectorAutoset.isAutoset) {
        output = 'Preferred inspector selected';
      }
    } else if (
      !vm.state.inspector?.value?.id &&
      vm.state.availableInspectors?.list?.length === 1 &&
      !vm.isInspectorSelectDisabled
    ) {
      output = (
        <>
          Suggested inspector:{' '}
          <Button
            type='button'
            className='link-button'
            onClick={() =>
              vm.userSelectsInspector(vm.state.availableInspectors?.list[0])
            }
          >
            {vm.state.availableInspectors?.list[0]?.name}
          </Button>
        </>
      );
    }

    return output;
  };

  return (
    <div className='inspections-form'>
      {vm.state.errors && <ErrorBag errors={vm.state.errors} />}
      <form noValidate>
        <div className='form-row linked-resource-select'>
          <ResourceSelectButton
            label='Select asset / source'
            errors={vm.state.linkedResource.errors}
            onClick={vm.userClicksResourceSelect}
            {...vm.getResourceSelectButtonProps()}
            data-testid='InspectionsForm.linkedResource'
            disabled={vm.isResourceSelectDisabled}
          />
        </div>
        {isSourceSelectButtonVisible && (
          <div className='form-row source-select'>
            <ResourceSelectButton
              label='Select source'
              errors={vm.state.source.errors}
              onClick={vm.userClicksSourceSelect}
              {...vm.getSourceSelectButtonProps()}
              data-testid='InspectionsForm.source'
              disabled={vm.isResourceSelectDisabled}
            />
            <p className='field-description'>
              This is where the inspection should take place
            </p>
          </div>
        )}
        <div className='form-row status-select'>
          <CollapsibleRadio
            label='Status'
            options={vm.inspectionStatusOptions}
            value={vm.state.status.value}
            onChange={vm.userChangesStatus}
            errors={vm.state.status.errors}
            data-testid='InspectionsForm.status'
          />
        </div>
        {isInspectionTypeVisible && (
          <div className='form-row type-select'>
            <Select
              label='Inspection type'
              isSearchable={true}
              onChange={vm.userSelectsInspectionType}
              errors={vm.state.type.errors}
              value={vm.state.type.value}
              options={vm.state.typeOptions}
              data-testid='InspectionsForm.type'
            />
          </div>
        )}
        <div className='form-row plan-select'>
          <ResourceSelectButton
            disabled={isInspectionPlanSelectDisabled}
            onClick={vm.userClicksPlanSelect}
            label='Select inspection plan'
            {...vm.getPlanSelectButtonProps()}
            errors={vm.state.plan.errors}
            data-testid='InspectionsForm.plan'
          />
          {isInspectionPlanSelectDisabled && (
            <p className='field-description'>
              Please select an inspection type first
            </p>
          )}
        </div>
        <div className='form-row window-input'>
          <div className='form-label'>Inspection window:</div>
          <div className='date-group'>
            <DateRangePicker
              min={getMinFutureCalendarDate()}
              value={vm.state.inspectionWindow.value}
              onChange={vm.userChangesWindow}
              data-testid='InspectionsForm.window'
              disabled={vm.isWindowSelectDisabled}
              className={classNames({
                'is-invalid': vm.state.inspectionWindow.errors.length,
              })}
            />
            {isClearWindowVisible && (
              <DeleteButton
                onClick={vm.userClearsWindow}
                data-testid='InspectionsForm.clearWindow'
              />
            )}
          </div>
          <ErrorBag errors={vm.state.inspectionWindow.errors} />
        </div>
        <div className='form-row date-input'>
          <div className='form-label'>Inspection date:</div>
          <div className='date-group'>
            <DatePicker
              min={getMinFutureCalendarDate()}
              value={vm.state.date.value}
              onChange={vm.userChangesDate}
              errors={vm.state.date.errors}
              disabled={vm.isDateSelectDisabled}
              data-testid='InspectionsForm.date'
            />
            {vm.state.date.value && (
              <DeleteButton
                onClick={vm.userClearsDate}
                data-testid='InspectionsForm.clearDate'
              />
            )}
          </div>
          <p className='field-description'>
            Optional and can be assigned later
          </p>
        </div>
        <div className='form-row inspector-select'>
          <div className='form-label'>Select inspector:</div>
          <div className='inspector-group'>
            <ResourceSelectButton
              className='inspector-select'
              errors={vm.state.inspector.errors}
              onClick={vm.userClicksInspectorSelect}
              {...vm.getInspectorSelectButtonProps()}
              data-testid='InspectionsForm.inspector'
              disabled={vm.isInspectorSelectDisabled}
            />
            {vm.state.inspector.value && (
              <DeleteButton
                onClick={vm.userClearsInspector}
                data-testid='InspectionsForm.clearInspector'
              />
            )}
          </div>
          <p className='field-description'>{renderInspecorSelectDescr()}</p>
        </div>
        <SubmitButton
          className='submit-button'
          type='button'
          disabled={vm.state.loading}
          onClick={vm.userSubmitsForm}
          data-testid='InspectionsForm.submit'
        >
          {submitText}
        </SubmitButton>
      </form>
      <LinkableResourcesModal
        data-testid='InspectionsForm.linkableResourcesModal'
        data={vm.getLinkableResourcesData()}
        tab={vm.state.linkableResources.tab}
        isOpen={vm.state.linkableResources.isModalOpen}
        onRequestClose={vm.userCancelsResourcesModal}
        onRowClick={vm.userSelectsResource}
        onSearchChange={vm.userSearchesResources}
        search={vm.state.linkableResources.search}
        setSortBy={vm.userSortsResources}
        sortBy={vm.state.linkableResources.sortBy}
        sortOrder={vm.state.linkableResources.sortOrder}
        onTabChange={vm.userChangesResourcesModalTab}
        page={vm.state.linkableResources.page}
        pageSize={vm.state.linkableResources.pageSize}
        setPage={vm.userSetsResourcesPage}
      />
      <SourcesModal
        data-testid='InspectionsForm.sourcesModal'
        data={vm.state.availableSources.list}
        isOpen={vm.state.availableSources.isModalOpen}
        onRequestClose={vm.userCancelsSourcesModal}
        onRowClick={vm.userSelectsSource}
        onSearchChange={vm.userSearchesSources}
        search={vm.state.availableSources.search}
        setPage={vm.userSetsSourcesPage}
        page={vm.state.availableSources.page}
        pageSize={vm.state.availableSources.pageSize}
        count={vm.state.availableSources.count}
        setSortBy={vm.userSortsSources}
        sortBy={vm.state.availableSources.sortBy}
        sortOrder={vm.state.availableSources.sortOrder}
        title='Select source'
      />
      <InspectionPlansModal
        data-testid='InspectionsForm.plansModal'
        data={vm.state.availablePlans.list}
        isOpen={vm.state.availablePlans.isModalOpen}
        onRequestClose={vm.userCancelsPlansModal}
        onRowClick={vm.userSelectsPlan}
        onSearchChange={vm.userSearchesPlans}
        search={vm.state.availablePlans.search}
        setSortBy={vm.userSortsPlans}
        sortBy={vm.state.availablePlans.sortBy}
        sortOrder={vm.state.availablePlans.sortOrder}
        setPage={vm.userSetsPlansPage}
        page={vm.state.availablePlans.page}
        pageSize={vm.state.availablePlans.pageSize}
        count={vm.state.availablePlans.count}
        title='Select inspection plan'
      />
      <InspectorsModal
        data-testid='InspectionsForm.inspectorsModal'
        data={vm.state.availableInspectors.list}
        isOpen={vm.state.availableInspectors.isModalOpen}
        onRequestClose={vm.userCancelsInspectorsModal}
        onRowClick={vm.userSelectsInspector}
        onSearchChange={vm.userSearchesInspectors}
        search={vm.state.availableInspectors.search}
        setSortBy={vm.userSortsInspectors}
        sortBy={vm.state.availableInspectors.sortBy}
        sortOrder={vm.state.availableInspectors.sortOrder}
        setPage={vm.userSetsInspectorsPage}
        page={vm.state.availableInspectors.page}
        pageSize={vm.state.availableInspectors.pageSize}
        count={vm.state.availableInspectors.count}
        title='Select inspector'
      />
    </div>
  );
};

export default InspectionsForm;
