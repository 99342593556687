import * as yup from 'yup';
import _set from 'lodash.set';
import { startOfDay, subDays } from 'date-fns/fp';

import validator from 'lib/validator';
import { pipe } from 'lib/funcHelpers';

export const partialWorkObjectValidationSchema = yup.object().shape({
  partialQuantity: yup.object().shape({
    value: yup
      .number()
      .typeError('Quantity needs to be a number')
      .integer('Quantity needs to be an integer')
      .positive('Quantity needs to be greater than 0')
      .required('Quantity is a required field'),
  }),
  deliveryDate: yup.object().shape({
    value: yup
      .date()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .min(
        pipe(startOfDay, subDays(2))(new Date()), //Hack: Allow up to two calendar days before to account for multi-timezone users
        'Delivery date cannot be in the past',
      ),
  }),
});

export const workObjectReInspectValidationSchema = yup.object().shape({
  reInspectAql: yup.object().shape({
    value: yup.object().nullable().required('AQL is a required field'),
  }),
});

export const processWorkObjectsDetailError = (e, input) => {
  return e.inner.reduce((acc, curr) => {
    const path = curr.path.split('.').slice(0, -1).join('.');

    _set(acc, path, {
      value: curr.params.originalValue,
      errors: curr.errors,
    });
    return acc;
  }, input);
};

export const processAPIError = (errorDetails, workObject) => {
  return Object.keys(errorDetails).reduce((acc, curr) => {
    const processedPath = curr.split('.').map((path) => path);
    // get only error message, we already have the key
    const values = errorDetails[curr]
      .map((error) => error.split('.')[1])
      .map((error) => {
        switch (error) {
          case 'invalid':
            return 'This value is invalid for this field';
          case 'unique':
            return 'This value needs to be unique';
          default:
            return error;
        }
      });

    let path = processedPath;

    if (processedPath.length > 1) {
      path = [processedPath[0], processedPath[1]];
    }

    path.push('errors');

    return _set(acc, path, values);
  }, workObject);
};

export const workObjectReInspectValidator = (state) =>
  validator(
    state,
    workObjectReInspectValidationSchema,
    processWorkObjectsDetailError,
  );

const workObjectsDetailValidator = (state) =>
  validator(
    state,
    partialWorkObjectValidationSchema,
    processWorkObjectsDetailError,
  );

export default workObjectsDetailValidator;
