import ROLES from 'config/roles';

export const EXPORT_INSPECTIONS_ROLES = [ROLES.QM_ADMIN, ROLES.CLIENT_ADMIN];

export const ADD_INSPECTIONS_ROLES = [
  ROLES.QM_ADMIN,
  ROLES.CLIENT_ADMIN,
  ROLES.COORDINATOR,
  ROLES.ASSET_MANAGER,
  ROLES.SOURCE_MANAGER,
];

export const ASSIGN_INSPECTOR_ROLES = [
  ROLES.QM_ADMIN,
  ROLES.CLIENT_ADMIN,
  ROLES.COORDINATOR,
  ROLES.ASSET_MANAGER,
  ROLES.SOURCE_MANAGER,
];

export const PERFORM_INSPECTION_ROLES = [ROLES.INSPECTOR];

/* BE filters inspections returning inspections associated to
 inspector, source owner, internal source and asset owner */
export const VIEW_ALL_ROLES = [
  ROLES.ASSET_OWNER,
  ROLES.CLIENT_ADMIN,
  ROLES.COORDINATOR,
  ROLES.INSPECTOR,
  ROLES.INTERNAL_SOURCE_OWNER,
  ROLES.ASSET_MANAGER,
  ROLES.SOURCE_MANAGER,
  ROLES.QM_ADMIN,
  ROLES.EXTERNAL_SOURCE_OWNER,
  ROLES.VENDOR,
];

export const VIEW_INSPECTION_ROLES = [
  ROLES.QM_ADMIN,
  ROLES.CLIENT_ADMIN,
  ROLES.ASSET_MANAGER,
  ROLES.SOURCE_MANAGER,
  ROLES.COORDINATOR,
];

export const CANCEL_INSPECTION_ROLES = [
  ROLES.QM_ADMIN,
  ROLES.CLIENT_ADMIN,
  ROLES.COORDINATOR,
];

export const PUBLISH_INSPECTION_ROLES = [
  ROLES.QM_ADMIN,
  ROLES.CLIENT_ADMIN,
  ROLES.COORDINATOR,
];

export const SCHEDULE_INSPECTION_ROLES = [
  ROLES.QM_ADMIN,
  ROLES.CLIENT_ADMIN,
  ROLES.COORDINATOR,
  ROLES.INTERNAL_SOURCE_OWNER,
  ROLES.EXTERNAL_SOURCE_OWNER,
  ROLES.VENDOR,
];

export const REASSIGN_INSPECTION_ROLES = [
  ROLES.QM_ADMIN,
  ROLES.CLIENT_ADMIN,
  ROLES.COORDINATOR,
];

export const EDIT_THIRD_PARTY_ANYTIME_ROLES = [
  ROLES.QM_ADMIN,
  ROLES.CLIENT_ADMIN,
];

export const EDIT_THIRD_PARTY_PUBLISHED_ROLES = [
  ...EDIT_THIRD_PARTY_ANYTIME_ROLES,
  ROLES.ASSET_MANAGER,
  ROLES.SOURCE_MANAGER,
  ROLES.COORDINATOR,
];

export const ADD_THIRD_PARTY_ROLES = [
  ROLES.QM_ADMIN,
  ROLES.CLIENT_ADMIN,
  ROLES.ASSET_MANAGER,
  ROLES.SOURCE_MANAGER,
  ROLES.COORDINATOR,
];

export const VIEW_INSPECTION_RESULTS = [
  ROLES.ASSET_OWNER,
  ROLES.CLIENT_ADMIN,
  ROLES.COORDINATOR,
  ROLES.INTERNAL_SOURCE_OWNER,
  ROLES.ASSET_MANAGER,
  ROLES.SOURCE_MANAGER,
  ROLES.QM_ADMIN,
  ROLES.EXTERNAL_SOURCE_OWNER,
  ROLES.VENDOR,
];
