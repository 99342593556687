import { Link } from 'react-router-dom';

import { DEFECT_WEIGHT } from 'config/defects';
import { INSPECTOR_TYPE } from 'config/inspectionConfig';
import INSPECTION_STATUS, {
  getInspectionStatusLabel,
  INSPECTION_RESULT,
} from 'config/inspectionStatus';

import { reduceLocation } from 'lib/dataTransform';
import {
  formatDate,
  formatDateTime,
  getMinFutureCalendarDate,
} from 'lib/dateHelpers';

import Button from 'lib/components/button/Button';
import Card from 'lib/components/card/Card';
import DatePicker from 'lib/components/date-picker/DatePicker';
import DateRangePicker from 'lib/components/date-range-picker/DateRangePicker';
import Defect from 'lib/components/defect/Defect';
import DeleteButton from 'lib/components/delete-button/DeleteButton';
import EditButton from 'lib/components/edit-button/EditButton';
import File from 'lib/components/file/File';
import InspectionPlanStatusPill from 'lib/components/inspection-plans-status-pill/InspectionPlansStatusPill';
import InspectionsResultPill from 'lib/components/inspections-result-pill/InspectionsResultPill';
import NamedAvatar from 'lib/components/named-avatar/NamedAvatar';
import NotesModal from 'lib/components/notes-modal/NotesModal';
import ProtectedComponent from 'lib/components/protected-component/ProtectedComponent';
import Tooltip from 'lib/components/tooltip/Tooltip';

import useInspectionsDetail from '../../useInspectionsDetail';
import InspectorsModal from '../inspectors-modal/InspectorsModal';
import SELECTORS from './fixtures/selectors.json';

// TODO: Refactor permissions to avoid importing from other modules
import { REVIEWABLE_PLAN_STATUS_LIST } from 'config/inspectionPlanStatus';
import { REVIEW_PLANS_ROLES } from 'modules/inspection-plans/inspectionPlansPermissions';
import {
  CANCEL_INSPECTION_ROLES,
  VIEW_INSPECTION_ROLES,
} from 'modules/inspections/inspectionsPermissions';

import { ReactComponent as CornerUpRightOutline } from 'assets/images/corner-up-right-outline.svg';

import { AppContext } from 'App';
import React, { useContext } from 'react';
import './InspectionsDetailPage.scss';
import StatusPill, {
  STATUS_PILL_VARIANT,
} from 'lib/components/status-pill/StatusPill';
import { WorkObjectLink } from '../WorkObjectLink';

const renderNameAndExtId = (v) => {
  return v?.name
    ? `${v.name}${v.externalId ? ` (External ID: ${v.externalId})` : ''}`
    : null;
};

const InspectionsDetailsPage = (props) => {
  const { appState } = useContext(AppContext);

  const isEnableInspectionFunctionalPlusMajor =
    !!appState?.company?.customConfig?.enableInspectionFunctionalPlusMajor;

  const { state, ...vm } = useInspectionsDetail(props);

  const vendor = state?.stepPair?.partialStep?.partialWorkObject
    ?.parentWorkObject?.vendor?.id
    ? state?.stepPair?.partialStep?.partialWorkObject?.parentWorkObject?.vendor
    : null;

  if (state.loading) {
    return null;
  }

  const defectList = [
    state.criticalDefectsCountAll ? (
      <Defect
        weight={DEFECT_WEIGHT.CRITICAL}
        count={state.criticalDefectsCountAll}
      />
    ) : null,
    state.majorDefectsCountAll ? (
      <Defect weight={DEFECT_WEIGHT.MAJOR} count={state.majorDefectsCountAll} />
    ) : null,
    state.minorDefectsCountAll ? (
      <Defect weight={DEFECT_WEIGHT.MINOR} count={state.minorDefectsCountAll} />
    ) : null,
    state.functionalDefectsCountAll ? (
      <Defect
        weight={DEFECT_WEIGHT.FUNCTIONAL}
        count={state.functionalDefectsCountAll}
      />
    ) : null,
    isEnableInspectionFunctionalPlusMajor &&
    (!!state.functionalDefectsCountAll || !!state.majorDefectsCountAll) ? (
      <Defect
        data-testid='InspectionsResultPage.functionalPlusMajorErrors'
        count={
          (state.functionalDefectsCountAll ?? 0) +
          (state.majorDefectsCountAll ?? 0)
        }
        weight={'major'}
        label='Functional & Major'
      />
    ) : null,
  ];

  const isInspectionWindowSet =
    state.inspectionWindow.value[0] && state.inspectionWindow.value[1];

  const isPlanNeedsReviewVisible = REVIEWABLE_PLAN_STATUS_LIST.includes(
    state.inspectionPlan?.status,
  );

  const renderInspectionResults = () => {
    if (state.status === INSPECTION_STATUS.FINISHED)
      return (
        <>
          <div className='pill-container'>
            <InspectionsResultPill status={state.result || state.status} />
          </div>
          <div className='defects-container'>{defectList}</div>
          {!state.isThirdParty && (
            <Button
              data-testid={SELECTORS.viewResults}
              className='medium-button view-results-button'
              onClick={() => {
                props.history.push(`/inspections/${state.id}/results`);
              }}
            >
              View results
            </Button>
          )}
        </>
      );
    if (
      state.result === INSPECTION_RESULT.VALIDITY_PERIOD &&
      state.validityInspection
    ) {
      return (
        <Button
          data-testid={SELECTORS.viewPreviousResults}
          className='medium-button view-results-button'
          onClick={() => {
            props.history.push(
              `/inspections/${state.validityInspection?.id}/results`,
            );
          }}
        >
          View previous results
        </Button>
      );
    }

    return <div className='empty-state'>-</div>;
  };

  return (
    <div
      className='inspections-detail-page container-m'
      data-testid={SELECTORS.container}
    >
      <div className='row inspections-header'>
        <div className='details'>
          <h2>{state.inspectionType?.name ?? 'Inspection'}</h2>
          <div className='info'>
            <div className='field-description'>ID: {state.id}</div>
            {!!state.externalId && (
              <div className='field-description'>
                External ID: {state.externalId}
              </div>
            )}
            {!!state.parentInspectionId && (
              <StatusPill variant={STATUS_PILL_VARIANT.WARNING}>
                Reinspection
              </StatusPill>
            )}
            <div className='field-description'>
              Status: {getInspectionStatusLabel(state.status)}
            </div>
            {state.isThirdParty && (
              <div className='field-description'>Origin: Third party</div>
            )}
          </div>
        </div>
        <div className='actions'>
          {vm.isCancellable && (
            <ProtectedComponent allowed={CANCEL_INSPECTION_ROLES}>
              <Button
                data-testid={SELECTORS.cancelButton}
                onClick={vm.userClicksCancel}
              >
                Needs review
              </Button>
            </ProtectedComponent>
          )}
          {vm.isPerformAllowed && (
            <Button
              data-testid={SELECTORS.performButton}
              onClick={() =>
                props.history.push(`/inspections/${state.id}/perform`)
              }
            >
              Perform inspection
            </Button>
          )}
          {vm.isEditThirdPartyAllowed && (
            <ProtectedComponent allowed={vm.isEditThirdPartyAllowed}>
              <EditButton
                data-testid={SELECTORS.editThirdPartyButton}
                onClick={() =>
                  props.history.push(`/inspections/${state.id}/edit`)
                }
              >
                Edit
              </EditButton>
            </ProtectedComponent>
          )}
          {vm.isEditInternalAllowed && (
            <ProtectedComponent allowed={VIEW_INSPECTION_ROLES}>
              <EditButton
                data-testid={SELECTORS.editButton}
                onClick={() =>
                  props.history.push(`/inspections/${state.id}/edit`)
                }
              >
                Edit
              </EditButton>
            </ProtectedComponent>
          )}
        </div>
      </div>
      <div className='cards'>
        <div className='card-column'>
          <h5>Target of inspection</h5>
          {state.asset ? (
            <Link to={`/assets/${state.asset.id}`}>
              <Card
                title={state.asset.name}
                subtitle={
                  <>
                    ID: <strong>{state.asset.id}</strong>
                    {state.asset.externalId && (
                      <>
                        {' '}
                        External ID: <strong>{state.asset.externalId}</strong>
                      </>
                    )}
                  </>
                }
                description={'Asset'}
                image={state.asset.images?.[0]?.url}
              />
            </Link>
          ) : state.source ? (
            <Link to={`/sources/${state.source.id}`}>
              <Card
                title={state.source.name}
                subtitle={
                  <>
                    ID: {state.source.id}
                    {state.source.externalId && (
                      <> External ID: {state.source.externalId}</>
                    )}
                  </>
                }
                description={'Source'}
                image={state.source.images?.[0]?.url}
              />
            </Link>
          ) : null}
        </div>
        {!state.isThirdParty && (
          <div className='card-column'>
            <h5 className='header-with-actions'>
              <span>Inspection plan</span>
              {isPlanNeedsReviewVisible && (
                <ProtectedComponent allowed={REVIEW_PLANS_ROLES}>
                  <Tooltip overlay='Needs review'>
                    <Button
                      onClick={vm.userClicksPlanNeedsReview}
                      className='plan-needs-review-button'
                      data-testid={SELECTORS.planNeedsReviewButton}
                    >
                      <CornerUpRightOutline />
                    </Button>
                  </Tooltip>
                </ProtectedComponent>
              )}
            </h5>
            <Link to={`/inspection-plans/${state.inspectionPlan.id}`}>
              <Card
                title={state.inspectionPlan.name}
                subtitle={
                  <>
                    {`ID: ${state.inspectionPlan.id} `}
                    <InspectionPlanStatusPill
                      inline
                      status={state.inspectionPlan.status}
                    />
                  </>
                }
              />
            </Link>
          </div>
        )}
      </div>
      <div className='source-info'>
        <p>Source: </p>
        <div>
          {state.asset ? (
            <Link to={`/sources/${state.locationSource.id}`}>
              <p className='bold'>{renderNameAndExtId(state.locationSource)}</p>
              <p className='field-description'>
                {reduceLocation(
                  state.locationSource.location,
                  'city',
                  'country',
                )}
              </p>
            </Link>
          ) : state.source ? (
            <Link to={`/sources/${state.source.id}`}>
              <p className='bold'>{renderNameAndExtId(state.source)}</p>
              <p className='field-description'>
                {reduceLocation(state.source.location, 'city', 'country')}
              </p>
            </Link>
          ) : null}
        </div>
      </div>
      {!!vendor && (
        <div className='source-info'>
          <p>Vendor: </p>
          <div>
            <Link to={`/sources/${vendor.id}`}>
              <p className='bold'>{renderNameAndExtId(vendor)}</p>
            </Link>
          </div>
        </div>
      )}
      <div className='work-object-info'>
        {!!state?.workObjectId && (
          <p>
            Work object ID: <WorkObjectLink inspection={state} />
          </p>
        )}
      </div>
      <div className='inspection-info'>
        <div className='inspector'>
          <p>
            Inspector
            {state.inspectorType === INSPECTOR_TYPE.EXTERNAL &&
              ' (Third party)'}
            :
          </p>
          {state.inspector.value ? (
            <div className='inspector-group'>
              <NamedAvatar
                user={state.inspector.value}
                large={true}
                onClick={
                  vm.isUserClickable()
                    ? () => vm.userClicksOnAvatar(state.inspector.value)
                    : undefined
                }
              />
              {vm.isReassignInternalAllowed && (
                <ProtectedComponent allowed={VIEW_INSPECTION_ROLES}>
                  <DeleteButton
                    onClick={vm.userClearsInspector}
                    data-testid={SELECTORS.clearInspector}
                  />
                </ProtectedComponent>
              )}
            </div>
          ) : (
            vm.isReassignInternalAllowed && (
              <ProtectedComponent allowed={VIEW_INSPECTION_ROLES}>
                <Button
                  className='medium-button'
                  onClick={vm.userClicksInspectorSelect}
                  data-testid={SELECTORS.assignInspector}
                >
                  Assign inspector
                </Button>
              </ProtectedComponent>
            )
          )}
          {state.isThirdParty && state.thirdPartyInspectorName && (
            <NamedAvatar
              user={{
                name: state.thirdPartyInspectorName,
                companyName: state.thirdPartyInspectingCompany,
              }}
              large={true}
            />
          )}
        </div>
        <div className='dates-container'>
          <div className='inspection-window'>
            <p>Inspection window:</p>
            {isInspectionWindowSet ? (
              <div className='date-group'>
                <p className='bold'>
                  {formatDate(state.inspectionWindow.value[0], '/')}
                  {' - '}
                  {formatDate(state.inspectionWindow.value[1], '/')}
                </p>
                {vm.isEditInternalAllowed && (
                  <ProtectedComponent allowed={VIEW_INSPECTION_ROLES}>
                    <DeleteButton
                      onClick={vm.userClearsWindow}
                      data-testid={SELECTORS.clearWindow}
                    />
                  </ProtectedComponent>
                )}
              </div>
            ) : (
              vm.isEditInternalAllowed && (
                <ProtectedComponent allowed={VIEW_INSPECTION_ROLES}>
                  <DateRangePicker
                    value={state.inspectionWindow.value}
                    min={getMinFutureCalendarDate()}
                    onChange={vm.userSetsWindow}
                    data-testid={SELECTORS.setWindow}
                  />
                </ProtectedComponent>
              )
            )}
          </div>
          <div className='inspection-date'>
            <p>Inspection date:</p>
            {state.inspectionDate.value || state?.finishedAt ? (
              <div className='date-group'>
                <p className='bold'>
                  {state?.finishedAt
                    ? formatDate(state.finishedAt, '/')
                    : formatDate(state.inspectionDate.value, '/')}
                </p>
                {vm.isReassignInternalAllowed && (
                  <ProtectedComponent allowed={VIEW_INSPECTION_ROLES}>
                    <DeleteButton
                      onClick={vm.userClearsDate}
                      data-testid={SELECTORS.clearDate}
                    />
                  </ProtectedComponent>
                )}
              </div>
            ) : (
              vm.isReassignInternalAllowed && (
                <ProtectedComponent allowed={VIEW_INSPECTION_ROLES}>
                  <DatePicker
                    min={getMinFutureCalendarDate()}
                    onChange={vm.userSetsDate}
                    data-testid={SELECTORS.setDate}
                  />
                </ProtectedComponent>
              )
            )}
          </div>
        </div>
        <div className='inspection-results'>
          <p>Inspection results:</p>
          {renderInspectionResults()}
        </div>
        <div className='decision-history'>
          <p>Decisions:</p>
          {!!state.actualDecisions?.length &&
            state.actualDecisions.map((dec) => (
              <div className='previous-decision' key={dec.id}>
                {!!dec.user && (
                  <NamedAvatar
                    user={dec.user}
                    subtitle={formatDateTime(dec.createdAt)}
                  />
                )}
                {!!dec.notes && <p className='decision-notes'>{dec.notes}</p>}
                <div className='decision-name'>{dec.name}</div>
              </div>
            ))}
        </div>
      </div>
      {state.isThirdParty && (
        <div className='inspection-documents-container'>
          <div>
            <h5>Associated documents</h5>
            <div className='associated-documents-list'>
              {(state.referenceDocuments || []).map((doc) => (
                <a href={doc.url} target='_blank' rel='noreferrer'>
                  <File data={doc} />
                </a>
              ))}
            </div>
          </div>
          <div>
            {state.externalReports?.[0] && (
              <>
                <h5>Inspection report</h5>
                <a
                  href={state.externalReports[0].url}
                  target='_blank'
                  rel='noreferrer'
                  className='external-report-document'
                >
                  <File data={state.externalReports[0]} />
                </a>
              </>
            )}
          </div>
        </div>
      )}
      <InspectorsModal
        data-testid={SELECTORS.inspectorsModal}
        data={state.availableInspectors.list}
        isOpen={state.availableInspectors.isModalOpen}
        onRequestClose={vm.userCancelsInspectorsModal}
        onRowClick={vm.userSelectsInspector}
        onSearchChange={vm.userSearchesInspectors}
        search={state.availableInspectors.search}
        setSortBy={vm.userSortsInspectors}
        sortBy={state.availableInspectors.sortBy}
        sortOrder={state.availableInspectors.sortOrder}
        setPage={vm.userSetsInspectorsPage}
        page={state.availableInspectors.page}
        pageSize={state.availableInspectors.pageSize}
        count={state.availableInspectors.count}
        title='Select inspector'
      />
      <NotesModal
        isOpen={state.cancelNotes.isModalOpen}
        onCancel={vm.userCancelsCancelModal}
        onSubmit={vm.userSubmitsCancelModal}
        className='inspections-detail-page--cancel-inspection-modal'
        description='Please provide some information about the problem.'
        data-testid={SELECTORS.cancelModal}
        charsLeft={state.cancelNotes.charsLeft}
        value={state.cancelNotes.notes}
        onChange={vm.userTypesCancelNotes}
      />
    </div>
  );
};

export default InspectionsDetailsPage;
