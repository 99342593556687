import ImageGallery from 'lib/components/image-gallery/ImageGallery';
import Button from 'lib/components/button/Button';
import EditButton from 'lib/components/edit-button/EditButton';
import Map, { Marker } from 'lib/components/map/Map';
import NamedAvatar from 'lib/components/named-avatar/NamedAvatar';
import StatusPill, {
  STATUS_PILL_VARIANT,
} from 'lib/components/status-pill/StatusPill';
import useSourcesDetail from '../../useSourcesDetail';
import { reduceLocation } from 'lib/dataTransform';

import './SourcesDetailPage.scss';
import WorkObjectsTable from 'lib/components/work-objects-table/WorkObjectsTable';
import InspectionsTable from 'lib/components/inspections-table/InspectionsTable';
import ProtectedComponent from 'lib/components/protected-component/ProtectedComponent';
import { VIEW_ASSOCIATED_WORK_OBJECTS } from 'modules/sources/sourcePermissions';

const SourcesDetailPage = (props) => {
  const { state, ...vm } = useSourcesDetail(props);
  const { loading, errors, source } = state;

  if (loading) {
    return null;
  }

  if (errors.length) {
    return null;
  }

  return (
    <div className='sources-detail-page'>
      <div className='sources-header container-m row'>
        <div className='details'>
          <div className='name'>{source.name}</div>
          <div className='info'>
            <StatusPill variant={STATUS_PILL_VARIANT.NOTIFY}>
              {source.type.name}
            </StatusPill>
            <span>ID: {source.id}</span>
            {!!source?.externalId && (
              <span>External ID: {source.externalId}</span>
            )}
            <span>Status: {source.status}</span>
          </div>
        </div>
        <div className='buttons-container'>
          {vm.isEditVisible() && (
            <EditButton
              onClick={() => props.history.push(`/sources/${source.id}/edit`)}
            >
              Edit
            </EditButton>
          )}
        </div>
      </div>
      <div className='sources-media container-m row'>
        <div className='map-container'>
          <Map
            center={{
              lat: source.location?.coordinates?.x || 0,
              lng: source.location?.coordinates?.y || 0,
            }}
            zoom={source.location?.coordinates ? 13 : 3}
            disableDefaultUI={true}
            mapTypeId='hybrid'
          >
            {source.location?.coordinates && (
              <Marker
                position={{
                  lat: source.location?.coordinates.x,
                  lng: source.location?.coordinates.y,
                }}
              />
            )}
          </Map>
          <div className='location-info'>
            <p className='title'>{source.location?.address}</p>
            <p>
              {reduceLocation(
                source.location,
                'district',
                'city',
                'state',
                'country',
              )}
            </p>
          </div>
        </div>
        {!!source.images?.length && (
          <div className='gallery-container'>
            <ImageGallery
              showFullscreenButton={false}
              showPlayButton={false}
              items={source.images.map((i) => ({
                original: i.url,
                thumbnail: i.url,
                height: 300,
              }))}
            />
          </div>
        )}
      </div>
      <div className='owners-container user-list info-block container-m row'>
        <div className='block-title'>Source Owners</div>
        <div className='block-body'>
          {source.owners.length ? (
            source.owners.map((owner) => (
              <NamedAvatar
                onClick={
                  vm.isOwnerClickable()
                    ? () => vm.userClicksOnOwner(owner)
                    : undefined
                }
                large
                user={owner}
                key={owner.id}
              />
            ))
          ) : (
            <div className='empty'>
              There are no owners for this source yet.
            </div>
          )}
        </div>
      </div>
      <div className='coordinators-container user-list info-block container-m row'>
        <div className='block-title'>Coordinators</div>
        <div className='block-body'>
          {source.coordinators?.length ? (
            source.coordinators.map((user) => (
              <NamedAvatar
                onClick={
                  vm.isOwnerClickable()
                    ? () => vm.userClicksOnOwner(user)
                    : undefined
                }
                large
                user={user}
                key={user.id}
              />
            ))
          ) : (
            <div className='empty'>
              There are no coordinators for this source yet.
            </div>
          )}
        </div>
      </div>
      <ProtectedComponent allowed={VIEW_ASSOCIATED_WORK_OBJECTS}>
        <div className='associated-table-title'>
          <h2>Associated work objects</h2>
          {state.workObjects?.count > 5 && (
            <Button
              type='button'
              className='medium-button'
              onClick={() =>
                props.history.push(`/work-objects/?atSource=${source?.id}`)
              }
              data-testid='WorkflowsDetailPage.viewAllWorkObjects'
            >
              View all
            </Button>
          )}
        </div>
        <WorkObjectsTable
          data={state.workObjects?.data}
          data-testid='SourcesDetailPage.WorkObjectsTable'
          userClicksOnRow={vm.userClicksOnWorkObjectRow}
        />
        {!state.workObjects?.count && (
          <p className='info-message'>No associated work objects found</p>
        )}
      </ProtectedComponent>
      <div className='associated-table-title'>
        <h2>Associated inspections</h2>
        {state.inspections?.count > 5 && (
          <Button
            type='button'
            className='medium-button'
            onClick={() =>
              props.history.push(`/inspections/?atSource=${source?.id}`)
            }
            data-testid='WorkflowsDetailPage.viewAllInspections'
          >
            View all
          </Button>
        )}
      </div>
      <InspectionsTable
        data={state.inspections?.data}
        data-testid='SourcesDetailPage.InspectionsTable'
        userClicksOnRow={vm.userClicksOnInspectionRow}
        emptyState
      />
      {!state.inspections?.count && (
        <p className='info-message'>No associated inspections found</p>
      )}
      <div className='associated-table-title'>
        <h2>Valid inspections</h2>
        {state.validInspections?.count > 5 && (
          <Button
            type='button'
            className='medium-button'
            onClick={() =>
              props.history.push(
                `/inspections/?atSource=${source?.id}&validUntil=${vm.today}`,
              )
            }
            data-testid='WorkflowsDetailPage.viewAllInspections'
          >
            View all
          </Button>
        )}
      </div>
      <InspectionsTable
        showColumns={['inspection-plan', 'valid-until']}
        hideColumns={['target']}
        data={state.validInspections?.data}
        data-testid='SourcesDetailPage.ValidInspectionsTable'
        userClicksOnRow={vm.userClicksOnInspectionRow}
      />
      {!state.validInspections?.count && (
        <p className='info-message'>No associated valid inspections found</p>
      )}
    </div>
  );
};

export default SourcesDetailPage;
